import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import ErrorIcon from '@mui/icons-material/Error';

import AddressAutocomplete from './AddressAutocomplete';

import { copy } from '../../../utils';

import './FormField.scss';
import { AppDataContext } from '../../../contexts';

export default function FormField({
  fieldName,
  graphic,
  targetInput,
  currentFieldIndex,
  setTargetInput,
  textFields,
  header,
  formKeyId,
  inputHasError,
  setInputHasError,
  removeLinkLabel,
  removeExtraUserField,
}) {
  const {isapiKeyWorking, setIsapiKeyWorking} =  useContext(AppDataContext)
  function setNewState(targetedState, stateSetter, inputName, newValue, fieldIndex) {
    const newState = copy(targetedState);
    // checking to see if we're dealing with an array of fields (extra users)
    // in which case with need to target the value inside that array
    if (Array.isArray(targetedState[fieldName])) {
      const newArray = copy(targetedState[fieldName]);
      newArray[fieldIndex][inputName] = newValue;
      newState[fieldName] = newArray;
      return stateSetter(newState);
    }
    newState[inputName] = newValue;
    return stateSetter(newState);
  }

  function onChange(inputName, inputValue, fieldIndex, formatter) {
    const newInputValue = formatter ? formatter(inputValue) : inputValue;
    return setNewState(targetInput, setTargetInput, inputName, newInputValue, fieldIndex);
  }

  function checkForError(inputName, inputValue, fieldIndex, checkFormat) {
    // if the value is an empty string or there is no specific type of format required,
    // no need for an error message and we can simply return nothing
    if (inputValue === '' || !checkFormat) return null;
    // we run the 'checkFormat' method to check if the input is valid,
    // the new error state is the opposite of what that method returns `!checkFormat()`
    const newErrorValue = !checkFormat(inputValue);
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  function clearError(inputName, fieldIndex) {
    const newErrorValue = false;
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  const pathToName = (currentInput, currentField, fieldIndex) => {
    if (Array.isArray(currentInput[fieldName])) {
      return currentInput[fieldName][fieldIndex][currentField.name];
    }
    return currentInput[currentField.name];
  };

  return (
    <div className="FormField">
      {header}
      <div className={fieldName}>
        {graphic}
        {textFields.map((textField) => {
          if (isapiKeyWorking && textField.name === 'companyStreetAddress') {
            return (
              <AddressAutocomplete
                key={`${textField.name}${formKeyId}`}
                targetInput={targetInput}
                setTargetInput={setTargetInput}
              />
            );
          }
          if (textField.isAutocomplete) {
            return (
              <FormControl key={`${textField.name}${formKeyId}`} className={textField.className}>
                <Autocomplete
                  freeSolo
                  disablePortal
                  name={textField.name}
                  style={{ margin: 0 }}
                  options={textField.options.sort((a, b) => -b.type.localeCompare(a.type))}
                  groupBy={(option) => option.type}
                  getOptionLabel={(option) => option.name}
                  inputValue={pathToName(targetInput, textField, currentFieldIndex) || ''}
                  onInputChange={(_, value) => setTargetInput({ ...targetInput, [textField.name]: value })}
                  renderInput={(params) => (
                    <TextField
                      className="autocomplete-field"
                      {...params}
                      label={textField.label + (textField.isOptional && fieldName !== 'legalFormField' ? ' (optional)' : '')}
                    />
                  )}
                />
              </FormControl>
            );
          }
          if (textField.isSelect) {
            return (
              <FormControl key={`${textField.name}${formKeyId}`} className={textField.className}>
                <InputLabel>{textField.label + (textField.isOptional && fieldName !== 'legalFormField' ? ' (optional)' : '')}</InputLabel>
                <Select
                  name={textField.name}
                  label={textField.label + (textField.isOptional && fieldName !== 'legalFormField' ? ' (optional)' : '')}
                  value={pathToName(targetInput, textField, currentFieldIndex) || ''}
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value, currentFieldIndex, textField.formatter);
                  }}
                  // TODO remove 'textField.className' here after amvp
                  className={`select-field ${textField.className}`}
                  MenuProps={{ disableScrollLock: true, classes: { paper: 'state-dropdown' } }}
                >
                  {textField.menuItems.map((menuItem) => {
                    return (
                      <MenuItem key={`${menuItem.value}${formKeyId}`} value={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          }
          return (
            <FormControl key={`${textField.name}${formKeyId}`} className={textField.className}>
              <TextField
                disabled={textField.isGiven}
                label={textField.label + (textField.isOptional && fieldName !== 'legalFormField' ? ' (optional)' : '')}
                name={textField.name}
                type={textField.type}
                value={pathToName(targetInput, textField, currentFieldIndex) || ''}
                onChange={(e) => {
                  e.preventDefault();
                  onChange(e.target.name, e.target.value, currentFieldIndex, textField.formatter);
                }}
                onFocus={(e) => clearError(e.target.name, currentFieldIndex)}
                onBlur={(e) => {
                  e.preventDefault();
                  checkForError(
                    e.target.name,
                    e.target.value,
                    currentFieldIndex,
                    textField.formatChecker,
                  );
                  onChange(e.target.name, e.target.value.trim(), currentFieldIndex);
                }}
                error={pathToName(inputHasError, textField, currentFieldIndex)}
              />
              <FormHelperText style={{
                visibility: pathToName(inputHasError, textField, currentFieldIndex) ? 'visible' : 'hidden',
                marginBottom: 0,
              }}
              >
                <ErrorIcon />
                {textField.helperText || 'invalid input'}
              </FormHelperText>
            </FormControl>
          );
        })}
        {Array.isArray(targetInput[fieldName]) && (
          // remove button and hr separator that only shows if in array of extra users
          <Button className="remove-user-btn" onClick={() => { removeExtraUserField(currentFieldIndex); }}>
            <HorizontalRuleIcon />
            <span>{removeLinkLabel}</span>
          </Button>
        )}
      </div>
      {Array.isArray(targetInput[fieldName]) && <hr />}
    </div>
  );
}

FormField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  targetInput: PropTypes.object.isRequired,
  setTargetInput: PropTypes.func.isRequired,
  header: PropTypes.element.isRequired,
  textFields: PropTypes.array.isRequired,
  inputHasError: PropTypes.object.isRequired,
  setInputHasError: PropTypes.func.isRequired,
  formKeyId: PropTypes.number.isRequired,
  graphic: PropTypes.element,
  currentFieldIndex: PropTypes.number,
  removeLinkLabel: PropTypes.string,
  removeExtraUserField: PropTypes.func,
  addressField: PropTypes.bool,
};
