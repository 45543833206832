import cloneDeep from 'lodash.clonedeep';
import { numericFormatter } from 'react-number-format';

const copy = (object) => cloneDeep(object);

// anystring@anystring.anystring
const isAValidEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

// (xxx) xxx-xxxx - US phone number format
const isAValidUSPhoneNumber = (str) => /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$/.test(str);

// xx-xxxxxxx EIN number format
const isAValidEINNumber = (str) => /^\(?([0-9]{2})[-]?([0-9]{7})$/.test(str);

// US Zip Code format
const isAValidZipCode = (str) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(str);

function formatPhoneNumber(value) {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

function formatEINNumber(value) {
  const EINNumber = value.replace(/[^\d]/g, '');
  if (EINNumber.length > 3) return `${EINNumber.slice(0, 2)}-${EINNumber.slice(2, 9)}`;
  return EINNumber;
}

const formatZipCode = (value) => value.replace(/[^\d|^-]/g, '');

const commaEvery3rdChar = (val) => val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

function formatNumericValue(val, { thousandSeparator = true, allowNegative = false, allowLeadingZeros = false, decimalScale = 4 } = {}) {
  let formattedVal = (val || '').toString();
  if (allowNegative) {
    formattedVal = formattedVal.replace(/[^\d.-]/g, '');
    formattedVal = formattedVal.replace(/(?<=[\s\S]+)-/g, '');
  } else {
    formattedVal = formattedVal.replace(/[^\d.]/g, '');
  }
  return numericFormatter(formattedVal, { thousandSeparator, allowNegative, allowLeadingZeros, decimalScale });
}

function formatInputToNumWith2Decimals(value) {
  let dollars;
  let cents;

  const nums = value.split('.').map((s) => s.replace(/\D/ig, ''));

  if (nums.length >= 2) {
    ([dollars] = nums);
    nums.shift();
    cents = nums.join('');
    if (cents.length >= 3) cents = cents.slice(0, 2);
  } else {
    dollars = nums.join('');
    cents = null;
  }

  return `${commaEvery3rdChar(dollars)}${cents !== null ? `.${cents}` : ''}`;
}

// Works whether number has , or not
function doesNumHaveLeading0s(num) {
  return /^0+/.test(num) && /[1-9]/ig.test(num);
}

function removeCommas(s) {
  return s.replaceAll(',', '');
}

function trimLeading0s(num) {
  return num.replaceAll(/^0+/g, '');
}

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


export {
  copy,
  isAValidEmail,
  isAValidUSPhoneNumber,
  commaEvery3rdChar,
  formatNumericValue,
  formatInputToNumWith2Decimals,
  formatPhoneNumber,
  formatZipCode,
  formatEINNumber,
  isAValidEINNumber,
  isAValidZipCode,
  doesNumHaveLeading0s,
  removeCommas,
  trimLeading0s,
  camelize,
};
