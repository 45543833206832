import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ContextsProvider from './contexts/ContextsProvider';
import NavigationProvider from './contexts/NavigationProvider';
import AuthProvider from './contexts/AuthProvider';

import App from './App';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

// MUI Global Style Alterations
const muiGlobalTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={muiGlobalTheme}>
        <ContextsProvider>
          <NavigationProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </NavigationProvider>
        </ContextsProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
);
