import React, { useContext } from 'react';
import Alert from '@mui/material/Alert';

import { ErrorMessageContext } from '../contexts';

export default function AlertDisplay() {
  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);

  if (showErrorMessage) {
    return (
      <Alert
        severity="error"
        style={{ position: 'fixed', width: '40%', top: 20, left: '30%', zIndex: 4000 }}
        onClose={() => setShowErrorMessage('')}
      >
        {showErrorMessage}
      </Alert>
    );
  }
}
