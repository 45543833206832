import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';

import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import { ReactComponent as CancellationCalendar } from '../../images/icons/cancellation_calendar_blue.svg';

import BreadCrumbNav from '../../components/BreadCrumbNav';
import Early409AModal from '../../components/Modals/Early409AModal';
import New409AReSubModal from './New409AReSubModal';
import CancellationModal from './CancellationModal';

import { UserDataContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import './index.scss';

export default function SubscriptionPage() {
  const { userData, setUserData } = useContext(UserDataContext);

  const [showEarly409AModal, setShowEarly409AModal] = useState(false);
  const [showNew409AReSubModal, setShowNew409AReSubModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const [{ loading: resubscribing }, renewSubscriptionRequest] = useFetch();

  const nav = useNavigate();

  const { subscriptionData } = userData;

  function resubscribe() {
    renewSubscriptionRequest({
      url: '/subscriptions/renew-subscription',
      method: 'post',
      urlIds: ['userId', 'companyId'],
      onSuccess: () => {
        setUserData({
          ...userData,
          subscriptionData: {
            ...userData.subscriptionData,
            isActive: 1,
            startDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            endDateTime: moment().add(1, 'years').format('YYYY-MM-DD HH:mm:ss'),
          },
        });
      },
    });
  }

  if (subscriptionData) {
    return (
      <main className="SubscriptionPage">
        <BreadCrumbNav pageTitle="Subscription" path="/" pathTitle="Home" />
        <div className="content-wrapper">
          <h4>Manage your subscription</h4>
          {subscriptionData.isActive ? (
            <p>{`Your annual initio™ subscription will automatically renew on ${moment(subscriptionData.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}`}</p>
          ) : (
            <p>
              {`Your annual initio™ subscription was cancelled on ${moment(subscriptionData.cancellationDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}. `}
              You can resubscribe for free at any time. Pay when you purchase a new 409A valuation.
            </p>
          )}
          <div className="page-content">
            <div className="left-column">
              <div className="subscription-price">
                {subscriptionData.isActive ? (
                  <p>
                    Current subscription price:
                    <br />
                    <span>free</span>
                  </p>
                ) : (
                  <p>
                    Resubscribe for&nbsp;
                    <span>free</span>
                  </p>
                )}
              </div>
              <div className="subscription-info">
                <h5>
                  An annual initio
                  <br />
                  subscription includes
                </h5>
                <hr />
                <div className="text-frame">
                  <ul>
                    <li>First 409A valuation is free</li>
                    <li>Additional users join for free</li>
                    <li>Our proprietary 409A valuation sandbox:</li>
                    <ul>
                      <li>Customized for your company</li>
                      <li>Designed with sliders that, in real-time, show you which factors have the most impact on your company&apos;s share price and audit risk</li>
                      <li>Allows you to finalize your 409A valuation when you&apos;re ready</li>
                    </ul>
                    <li>Plus, a 409A valuation report detailing:</li>
                    <ul>
                      <li>Your company&apos;s estimated fair market value based on your inputs</li>
                      <li>The assumptions and conclusions of our analysis</li>
                    </ul>
                    <li>Private market data (coming soon)</li>
                    <li>
                      Equity scenario simulations
                      <br />
                      (coming soon)
                    </li>
                  </ul>
                  <p>
                    Subscriptions automatically renew every year,
                    <br />
                    unless you cancel. We will send reminders when
                    <br />
                    your subscription is coming due. Cancel any time.
                  </p>
                </div>
                <hr />
                <p>
                  Billed annually, current price:&nbsp;
                  <span>free</span>
                </p>
              </div>
            </div>
            <div className="right-column">
              <div className="subscription-data">
                <h5>Your subscription</h5>
                <hr />
                {subscriptionData.isActive ? (
                  <>
                    <span className="bold">1 annual initio subscription</span>
                    <br />
                    <span>{`Subscription start date: ${moment(subscriptionData.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}`}</span>
                    <br />
                    <span>{`Automatically renews: ${moment(subscriptionData.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}`}</span>
                  </>
                ) : (
                  <>
                    <span>{`Subscription start date: ${moment(subscriptionData.startDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}`}</span>
                    <br />
                    <span className="bold purple">{`Subscription cancelled: ${moment(subscriptionData.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY')}`}</span>
                  </>
                )}
                <Button className="account-info-link" onClick={() => nav('/account-info', { state: { tabSelected: 'payment-history' } })}>Go to Account page to see payment history</Button>
                <hr />
                {subscriptionData.isActive === 0 && (
                  <div className="sub-data-btns">
                    {resubscribing ? (
                      <Button disabled>
                        <span className="dots-circle-spinner" />
                        Resubscribing...
                      </Button>
                    ) : (
                      <Button onClick={resubscribe}>
                        <EventRepeatOutlinedIcon />
                        Resubscribe
                      </Button>
                    )}
                    <Button
                      disabled={resubscribing}
                      onClick={() => {
                        if (moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').isAfter(moment().subtract(1, 'years'))) {
                          setShowEarly409AModal(true);
                          return;
                        }
                        setShowNew409AReSubModal(true);
                      }}
                    >
                      <AddchartIcon />
                      Start new 409A
                    </Button>
                  </div>
                )}
              </div>
              <div className="right-col-btns">
                {subscriptionData.isActive === 1 && (
                  <Button onClick={() => setShowCancellationModal(true)} className="cancellation-btn">
                    <CancellationCalendar />
                    Cancel subscription
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <CancellationModal showCancellationModal={showCancellationModal} setShowCancellationModal={setShowCancellationModal} />
        <Early409AModal showEarly409AModal={showEarly409AModal} setShowEarly409AModal={setShowEarly409AModal} />
        <New409AReSubModal showNew409AReSubModal={showNew409AReSubModal} setShowNew409AReSubModal={setShowNew409AReSubModal} />
      </main>
    );
  }
}
