import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

// MUI imports
import LinearProgress from '@mui/material/LinearProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';

// Images
import helloAvatar from '../../images/HelloAvatarGraphic.svg';

// Components
import ArrowTooltip from './components/ArrowTooltip';
import Wiz1 from './Wiz1';
import ReviewAndSign from './ReviewAndSign';
import MakePayment from './MakePayment';

// Helper
import { copy } from '../../utils';

import {
  UserDataContext,
  NavigationContext,
} from '../../contexts';

import {
  userInfoFormContent,
  userInfoDefaultValues,
  companyInfoFormContent,
  companyInfoDefaultValues,
  extraUserFormContent,
  legalFirmInfoFormContent,
  legalFirmInfoDefaultValues,
  accountingFirmInfoFormContent,
  accountingFirmInfoDefaultValues,
  hasErrorDefaultValues,
} from './utils/formFieldsContents';

import useFetch from '../../hooks/useFetch';
import useSignOut from '../../hooks/useSignOut';

import './index.scss';

export default function ClientIntakeForm() {
  const { userData, setUserData } = useContext(UserDataContext);
  const { to, from } = useContext(NavigationContext);

  const [activeWizard, setActiveWizard] = useState(0);
  const [inWiz, setInWiz] = useState(false);
  const [wizSteps, setWizSteps] = useState({ currentStep: 1, totalSteps: 3 });
  const [stepsVisited, setStepsVisited] = useState([]);
  const [hasStarted, setHasStarted] = useState(false);
  const [checkForLastCompletedStep, setCheckForLastCompletedStep] = useState(false);

  // Step 1 - Client Intake Form
  const [userInfoInput, setUserInfoInput] = useState(userInfoDefaultValues);
  const [companyInfoInput, setCompanyInfoInput] = useState(companyInfoDefaultValues);
  const [legalFirmInfoInput, setLegalFirmInfoInput] = useState(legalFirmInfoDefaultValues);
  const [accountingFirmInfoInput, setAccountingFirmInfoInput] =
    useState(accountingFirmInfoDefaultValues);
  const [inputHasError, setInputHasError] = useState(hasErrorDefaultValues);

  // Step 2 - Review And Sign
  const [reviewPageHasBeenVisited, setReviewPageHasBeenVisited] = useState(false);
  const [userSignature, setUserSignature] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  // Step 3 - Payment
  const [paymentFormIsLoading, setPaymentFormIsLoading] = useState(false);
  const [paymentIsCompleted, setPaymentIsCompleted] = useState(false);
  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
  const [discountPaymentData, setDiscountPaymentData] = useState({});
  const makePaymentButtonRef = useRef('');

  const nav = useNavigate();
  const location = useLocation();
  const [urlParams] = useSearchParams();

  const [signOut] = useSignOut();

  const [{ response: accountDBData, loading: fetchingAccountData }] = useFetch({ url: '/accounts', urlIds: ['companyId'] });
  const [{ response: companyDBData, loading: fetchingCompanyData }] = useFetch({ url: '/companies/details', urlIds: ['companyId'] });
  const [{ success: accountUpdateSuccess }, updateAccountDataRequest] = useFetch();
  const [{ success: companyUpdateSuccess }, updateCompanyDataRequest] = useFetch();

  // Handles browser navigation
  function handleNav(event) {
    if (!event) return;
    if (!event.search) {
      setInWiz(false);
      setCheckForLastCompletedStep(true);
      return;
    }
    const navParams = new URLSearchParams(event.search);
    const paramCurrentStep = navParams.get('currentStep');
    const paramTotalSteps = navParams.get('totalSteps');
    const paramActiveWizard = navParams.get('activeWizard');
    if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
      setWizSteps({
        currentStep: parseInt(paramCurrentStep, 10),
        totalSteps: parseInt(paramTotalSteps, 10),
      });
      setActiveWizard(parseInt(paramActiveWizard, 10));
      setInWiz(true);
    }
  }

  useEffect(() => handleNav(to), [to]);
  useEffect(() => handleNav(from), [from]);

  function isFormCompleted() {
    function checkForEmptyInputs(inputs, formContent) {
      return Object.values(inputs).every((x, i) => {
        if (!formContent[i]?.isOptional) return x !== '';
        return true;
      });
    }

    return ( // check for empty inputs
      checkForEmptyInputs(userInfoInput, userInfoFormContent) &&
      checkForEmptyInputs(companyInfoInput, companyInfoFormContent) &&
      checkForEmptyInputs(legalFirmInfoInput, legalFirmInfoFormContent) &&
      checkForEmptyInputs(accountingFirmInfoInput, accountingFirmInfoFormContent) &&
      (companyInfoInput.extraUsers.length > 0 ?
        companyInfoInput.extraUsers.every((extraUser) => {
          return checkForEmptyInputs(extraUser, extraUserFormContent);
        }) : true) &&
      // check for errors
      Object.values(inputHasError).every((x) => x === false || Array.isArray(x)) &&
      (inputHasError.extraUsers.length > 0 ?
        inputHasError.extraUsers.every((extraUser) => {
          return Object.values(extraUser).every((x) => x === false);
        }) : true)
    );
  }

  const steps = [
    {
      label: 'Your information',
      totalSteps: () => 3,
      isComplete: () => isFormCompleted(),
    },
    {
      label: 'Review and sign',
      totalSteps: () => 2,
      isComplete: () => !!userSignature,
    },
    {
      label: 'Make payment',
      totalSteps: () => 1,
      isComplete: () => paymentIsCompleted,
    },
  ];


  useEffect(() => {
    function integrateDbValsAndDefaults(dbVals, defaults) {
      return dbVals.reduce((accumulator, { key, val }) => {
        accumulator[key] = (val !== null) ? val : defaults[key];
        return accumulator;
      }, {});
    }
    if (accountDBData && companyDBData) {
      const paramCurrentStep = urlParams.get('currentStep');
      const paramTotalSteps = urlParams.get('totalSteps');
      const paramActiveWizard = urlParams.get('activeWizard');

      const {
        accountingAdvisorEmail = null,
        accountingAdvisorName,
        accountingAdvisorPermission,
        accountingFirmName,
        // companyId,
        // companyName,
        companyStreetAddress,
        // companyType,
        country,
        companyWebsite,
        // createdDatetime,
        dba,
        ein,
        extraUsers,
        // insertUserId,
        legalAdvisorEmail,
        legalAdvisorName,
        legalAdvisorPermission,
        legalFirmName,
        // partnerFirm,
        // partnerType,
        // partnerUserId,
        // primaryUserId,
        // resource,
        city,
        state,
        // websiteUrl,
        zipCode,
        signature,
      } = companyDBData.Body;

      const {
        // accountId,
        // accountType,
        // cognitoUuid,
        // companyId,
        companyName,
        // createdDatetime,
        email,
        firstName,
        // isAuthSignatory,
        // isClient,
        lastName,
        // marketingOptIn,
        // partnerFirm,
        // partnerUserId,
        phoneNumber,
        // resource,
        // version,
      } = accountDBData.Body;

      const alreadyFilledOutData = [
        accountingAdvisorEmail,
        accountingAdvisorName,
        accountingAdvisorPermission,
        accountingFirmName,
        companyStreetAddress,
        country,
        companyWebsite,
        dba,
        ein,
        extraUsers,
        legalAdvisorEmail,
        legalAdvisorName,
        legalAdvisorPermission,
        legalFirmName,
        city,
        state,
        zipCode,
        signature,
        phoneNumber,
        lastName,
        firstName,
      ].some((retrievedData) => retrievedData !== null);

      setHasStarted(alreadyFilledOutData);

      // Wiz #1
      // Step - 1
      const userInfoDBVals = [
        { key: 'firstName', val: firstName },
        { key: 'lastName', val: lastName },
        { key: 'email', val: email },
        { key: 'phoneNumber', val: phoneNumber },
      ];

      const userInfoIntegratedVals = integrateDbValsAndDefaults(
        userInfoDBVals,
        userInfoDefaultValues,
      );

      setUserInfoInput(userInfoIntegratedVals);

      // Step - 2
      const companyInfoDBVals = [
        { key: 'companyName', val: companyName },
        { key: 'dba', val: dba },
        { key: 'companyStreetAddress', val: companyStreetAddress },
        { key: 'city', val: city },
        { key: 'state', val: state },
        { key: 'zipCode', val: zipCode },
        { key: 'country', val: country },
        { key: 'companyWebsite', val: companyWebsite },
        { key: 'ein', val: ein },
        { key: 'extraUsers', val: extraUsers },
      ];

      const companyIntegratedVals = integrateDbValsAndDefaults(
        companyInfoDBVals,
        companyInfoDefaultValues,
      );
      // console.log("companyIntegratedVals",companyIntegratedVals);

      setCompanyInfoInput(companyIntegratedVals);

      // Step - 3
      const legalFirmInfoDBVals = [
        { key: 'legalFirmName', val: legalFirmName },
        { key: 'legalAdvisorName', val: legalAdvisorName },
        { key: 'legalAdvisorEmail', val: legalAdvisorEmail },
        { key: 'legalAdvisorPermission', val: legalAdvisorPermission },
      ];

      const legalFirmIntegratedVals = integrateDbValsAndDefaults(
        legalFirmInfoDBVals,
        legalFirmInfoDefaultValues,
      );

      setLegalFirmInfoInput(legalFirmIntegratedVals);

      // Step - 4
      const accountingFirmInfoDBVals = [
        { key: 'accountingFirmName', val: accountingFirmName },
        { key: 'accountingAdvisorName', val: accountingAdvisorName },
        { key: 'accountingAdvisorEmail', val: accountingAdvisorEmail },
        { key: 'accountingAdvisorPermission', val: accountingAdvisorPermission },
      ];

      const accountingFirmIntegratedVals = integrateDbValsAndDefaults(
        accountingFirmInfoDBVals,
        accountingFirmInfoDefaultValues,
      );

      setAccountingFirmInfoInput(accountingFirmIntegratedVals);

      // Signature
      setUserSignature(signature);
      if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
        setWizSteps({
          currentStep: parseInt(paramCurrentStep, 10),
          totalSteps: parseInt(paramTotalSteps, 10),
        });
        setActiveWizard(parseInt(paramActiveWizard, 10));
        setInWiz(true);
      } else {
        setCheckForLastCompletedStep(true);
      }
    }
  }, [accountDBData, companyDBData]);

  useEffect(() => {
    if (checkForLastCompletedStep) {
      setActiveWizard(steps.findIndex(({ isComplete }) => !isComplete()));
    }
    setCheckForLastCompletedStep(false);
  }, [checkForLastCompletedStep]);

  useEffect(() => {
    if (inWiz) {
      const { currentStep, totalSteps } = wizSteps;
      const newUrl = `?currentStep=${currentStep}&totalSteps=${totalSteps}&activeWizard=${activeWizard}`;
      if (location.search !== newUrl) nav(newUrl);
    }
  }, [wizSteps, inWiz, activeWizard]);

  function updateUserData({ isComplete = false } = {}) {
    const accountUserData = {
      ...userInfoInput,
      companyName: companyInfoInput.companyName,
      progress: isComplete ? 'completed' : 'not completed',
    };

    const companyData = {
      ...companyInfoInput,
      ...legalFirmInfoInput,
      ...accountingFirmInfoInput,
      signature: userSignature,
      progress: isComplete ? 'completed' : 'not completed',
    };

    updateAccountDataRequest({ url: '/accounts/update-account', urlIds: ['companyId'], body: accountUserData, bodyIds: ['userId', 'companyId'] });
    updateCompanyDataRequest({ url: '/companies/update-company', urlIds: ['companyId'], body: companyData, bodyIds: ['userId', 'companyId'] });
  }

  useEffect(() => {
    if (accountUpdateSuccess && companyUpdateSuccess) {
      const accountUserData = { ...userInfoInput, companyName: companyInfoInput.companyName };
      const companyData = { ...companyInfoInput, ...legalFirmInfoInput, ...accountingFirmInfoInput };

      setUserData({
        ...userData,
        accountData: { ...userData.accountData, ...accountUserData },
        companyData: { ...userData.companyData, ...companyData },
        userAttributes: {
          ...userData.userAttributes,
          companyName: companyInfoInput.companyName,
          firstName: userInfoInput.firstName,
          lastName: userInfoInput.lastName,
        },
      });
    }
  }, [accountUpdateSuccess, companyUpdateSuccess]);

  useEffect(() => { if (paymentIsCompleted) updateUserData({ isComplete: true }); }, [paymentIsCompleted]);

  function determineIcon(isWizardComplete, index) {
    const isCurrentWizComplete = isWizardComplete();

    let status = 'not-started';
    if (isCurrentWizComplete) status = 'completed';
    else if (activeWizard === index) status = 'is-next';
    else if (stepsVisited.includes(index)) status = 'has-started';

    return {
      icon: isCurrentWizComplete && <DoneIcon />,
      status,
    };
  }

  function openNewWizard(
    activeWizIndex,
    numTotalSteps,
    startOnThisStep = 1,
    isInTheWiz = true,
  ) {
    setInWiz(isInTheWiz);
    setActiveWizard(activeWizIndex);
    wizSteps.totalSteps = numTotalSteps;
    wizSteps.currentStep = startOnThisStep;
    setWizSteps(copy(wizSteps));
  }

  function isInReviewAndSignFirstStep() {
    return activeWizard === 1 && wizSteps.currentStep === 1;
  }

  function isInReviewAndSignSecondStep() {
    return activeWizard === 1 && wizSteps.currentStep === 2;
  }

  function isInMakePaymentFinalWizard() {
    return activeWizard === 2;
  }

  function home() {
    return (
      <>
        {(fetchingAccountData || fetchingCompanyData) && (
          <div className="loading-wrapper">
            <div className="dots-circle-spinner" />
          </div>
        )}
        <h4>Finish your account setup</h4>
        <img src={helloAvatar} className="hello-avatar-image" alt="hello and welcome to customer input form" />
        <p>
          Finish setting up your account in just three easy steps that take an average of
          5-10 minutes to complete.
        </p>
        <Stepper activeStep={activeWizard} orientation="vertical">
          {
            steps.map(({ label, totalSteps, isComplete }, i, rootArr) => {
              const { icon, status } = determineIcon(isComplete, i, rootArr);
              // console.log("rootarr",rootArr)
              return (
                <Step
                  key={label}
                  disabled={false}
                  // eslint-disable-next-line max-len
                  className={`individual-step ${(!userSignature && label === 'Make payment') ? 'no-click-event' : ''}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!hasStarted) setHasStarted(true);
                    openNewWizard(i, totalSteps());
                  }}
                >
                  <StepLabel
                    className={status}
                    StepIconComponent={() => icon || i + 1}
                  >
                    {label}
                    {/completed/ig.test(status) && <CreateOutlinedIcon />}
                  </StepLabel>
                  {label === 'Make payment' && <ArrowTooltip />}
                </Step>
              );
            })
          }
        </Stepper>
        <Button
          className="start-btn"
          onClick={() => {
            if (!hasStarted) setHasStarted(true);
            const { totalSteps } = steps[activeWizard];
            openNewWizard(activeWizard, totalSteps());
          }}
        >
          {hasStarted ? 'Next' : 'Finish setup'}
        </Button>
      </>
    );
  }

  function wiz() {
    if (activeWizard === 0) {
      return (
        <Wiz1
          currentStep={wizSteps.currentStep}
          userInfoInput={userInfoInput}
          companyInfoInput={companyInfoInput}
          legalFirmInfoInput={legalFirmInfoInput}
          accountingFirmInfoInput={accountingFirmInfoInput}
          inputHasError={inputHasError}
          setUserInfoInput={setUserInfoInput}
          setCompanyInfoInput={setCompanyInfoInput}
          setLegalFirmInfoInput={setLegalFirmInfoInput}
          setAccountingFirmInfoInput={setAccountingFirmInfoInput}
          setInputHasError={setInputHasError}
        />
      );
    }
    if (activeWizard === 1) {
      return (
        <ReviewAndSign
          openNewWizard={openNewWizard}
          steps={steps}
          currentStep={wizSteps.currentStep}
          wizSteps={wizSteps}
          setWizSteps={setWizSteps}
          isFormCompleted={isFormCompleted()}
          userInfoInput={userInfoInput}
          companyInfoInput={companyInfoInput}
          legalFirmInfoInput={legalFirmInfoInput}
          accountingFirmInfoInput={accountingFirmInfoInput}
          inputHasError={inputHasError}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          userSignature={userSignature}
          setUserSignature={setUserSignature}
          setReviewPageHasBeenVisited={setReviewPageHasBeenVisited}
        />
      );
    }
    if (activeWizard === 2) {
      return (
        <MakePayment
          makePaymentButtonRef={makePaymentButtonRef}
          setPaymentFormIsLoading={setPaymentFormIsLoading}
          setPaymentIsProcessing={setPaymentIsProcessing}
          setPaymentIsCompleted={setPaymentIsCompleted}
          discountPaymentData={discountPaymentData}
          setDiscountPaymentData={setDiscountPaymentData}
        />
      );
    }

    return <h2>Sorry, not a valid step...</h2>;
  }

  if (paymentIsCompleted) {
    return (
      <div className="ClientIntakeForm success-container">
        <h2>Success!</h2>
        <div className="success-msg-container">
          <CheckCircleIcon />
          <p>
            {`You've successfully set up your ${companyInfoInput.companyName}'s account. We've emailed you a confirmation of payment.`}
          </p>
        </div>
        <Button
          className="payment-page-link"
          onClick={() => window.location.assign(process.env.REACT_APP_INITIO_HOSTNAME)}
        >
          Go to homepage
        </Button>
        <Button
          className="payment-page-link"
          onClick={signOut}
        >
          Sign out
        </Button>
      </div>
    );
  }

  function nextOrMakePaymentBtnText() {
    if (isInReviewAndSignFirstStep()) return 'Submit information';

    if (isInMakePaymentFinalWizard()) {
      if (paymentIsProcessing) {
        return (
          <>
            <span className="dots-circle-spinner" />
            Processing Payment...
          </>
        );
      }

      return discountPaymentData.chargeAmount === '0' ? 'Proceed without payment' : 'Make payment';
    }

    return 'Next';
  }

  return (
    <div className="ClientIntakeForm">

      {inWiz && (
        <div className="bread-crumb">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={() => {
              setInWiz(false);
              setCheckForLastCompletedStep(true);
              nav('/');
            }}
          >
            <ArrowBackIcon className="back-icon" />
            Account setup
          </button>
          <span>{steps[activeWizard]?.label}</span>
        </div>
      )}

      <div className="client-form-container">
        {inWiz && (
          <div className="linear-progress">
            <span className="current-step">{`${wizSteps.currentStep} of ${wizSteps.totalSteps}`}</span>
            <LinearProgress variant="determinate" value={(wizSteps.currentStep / wizSteps.totalSteps) * 100} />
          </div>
        )}
        <div className="client-form" style={!inWiz ? { padding: '40px 140px 40px 140px' } : {}}>
          {inWiz ? wiz() : home()}
        </div>
        {
          inWiz && (
            <div className="bottom-btn-container">
              {
                (
                  reviewPageHasBeenVisited &&
                  (activeWizard === 0)
                ) && (
                  <Button
                    className="back-to-review"
                    onClick={(e) => {
                      e.preventDefault();
                      openNewWizard(1, steps[1].totalSteps(), 1);
                      updateUserData();
                    }}
                  >
                    Back to review page
                  </Button>
                )
              }
              <Button
                className="back-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (activeWizard === 0) {
                    updateUserData();
                  }
                  if (wizSteps.currentStep === 1) {
                    setInWiz(false);
                    setCheckForLastCompletedStep(true);
                    nav('/');
                  } else {
                    wizSteps.currentStep -= 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                Back
              </Button>
              <Button
                className="next-btn"
                disabled={(
                  (isInReviewAndSignSecondStep() && !userSignature) ||
                  paymentFormIsLoading || paymentIsProcessing
                )}
                onClick={(e) => {
                  e.preventDefault();

                  if (activeWizard === 0 || activeWizard === 1) {
                    updateUserData();
                  }
                  if (
                    isInReviewAndSignFirstStep() &&
                    !isFormCompleted()
                  ) {
                    setShowErrors(true);
                    return;
                  }

                  if (isInMakePaymentFinalWizard()) {
                    makePaymentButtonRef.current.click();
                    return;
                  }

                  if (wizSteps.currentStep === wizSteps.totalSteps) {
                    let nextActiveWiz = activeWizard + 1;
                    if (!steps[nextActiveWiz]) nextActiveWiz = steps.length - 1;
                    const newStepsVisitedArray = copy(stepsVisited);
                    newStepsVisitedArray.push(activeWizard);
                    setStepsVisited(newStepsVisitedArray);
                    setActiveWizard(nextActiveWiz);
                    setInWiz(false);
                    wizSteps.currentStep = 1;
                    setWizSteps(copy(wizSteps));
                    nav('/');
                  } else {
                    wizSteps.currentStep += 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                {nextOrMakePaymentBtnText()}
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
}
