const industryVerticals = {
  version: '1.0',
  data: [
    {
      category: 'Cannabis',
      subcategories: [
        {
          subcategory: 'Cannabis',
          verticals: [
            'Cannabis',
          ],
        },
      ],
    },
    {
      category: 'Energy',
      subcategories: [
        {
          subcategory: 'Energy',
          verticals: [
            'Energy Equipment and Services',
            'Oil, Gas and Consumable Fuels',
            'Cleantech',
            'Climate Tech',
          ],
        },
      ],
    },
    {
      category: 'Materials',
      subcategories: [
        {
          subcategory: 'Materials',
          verticals: [
            'Chemicals',
            'Construction Materials',
            'Containers and Packaging',
            'Metals and Mining',
            'Advanced Manufacturing',
            'Paper and Forest Products',
            'Supply chain technology',
          ],
        },
      ],
    },
    {
      category: 'Industrials',
      subcategories: [
        {
          subcategory: 'Capital Goods',
          verticals: [
            'Building Products',
            'Construction and Engineering',
            'Electrical Equipment',
            'Machinery',
            'Trading Companies and Distributors',
          ],
        },
        {
          subcategory: 'Commercial and Professional Services',
          verticals: [
            'Professional Services',
            'Construction Technology',
          ],
        },
        {
          subcategory: 'Transportation',
          verticals: [
            'Air Freight and Logistics',
            'Airlines',
            'Marine',
            'Road and Rail',
            'Transportation Infrastructure',
          ],
        },
      ],
    },
    {
      category: 'Consumer Discretionary',
      subcategories: [
        {
          subcategory: 'Automobiles and Components',
          verticals: [
            'Automobiles',
          ],
        },
        {
          subcategory: 'Consumer Durables and Apparel',
          verticals: [
            'Household Durables',
            'Leisure Products',
            'Textiles, Apparel and Luxury Goods',
          ],
        },
        {
          subcategory: 'Consumer Services',
          verticals: [
            'Hotels, Restaurants and Leisure',
          ],
        },
        {
          subcategory: 'Retailing',
          verticals: [
            'Distributors',
            'Internet and Direct Marketing Retail',
            'Multiline Retail',
            'Specialty Retail',
          ],
        },
      ],
    },
    {
      category: 'Consumer Staples',
      subcategories: [
        {
          subcategory: 'Food and Staples Retailing',
          verticals: [
            'Food and Staples Retailing',
          ],
        },
        {
          subcategory: 'Food, Beverage and Tobacco',
          verticals: [
            'Beverages',
            'Food Products',
            'Tobacco',
          ],
        },
        {
          subcategory: 'Household and Personal Products',
          verticals: [
            'Personal Products',
          ],
        },
      ],
    },
    {
      category: 'Health Care',
      subcategories: [
        {
          subcategory: 'Health Care Equipment and Services',
          verticals: [
            'Healthcare Technology',
            'Healthcare Equipment and Supplies',
            'Healthcare Providers and Services',
          ],
        },
        {
          subcategory: 'Pharmaceuticals, Biotechnology and Life Sciences',
          verticals: [
            'Biotechnology',
            'Life Sciences Tools and Services',
            'Pharmaceuticals',
            'Oncology',
            'Life sciences',
          ],
        },
      ],
    },
    {
      category: 'Financials',
      subcategories: [
        {
          subcategory: 'Banks',
          verticals: [
            'Banks',
            'Thrifts and Mortgage Finance',
            'Mortgage tech',
          ],
        },
        {
          subcategory: 'Diversified Financials',
          verticals: [
            'Capital Markets',
            'Consumer Finance',
            'Diversified Financial Services',
            'Mortgage Real Estate Investment Trusts (REITs)',
          ],
        },
        {
          subcategory: 'Insurance',
          verticals: [
            'Insurance',
          ],
        },
      ],
    },
    {
      category: 'Information Technology',
      subcategories: [
        {
          subcategory: 'Semiconductors and Semiconductor Equipment',
          verticals: [
            'Semiconductors and Semiconductor Equipment',
          ],
        },
        {
          subcategory: 'Software and Services',
          verticals: [
            'IT Services',
            'Software',
            'Adtech',
            'Artificial Intelligence and Machine Learning',
            'Augmented Reality',
            'B2B Payments',
            'Big Data',
            'Car Sharing',
            'Cloudtech and DevOps',
            'Cybersecurity',
            'Digital Health',
            'Ecommerce',
            'Edtech',
            'eSports',
            'FinTech',
            'Legal tech',
            'Software as a service (SaaS)',
          ],
        },
        {
          subcategory: 'Technology Hardware and Equipment',
          verticals: [
            'Communications Equipment',
            'Technology Hardware, Storage and Peripherals',
            'Electronic Equipment, Instruments and Components',
            'Agtech',
            'Audiotech',
            'Autonomous Cars',
            'IoT',
            'Nanotechnology',
            'Space tech',
            'Virtual reality (VR)',
          ],
        },
      ],
    },
    {
      category: 'Communication Services',
      subcategories: [
        {
          subcategory: 'Telecommunication Services',
          verticals: [
            'Diversified Telecommunication Services',
            'Wireless Telecommunication Services',
            'Mobile',
            'Mobile commerce',
            'Technology, media and telecommunications (TMT)',
          ],
        },
        {
          subcategory: 'Media and Entertainment',
          verticals: [
            'Media',
            'Entertainment',
            'Interactive Media and Services',
          ],
        },
      ],
    },
    {
      category: 'Utilities',
      subcategories: [
        {
          subcategory: 'Utilities',
          verticals: [
            'Electric Utilities',
            'Gas Utilities',
            'Independent Power Producers and Energy Traders',
            'Multi-Utilities',
            'Water Utilities',
          ],
        },
      ],
    },
    {
      category: 'Real Estate',
      subcategories: [
        {
          subcategory: 'Real Estate',
          verticals: [
            'Equity Real Estate Investment Trusts (REITs)',
            'Real Estate Management and Development',
          ],
        },
      ],
    },
  ],
};

const industryVerticalsAlphabetizedAndSorted = [
  {
    category: 'Software and Services',
    title: 'IT Services',
  },
  {
    category: 'Software and Services',
    title: 'Software',
  },
  {
    category: 'Software and Services',
    title: 'Adtech',
  },
  {
    category: 'Software and Services',
    title: 'Artificial Intelligence and Machine Learning',
  },
  {
    category: 'Software and Services',
    title: 'Augmented Reality',
  },
  {
    category: 'Software and Services',
    title: 'B2B Payments',
  },
  {
    category: 'Software and Services',
    title: 'Big Data',
  },
  {
    category: 'Software and Services',
    title: 'Car Sharing',
  },
  {
    category: 'Software and Services',
    title: 'Cloudtech and DevOps',
  },
  {
    category: 'Software and Services',
    title: 'Cybersecurity',
  },
  {
    category: 'Software and Services',
    title: 'Digital Health',
  },
  {
    category: 'Software and Services',
    title: 'Ecommerce',
  },
  {
    category: 'Software and Services',
    title: 'Edtech',
  },
  {
    category: 'Software and Services',
    title: 'eSports',
  },
  {
    category: 'Software and Services',
    title: 'FinTech',
  },
  {
    category: 'Software and Services',
    title: 'Legal tech',
  },
  {
    category: 'Software and Services',
    title: 'Software as a service (SaaS)',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Biotechnology',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Life Sciences Tools and Services',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Pharmaceuticals',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Oncology',
  },
  {
    category: 'Pharmaceuticals, Biotechnology and Life Sciences',
    title: 'Life sciences',
  },
  {
    category: 'Automobiles and Components',
    title: 'Automobiles',
  },
  {
    category: 'Banks',
    title: 'Banks',
  },
  {
    category: 'Banks',
    title: 'Thrifts and Mortgage Finance',
  },
  {
    category: 'Banks',
    title: 'Mortgage tech',
  },
  {
    category: 'Cannabis',
    title: 'Cannabis',
  },
  {
    category: 'Capital Goods',
    title: 'Building Products',
  },
  {
    category: 'Capital Goods',
    title: 'Construction and Engineering',
  },
  {
    category: 'Capital Goods',
    title: 'Electrical Equipment',
  },
  {
    category: 'Capital Goods',
    title: 'Machinery',
  },
  {
    category: 'Capital Goods',
    title: 'Trading Companies and Distributors',
  },
  {
    category: 'Commercial and Professional Services',
    title: 'Professional Services',
  },
  {
    category: 'Commercial and Professional Services',
    title: 'Construction Technology',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Household Durables',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Leisure Products',
  },
  {
    category: 'Consumer Durables and Apparel',
    title: 'Textiles, Apparel and Luxury Goods',
  },
  {
    category: 'Consumer Services',
    title: 'Hotels, Restaurants and Leisure',
  },
  {
    category: 'Diversified Financials',
    title: 'Capital Markets',
  },
  {
    category: 'Diversified Financials',
    title: 'Consumer Finance',
  },
  {
    category: 'Diversified Financials',
    title: 'Diversified Financial Services',
  },
  {
    category: 'Diversified Financials',
    title: 'Mortgage Real Estate Investment Trusts (REITs)',
  },
  {
    category: 'Energy',
    title: 'Energy Equipment and Services',
  },
  {
    category: 'Energy',
    title: 'Oil, Gas and Consumable Fuels',
  },
  {
    category: 'Energy',
    title: 'Cleantech',
  },
  {
    category: 'Energy',
    title: 'Climate Tech',
  },
  {
    category: 'Food and Staples Retailing',
    title: 'Food and Staples Retailing',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Beverages',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Food Products',
  },
  {
    category: 'Food, Beverage and Tobacco',
    title: 'Tobacco',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Technology',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Equipment and Supplies',
  },
  {
    category: 'Health Care Equipment and Services',
    title: 'Healthcare Providers and Services',
  },
  {
    category: 'Household and Personal Products',
    title: 'Personal Products',
  },
  {
    category: 'Insurance',
    title: 'Insurance',
  },
  {
    category: 'Materials',
    title: 'Chemicals',
  },
  {
    category: 'Materials',
    title: 'Construction Materials',
  },
  {
    category: 'Materials',
    title: 'Containers and Packaging',
  },
  {
    category: 'Materials',
    title: 'Metals and Mining',
  },
  {
    category: 'Materials',
    title: 'Advanced Manufacturing',
  },
  {
    category: 'Materials',
    title: 'Paper and Forest Products',
  },
  {
    category: 'Materials',
    title: 'Supply chain technology',
  },
  {
    category: 'Media and Entertainment',
    title: 'Media',
  },
  {
    category: 'Media and Entertainment',
    title: 'Entertainment',
  },
  {
    category: 'Media and Entertainment',
    title: 'Interactive Media and Services',
  },
  {
    category: 'Real Estate',
    title: 'Equity Real Estate Investment Trusts (REITs)',
  },
  {
    category: 'Real Estate',
    title: 'Real Estate Management and Development',
  },
  {
    category: 'Retailing',
    title: 'Distributors',
  },
  {
    category: 'Retailing',
    title: 'Internet and Direct Marketing Retail',
  },
  {
    category: 'Retailing',
    title: 'Multiline Retail',
  },
  {
    category: 'Retailing',
    title: 'Specialty Retail',
  },
  {
    category: 'Semiconductors and Semiconductor Equipment',
    title: 'Semiconductors and Semiconductor Equipment',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Communications Equipment',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Technology Hardware, Storage and Peripherals',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Electronic Equipment, Instruments and Components',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Agtech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Audiotech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Autonomous Cars',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'IoT',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Nanotechnology',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Space tech',
  },
  {
    category: 'Technology Hardware and Equipment',
    title: 'Virtual reality (VR)',
  },
  {
    category: 'Telecommunication Services',
    title: 'Diversified Telecommunication Services',
  },
  {
    category: 'Telecommunication Services',
    title: 'Wireless Telecommunication Services',
  },
  {
    category: 'Telecommunication Services',
    title: 'Mobile',
  },
  {
    category: 'Telecommunication Services',
    title: 'Mobile commerce',
  },
  {
    category: 'Telecommunication Services',
    title: 'Technology, media and telecommunications (TMT)',
  },
  {
    category: 'Transportation',
    title: 'Air Freight and Logistics',
  },
  {
    category: 'Transportation',
    title: 'Airlines',
  },
  {
    category: 'Transportation',
    title: 'Marine',
  },
  {
    category: 'Transportation',
    title: 'Road and Rail',
  },
  {
    category: 'Transportation',
    title: 'Transportation Infrastructure',
  },
  {
    category: 'Utilities',
    title: 'Electric Utilities',
  },
  {
    category: 'Utilities',
    title: 'Gas Utilities',
  },
  {
    category: 'Utilities',
    title: 'Independent Power Producers and Energy Traders',
  },
  {
    category: 'Utilities',
    title: 'Multi-Utilities',
  },
  {
    category: 'Utilities',
    title: 'Water Utilities',
  },
];

const industryVerticalsPlainList = industryVerticals
  .data
  .map(({ category, subcategories }) => {
    return subcategories.map(({ verticals, subcategory }) => {
      return verticals.map((vertical) => `${category} - ${subcategory} - ${vertical}`);
    }).flat();
  })
  .flat()
  .sort((a, b) => a.localeCompare(b));

const industryVerticalsListWithCategories = industryVerticals
  .data
  .map(({ category, subcategories }) => {
    return subcategories.map(({ verticals, subcategory }) => {
      return verticals.map((vertical) => {
        return {
          category,
          subcategory,
          title: vertical,
        };
      });
    }).flat();
  })
  .flat()
  .sort((a, b) => a.category.localeCompare(b.category));

const industryVerticalsListWithTwoLevelsOfCategories = industryVerticals
  .data
  .map(({ subcategories }) => {
    return subcategories.map(({ verticals, subcategory }) => {
      return verticals.map((vertical) => {
        return {
          category: subcategory,
          title: vertical,
        };
      });
    }).flat();
  })
  .flat()
  .sort((a, b) => a.category.localeCompare(b.category));


export {
  industryVerticals,
  industryVerticalsPlainList,
  industryVerticalsListWithCategories,
  industryVerticalsListWithTwoLevelsOfCategories,
  industryVerticalsAlphabetizedAndSorted,
};
