import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SharePriceCards.scss';
import { Button, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { commaEvery3rdChar } from '../../utils';

export default function SharePriceCards({
  color,
  title,
  data,
  auditRiskLabel,
}) {
  const [showToolTip, setShowToolTip] = useState(false);
  const toolTips = {
    'Share price': {
      text: `Move the share price slider's blue handle to change the share price.
      Click the optimize button to optimize the share price for your company or employees.`,
      placement: 'top-start',
    },
    'Defensible outcomes': {
      text: `'Defensible outcomes'
      is the number of times the selected share price was the outcome of an iteration.
      All outcomes in this sandbox are capable of being defended in case of an audit.`,
      placement: 'top-start',
    },
    'Total Iterations': {
      text: `This is the total number of times initio's proprietary calculation
      engine iterated on your company's share price. It processed multiple methodologies,
      that our valuation experts reviewed and finalized.`,
      placement: 'top',
    },
    Term: {
      text: `A term is the length of time until an expected exit event,
      such as a Merger
      and Acquisition (M&A) deal, Initial public offering (IPO), etc.
      This is the term used in the chosen scenario for the currently selected share price.`,
      placement: 'top',
    },
    Volatility: {
      text: `Volatility is a measure of uncertainty and risk related to the size of changes within a securities value.
      We use current industry data to set this value.`,
      placement: 'top',
    },
    'NTM Options': {
      text: `NTM Options is the number of options the company expects to issue over the next twelve months.
      It is calculated as a percentage of the remaining option pool as of the valuation date.`,
      placement: 'top-end',
    },
    DLOM: {
      text: `Discount for Lack of Marketability (DLOM),
      is the discount taken due to privately held stock not being easily exchanged without a significant loss in value.
      The lower the value shown here, the greater the discount.`,
      placement: 'top-end',
    },
  };

  return (
    <div className="sharePriceCards">
      <ClickAwayListener onClickAway={() => setShowToolTip(false)} touchEvent="onTouchStart">
        <Tooltip
          title={toolTips[`${title}`].text}
          placement={toolTips[`${title}`].placement}
          PopperProps={{
            disablePortal: true,
          }}
          open={showToolTip}
          disableFocusListener
          arrow
        >
          <Button
            onClick={() => {
              setShowToolTip(!showToolTip);
            }}
            onMouseOver={() => { setShowToolTip(true); }}
            onMouseLeave={() => { setShowToolTip(false); }}
            className={`share-price-container ${auditRiskLabel} ${color}`}
          >
            <span className="share-price-data">{commaEvery3rdChar(data)}</span>
            <span className="share-price-title">{title}</span>
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
}

SharePriceCards.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.string,
  auditRiskLabel: PropTypes.string,
};
