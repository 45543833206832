import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import { ReactComponent as CancellationCalendar } from '../../images/icons/cancellation_calendar_white.svg';

import { UserDataContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

export default function CancellationModal({ showCancellationModal, setShowCancellationModal }) {
  const [{ loading: cancellingSubscription }, cancelSubscriptionRequest] = useFetch();

  const { userData, setUserData } = useContext(UserDataContext);

  function cancelSubscription() {
    cancelSubscriptionRequest({
      url: '/subscriptions/cancel-current-subscription',
      method: 'post',
      urlIds: ['companyId'],
      onSuccess: () => {
        setShowCancellationModal(false);
        setUserData({ ...userData, subscriptionData: { ...userData.subscriptionData, isActive: 0, cancellationDateTime: moment().format('YYYY-MM-DD HH:mm:ss') } });
      },
    });
  }

  return (
    <Dialog className="CancellationModal" open={showCancellationModal}>
      <IconButton onClick={() => setShowCancellationModal(false)}>
        <CloseIcon />
      </IconButton>
      <h4>
        <NotificationImportantOutlinedIcon />
        Cancel your initio subscription?
        <br />
        It&apos;s free to renew, and to continue a subscription.
      </h4>
      <p>If you cancel your subscription you may not be able to access your valuations and  other data on our platform.</p>
      <p>
        With a subscription you&rsquo;ll be the first to access our new products while in Beta, for free.
        You&apos;ll also get your 409A valuation within 5 business days, guaranteed, instead of 7.
      </p>
      <p>Subscription is commitment free, you only pay when you want a new 409A valuation.</p>
      <div className="bottom-btns">
        <Button disabled={cancellingSubscription} onClick={() => setShowCancellationModal(false)}>
          <EventRepeatOutlinedIcon />
          Continue my subscription for free
        </Button>
        {cancellingSubscription ? (
          <Button disabled>
            <span className="dots-circle-spinner" />
            Cancelling subscription...
          </Button>
        ) : (
          <Button onClick={cancelSubscription}>
            <CancellationCalendar />
            Cancel my subscription
          </Button>
        )}
      </div>
    </Dialog>
  );
}

CancellationModal.propTypes = {
  showCancellationModal: PropTypes.bool.isRequired,
  setShowCancellationModal: PropTypes.func.isRequired,
};
