import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";

import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';

import './CalendlyModal.scss';

export default function CalendlyModal({ showCalendlyModal, setShowCalendlyModal }) {
  const [calendlyLoaded, setCalendlyLoaded] = useState(false);

  useCalendlyEventListener({ onEventTypeViewed: () => setCalendlyLoaded(true) });

  return (
    <Dialog className="CalendlyModal" open={showCalendlyModal}>
      {calendlyLoaded && (
        <IconButton
          onClick={() => {
            setShowCalendlyModal(false);
            setCalendlyLoaded(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <InlineWidget
        url="https://calendly.com/alex-initio/30min?hide_gdpr_banner=1"
        styles={{
          minWidth: '1000px',
          height: '800px',
        }}
      />
    </Dialog>
  );
}

CalendlyModal.propTypes = {
  showCalendlyModal: PropTypes.bool.isRequired,
  setShowCalendlyModal: PropTypes.func.isRequired,
};
