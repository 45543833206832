import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';

import Tr409AInProgressModal from '../../components/Modals/Tr409AInProgressModal';

import useFetch from '../../hooks/useFetch';

import { UserDataContext } from '../../contexts';

export default function New409AReSubModal({ showNew409AReSubModal, setShowNew409AReSubModal }) {
  const [startingNewValuation, setStartingNewValuation] = useState(false);

  const [showTr409AInProgressModal, setShowTr409AInProgressModal] = useState(false);

  const { userData, setUserData } = useContext(UserDataContext);

  const [, renewSubscriptionRequest] = useFetch();
  const [, startNew409ARequest] = useFetch();

  const nav = useNavigate();

  function startNew409A() {
    if (userData.userState !== 'report-ready') {
      setShowNew409AReSubModal(false);
      setShowTr409AInProgressModal(true);
      return;
    }
    setStartingNewValuation(true);
    renewSubscriptionRequest({
      url: '/subscriptions/renew-subscription',
      method: 'post',
      urlIds: ['userId', 'companyId'],
      onSuccess: () => {
        setUserData({
          ...userData,
          subscriptionData: {
            ...userData.subscriptionData,
            isActive: 1,
            startDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            endDateTime: moment().add(1, 'years').format('YYYY-MM-DD HH:mm:ss'),
          },
        });
        startNew409ARequest({
          url: '/transactions/new-transaction',
          method: 'post',
          urlIds: ['companyId'],
          onSuccess: () => nav('/409a-info?newTransaction=true'),
        });
      },
    });
  }

  return (
    <>
      <Dialog className="New409AReSubModal" open={showNew409AReSubModal}>
        <IconButton onClick={() => setShowNew409AReSubModal(false)}>
          <CloseIcon />
        </IconButton>
        <h4>
          <NotificationImportantOutlinedIcon />
          {`${userData.userState === '409a-in-progress' ? 'Resume' : 'Start a new'} 409A valuation?`}
          <br />
          You will be resubscribed automatically for free.
        </h4>
        <p>{`Your annual initio™ subscription was cancelled on ${moment(userData.subscriptionData?.cancellationDateTime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')}.`}</p>
        <p>
          To proceed with a new 409A valuation you must have a current, annual initio subscription.
          Subscription is currently free, and auto-renews in 1 year.
          We will send reminders when your subscription is coming due. You can cancel any time.
        </p>
        <p>
          If that sounds good, then go ahead and start a new 409A. Simply fill out and submit your 409A valuation information,
          and pay when your ready for us to begin working on your valuation.
        </p>
        <div className="bottom-btns">
          <Button disabled={startingNewValuation} onClick={() => setShowNew409AReSubModal(false)}>
            Cancel
          </Button>
          {startingNewValuation ? (
            <Button disabled>
              <span className="dots-circle-spinner" />
              {`${userData.userState === '409a-in-progress' ? 'Resuming' : 'Starting new'} 409A...`}
            </Button>
          ) : (
            <Button onClick={startNew409A}>
              {`${userData.userState === '409a-in-progress' ? 'Resume' : 'Start new'} 409A`}
            </Button>
          )}
        </div>
      </Dialog>
      <Tr409AInProgressModal showTr409AInProgressModal={showTr409AInProgressModal} setShowTr409AInProgressModal={setShowTr409AInProgressModal} />
    </>
  );
}

New409AReSubModal.propTypes = {
  showNew409AReSubModal: PropTypes.bool.isRequired,
  setShowNew409AReSubModal: PropTypes.func.isRequired,
};
