import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
// Icon
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import PanelButtons from './PanelButtons';
import AnimatedDropdown from './AnimatedDropdown';
import ChooseFundingRoundDate from './ChooseFundingRoundDate';
import ToolTip from './ToolTip';
import {
  formatInputToNumWith2Decimals,
  doesNumHaveLeading0s,
  removeCommas,
  trimLeading0s,
} from '../../utils';
import {
  SAFE,
  convertibleNote,
  noFunding,
  fundingType,
} from '../../utils/globals';
import './Wiz3.scss';

export default function Wiz3({
  currentStep,
  // Step #1
  totalAmountRaised,
  setTotalAmountRaised,
  // Step #2
  setChosenFunding,
  chosenFunding,
  // Step #3
  currentFundingDate,
  setCurrentFundingDate,
  // ------------ spacer ------------
  totalSafeOrConvertible,
  setTotalSafeOrConvertible,
  // ------------ spacer ------------
  interestRate,
  setInterestRate,
  // ------------ spacer ------------
  valuationCap,
  setValuationCap,
  // ------------ spacer ------------
  preOrPostMoneyNotes,
  setPreOrPostMoneyNotes,
  // Step #4
  receivedFutureTerms,
  setReceivedFutureTerms,
  // Step #5
  anySecondaryCommonStockTransactions,
  setAnySecondaryCommonStockTransactions,
  // ------------ spacer ------------
  secondaryCommonStockTransactionTotalAmount,
  setSecondaryCommonStockTransactionTotalAmount,
  // ------------ spacer ------------
  percentageSecondaryCommonStockSold,
  setPercentageSecondaryCommonStockSold,
}) {
  const [
    percentageSecondaryCommonStockSoldErr,
    setPercentageSecondaryCommonStockSoldErr,
  ] = useState(false);

  // helper
  function fundingTypeDefinitionsDropdown() {
    const fundingTypeDefinitions = [
      {
        title: 'SAFE:',
        // eslint-disable-next-line max-len
        description: 'stands for \'Simple Agreement for Future Equity\'. It is used by startups and investors in seed-stage funding deals. It grants investors the right to purchase equity in the company at a future date.',
      },
      {
        title: 'Convertible Note:',
        description: 'a debt security that is convertible into equity, typically at the terms of the current or next round.',
      },
      {
        title: 'Angel:',
        // eslint-disable-next-line max-len
        description: 'typically a small round designed to get a new company off the ground. Investors in an angel round include individual angel investors, angel investor groups, friends, and family.',
      },
      {
        title: 'Pre-Seed:',
        description: 'either has no institutional investors or is a very low amount, often below $150k.',
      },
      {
        title: 'Series Seed:',
        // eslint-disable-next-line max-len
        description: 'the first rounds of funding company, often for a young company working to gain traction, often comes after an angel round (if applicable) and before a company\'s Series A round.',
      },
      {
        title: 'Series A and Series B',
        description: 'rounds are funding rounds for earlier stage companies.',
      },
      {
        title: 'Series C rounds and onwards',
        description: 'are for later stage and more established companies.',
      },
    ];

    return (
      <AnimatedDropdown
        title="Funding type definitions"
        customContainerStyles={{ marginTop: '34px' }}
        content={(
          <div>
            {
              fundingTypeDefinitions.map(({ title, description }) => {
                const keyIndex = description.replaceAll(' ', '-');

                return (
                  <p
                    key={keyIndex}
                    style={{ marginBottom: '16px' }}
                  >
                    <span style={{ fontWeight: 700 }}>{title}</span>
                    {' '}
                    {description}
                  </p>
                );
              })
            }
          </div>
        )}
      />
    );
  }

  if (currentStep === 1) {
    return (
      <div>
        <h4>Total amount raised by company</h4>
        <p>
          This is the cumulative amount raised by the company since its inception.
        </p>
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            Total amount raised
          </InputLabel>
          <OutlinedInput
            className="money-val-inputs"
            id="outlined-adornment-amount"
            value={totalAmountRaised || ''}
            onChange={(e) => {
              const { value } = e.target;
              setTotalAmountRaised(formatInputToNumWith2Decimals(value));
            }}
            startAdornment={(
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            )}
            label="Total amount raised"
          />
        </FormControl>
        <AnimatedDropdown
          title="More information about 'Total amount raised'"
          customContainerStyles={{ marginTop: '34px' }}
          useToolTipBackground={false}
          content={(
            <div style={{ marginLeft: '23px' }}>
              <p>
                This is the total amount of cash raised by the Company since its inception.
                This will include cash raised from equity financings as well as the
                principal amounts raised from convertible notes, SAFEs, and other similar
                convertible debt securities. This does not include any interest accrued on
                debt that would be converted into equity.
              </p>
            </div>
          )}
        />
      </div>
    );
  }

  if (currentStep === 2) {
    return (
      <div>
        <h4>Current funding type</h4>
        <p>
          If you&apos;re getting a 409A valuation as part of a current
          round of funding, select the type of funding below. If this
          409A valuation is not connected to a round of funding, simply
          choose &lsquo;no funding&rsquo;.
        </p>
        <PanelButtons
          parentSetter={setChosenFunding}
          options={fundingType.concat(noFunding)}
          customOptionStyles={{ width: '178px' }}
          parentSelectedOption={chosenFunding}
        />
        {fundingTypeDefinitionsDropdown()}
      </div>
    );
  }

  if (currentStep === 3 &&
    ((chosenFunding === SAFE) || (chosenFunding === convertibleNote))
  ) {
    return (
      <div className="Wiz3 step-2">
        <h4>SAFE Note or Convertible Note</h4>
        <p>
          You selected a SAFE Note or Convertible note as the funding
          type for your current round of funding.
          <br />
          Tell us more about it.
        </p>
        <ChooseFundingRoundDate
          title="When did you raise this current round of funding?"
          label="Date of current round of funding"
          parentSetter={setCurrentFundingDate}
          parentChosenValue={currentFundingDate}
        />
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            Total amount raised
          </InputLabel>
          <OutlinedInput
            className="money-val-inputs"
            id="outlined-adornment-amount"
            value={totalSafeOrConvertible}
            onChange={(e) => {
              const { value } = e.target;

              setTotalSafeOrConvertible(formatInputToNumWith2Decimals(value));
            }}
            startAdornment={(
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            )}
            label="Total amount raised"
          />
        </FormControl>

        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="interest-rate">
            Interest rate
          </InputLabel>
          <OutlinedInput
            className="money-val-inputs"
            id="interest-rate"
            value={interestRate}
            onChange={(e) => {
              const { value } = e.target;
              setInterestRate(formatInputToNumWith2Decimals(value));
            }}
            startAdornment={(
              <InputAdornment position="start">
                <PercentIcon />
              </InputAdornment>
            )}
            label="Interest rate"
          />
        </FormControl>

        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="outlined-adornment-amount">
            Valuation cap
          </InputLabel>
          <OutlinedInput
            className="money-val-inputs"
            id="outlined-adornment-amount"
            value={valuationCap}
            onChange={(e) => {
              const { value } = e.target;
              setValuationCap(formatInputToNumWith2Decimals(value));
            }}
            startAdornment={(
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            )}
            label="Valuation cap"
          />
        </FormControl>

        <FormControl className="money-val-inputs" sx={{ m: 1 }}>
          <p id="pre-or-post-money-notes">
            Is the valuation cap based on pre-money or post-money?
          </p>
          <RadioGroup
            aria-labelledby="pre-or-post-money-notes"
            name="pre-post-money-rounds-group"
            row
            onChange={(e) => {
              e.preventDefault();
              setPreOrPostMoneyNotes(e.target.value);
            }}
            sx={{ fontSize: '10px' }}
            value={preOrPostMoneyNotes}
          >
            {
              [
                'Pre-money',
                'Post-money',
              ].map((NoteType) => (
                <FormControlLabel
                  className="pre-post-money-labels"
                  key={NoteType}
                  value={NoteType}
                  control={<Radio />}
                  label={NoteType}
                />
              ))
            }
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  if ((currentStep === 3) && (chosenFunding === noFunding)) {
    return (
      <div className="Wiz3 step-2">
        <h4>No current round of funding</h4>
        <p>
          So, this 409A valuation isn&apos;t directly connected to a current round of funding.
          Got it. While we most likely have all the information we need to proceed, we may
          decide to follow-up with you just to be sure.
        </p>
      </div>
    );
  }

  if (currentStep === 3) {
    return (
      <div className="Wiz3 step-2">
        <h4>Current round of funding</h4>
        <p>
          This date should typically match the date of your latest round in
          your filed Articles of Incorporation.
          If the latest round is still open, please use the date of the most recent close.
        </p>
        <ChooseFundingRoundDate
          label="Date of current round of funding"
          parentSetter={setCurrentFundingDate}
          parentChosenValue={currentFundingDate}
        />
      </div>
    );
  }

  if (currentStep === 4) {
    return (
      <div>
        <h4>
          Has the company received any term sheet(s) for future
          rounds of funding?
        </h4>
        <p>
          We&apos;re only asking about term sheet(s) relating to future rounds.
          These are not term sheets relating to this current round
          of funding used for this valuation.
        </p>
        <FormControl>
          <RadioGroup
            value={receivedFutureTerms}
            row
            onChange={(e) => {
              e.preventDefault();
              setReceivedFutureTerms(JSON.parse(e.target.value));
            }}
          >
            <FormControlLabel value control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <ToolTip
          title="Important to know"
          icon="WarningAmberIcon"
          content={(
            <div style={{ marginLeft: '23px', width: '650px' }}>
              <p>
                If you have anything written, in any form, signed or not signed,
                that discusses specific terms and conditions that has any likelihood
                that it will close, include it.
                If you don&apos;t you could be liable and the IRS can penalize you.
              </p>
            </div>
          )}
        />
        <AnimatedDropdown
          title="What is a term sheet?"
          customContainerStyles={{ marginTop: '34px' }}
          content={(
            <div style={{ marginLeft: '23px', width: '650px' }}>
              <p>
                A term sheet is any non-binding written agreement that outlines
                the terms and conditions of an investment.
                It can take the form of a digital file, an email in someone&apos;s inbox,
                anything that outlines terms and conditions of an investment.
              </p>
            </div>
          )}
          useToolTipBackground={false}
        />
      </div>
    );
  }

  if (currentStep === 5) {
    return (
      <div className="Wiz3 step-5">
        <h4>Has the company had any secondary common stock transactions?</h4>
        <p>
          Secondary transactions occur when shareholders sell a directly held
          ownership interest in a company. For example, if your
          investors have sold common stock, then the company has had a
          secondary transaction.
        </p>
        <FormControl>
          <RadioGroup
            value={anySecondaryCommonStockTransactions}
            row
            onChange={(e) => {
              e.preventDefault();
              setAnySecondaryCommonStockTransactions(JSON.parse(e.target.value));
            }}
          >
            <FormControlLabel value control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {
          anySecondaryCommonStockTransactions && (
            <div>
              <hr />
              <div className="secondary-stock-transactions-container">
                <FormControl
                  sx={{ m: 1 }}
                  key="total-common-stock-secondary-transaction"
                >
                  <InputLabel htmlFor="total-common-stock-secondary-transaction">
                    Total amount of transaction
                  </InputLabel>
                  <OutlinedInput
                    className="secondary-stock-transaction-input"
                    id="total-common-stock-secondary-transaction"
                    value={secondaryCommonStockTransactionTotalAmount}
                    onChange={(e) => {
                      e.preventDefault();
                      setSecondaryCommonStockTransactionTotalAmount(
                        formatInputToNumWith2Decimals(e.target.value),
                      );
                    }}
                    startAdornment={(
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    )}
                    label="Total amount of transaction"
                  />
                </FormControl>

                <FormControl
                  sx={{ m: 1 }}
                  key="percentage-common-stock-sold"
                >
                  <InputLabel
                    htmlFor="percentage-common-stock-sold"
                    error={percentageSecondaryCommonStockSoldErr}
                  >
                    Percent of common stock sold
                  </InputLabel>
                  <OutlinedInput
                    error={percentageSecondaryCommonStockSoldErr}
                    className="secondary-stock-transaction-input"
                    id="percentage-common-stock-sold"
                    value={percentageSecondaryCommonStockSold}
                    onBlur={(event) => {
                      event.preventDefault();
                      let formattedNumOnBlur = event.target.value.trim();

                      if (doesNumHaveLeading0s(formattedNumOnBlur)) {
                        formattedNumOnBlur = trimLeading0s(removeCommas(formattedNumOnBlur));
                        setPercentageSecondaryCommonStockSold(
                          formatInputToNumWith2Decimals(formattedNumOnBlur),
                        );
                      }

                      if (parseFloat(formattedNumOnBlur) > 100) {
                        if (percentageSecondaryCommonStockSoldErr === false) {
                          setPercentageSecondaryCommonStockSoldErr(true);
                        }
                      } else if (percentageSecondaryCommonStockSoldErr) {
                        setPercentageSecondaryCommonStockSoldErr(false);
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      let formattedNum = e.target.value.trim();

                      if (doesNumHaveLeading0s(formattedNum)) {
                        formattedNum = trimLeading0s(removeCommas(formattedNum));
                      }

                      formattedNum = formatInputToNumWith2Decimals(formattedNum);

                      if (parseFloat(formattedNum) > 100) {
                        if (percentageSecondaryCommonStockSoldErr === false) {
                          setPercentageSecondaryCommonStockSoldErr(true);
                        }
                      } else {
                        if (percentageSecondaryCommonStockSoldErr) {
                          setPercentageSecondaryCommonStockSoldErr(false);
                        }
                        setPercentageSecondaryCommonStockSold(formattedNum);
                      }
                    }}
                    startAdornment={(
                      <InputAdornment position="start">
                        <PercentIcon />
                      </InputAdornment>
                    )}
                    label="Percent of common stock sold"
                  />

                  <FormHelperText
                    error={percentageSecondaryCommonStockSoldErr}
                    className={(`percentage-common-stock-sold-error
                      ${percentageSecondaryCommonStockSoldErr ? '' : 'hide-error'}
                    `)}
                  >
                    Enter percentage that is less than or equal to 100%
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  return (
    <div>
      <h3>Error: Not a valid step for the 3rd 409a Wizard</h3>
    </div>
  );
}

Wiz3.propTypes = {
  currentStep: PropTypes.number,
  // Step #1
  totalAmountRaised: PropTypes.string,
  setTotalAmountRaised: PropTypes.func,
  // Step #2
  chosenFunding: PropTypes.string,
  setChosenFunding: PropTypes.func,
  // Step #3
  currentFundingDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setCurrentFundingDate: PropTypes.func,
  // ------------ spacer ------------
  totalSafeOrConvertible: PropTypes.string,
  setTotalSafeOrConvertible: PropTypes.func,
  // ------------ spacer ------------
  interestRate: PropTypes.string,
  setInterestRate: PropTypes.func,
  // ------------ spacer ------------
  valuationCap: PropTypes.string,
  setValuationCap: PropTypes.func,
  // ------------ spacer ------------
  preOrPostMoneyNotes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setPreOrPostMoneyNotes: PropTypes.func,
  // Step #4
  receivedFutureTerms: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  setReceivedFutureTerms: PropTypes.func,
  // Step #5
  anySecondaryCommonStockTransactions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  setAnySecondaryCommonStockTransactions: PropTypes.func,
  // ------------ spacer ------------
  secondaryCommonStockTransactionTotalAmount: PropTypes.string,
  setSecondaryCommonStockTransactionTotalAmount: PropTypes.func,
  // ------------ spacer ------------
  percentageSecondaryCommonStockSold: PropTypes.string,
  setPercentageSecondaryCommonStockSold: PropTypes.func,
};
