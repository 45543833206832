import React from 'react';
import PropTypes from 'prop-types';
import './Wiz4Header.scss';

export default function Wiz4Header({
  title,
  icon,
  content,
  nameOfClass,
}) {
  return (
    <div className={nameOfClass ? `Wiz4Header ${nameOfClass}` : 'Wiz4Header'}>
      <h4>{title}</h4>
      <div className="icon-and-description-container">
        {icon}
        <div className="description-wrapper">
          {
            typeof content === 'string' ? <p>{content}</p> : content
          }
        </div>
      </div>
    </div>
  );
}

Wiz4Header.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  nameOfClass: PropTypes.string,
};
