class IdleTimer {
  constructor({ timeout, onTimeout, global = false }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.global = global;

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = this.global ? parseInt(localStorage.getItem('_globalTime'), 10) : parseInt(localStorage.getItem('_expiredTime'), 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      if (this.global) {
        localStorage.setItem('_globalTime', Date.now() + this.timeout * 1000);
      } else {
        localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000);
      }
    }, 300);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    if (global) {
      localStorage.removeItem('_globalTime');
    } else {
      localStorage.removeItem('_expiredTime');
    }
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
