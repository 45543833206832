import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { ReactComponent as WaitingGraphic } from '../../images/WaitingGraphic.svg';
import { ReactComponent as HelloGraphic } from '../../images/HelloGraphic.svg';
import { ReactComponent as SandboxReadyGraphic } from '../../images/SandboxReadyGraphic.svg';

import HomepageHeader from './Components/HomepageHeader';

import './SplashPage.scss';

export default function SplashPage({ currentUserState }) {
  const nav = useNavigate();

  return (
    <div className="SplashPage">
      <div className="splash-content-wrapper">
        <HomepageHeader />
        <div className="splash-body-wrapper">

          {currentUserState === '409a-completed' ?
            <WaitingGraphic className="splash-graphic" /> :
            currentUserState === 'sandbox-ready' ?
              <SandboxReadyGraphic className="splash-graphic" /> :
              <HelloGraphic className="splash-graphic" />}

          {(currentUserState === '409a-completed' || currentUserState === 'sandbox-ready') && (
            <span className="title">
              {currentUserState === 'sandbox-ready' ?
                'Check it out. Your 409A valuation sandbox is ready.' :
                'Sit back and relax, your 409A valuation form and documents are being reviewed.'}
            </span>
          )}

          {currentUserState === '409a-not-started' ? (
            <>
              <p>Success! You&apos;re all set up with your initio account.</p>
              <p>You get a free 409A valuation per year. Get started!</p>
            </>
          ) : currentUserState === '409a-in-progress' ? (
            <>
              <p>You&apos;ve started a 409A valuation form. We&apos;ve saved your progress.</p>
              <p>Simply click &apos;Resume 409A valuation&apos; whenever you&apos;re ready to proceed.</p>
            </>
          ) : (
            <>
              {currentUserState === '409a-completed' &&
                <p>We&apos;ll email you within the next 7 business days when your 409A valuation sandbox is ready.</p>}
              <p>The sandbox is where you can, in real-time, customize your 409A before you lock in your final valuation.</p>
            </>
          )}

          <div className="button-wrapper">
            {currentUserState !== '409a-completed' && (
              <Button
                startIcon={currentUserState === 'Start a new 409A' && <AddIcon />}
                onClick={() => nav(currentUserState === 'sandbox-ready' ? '/409a-price-sandbox' : '/409a-info')}
              >
                {currentUserState === '409a-not-started' ? 'Start a 409A valuation' :
                  currentUserState === '409a-in-progress' ? 'Resume 409A valuation' : 'Check out your 409A valuation sandbox'}
              </Button>
            )}
            {(currentUserState === '409a-completed' || currentUserState === 'sandbox-ready') && (
              <Button onClick={() => nav('/409a-info?review=true')}>View submitted information</Button>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}

SplashPage.propTypes = {
  currentUserState: PropTypes.string.isRequired,
};
