import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import SideNavBar from './SideNavBar';

import {
  UserDataContext,
  UserStateContext,
  NavWidthContext,
} from '../../contexts';

import { ReactComponent as InitioLogo } from '../../images/initio-logo.svg';

import useSignOut from '../../hooks/useSignOut';

import './NavBar.scss';

export default function NavBar() {
  const nav = useNavigate();

  const { userData: { userAttributes } } = useContext(UserDataContext);
  const { userState } = useContext(UserStateContext);

  const { navWidth } = useContext(NavWidthContext);

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false);
  const [supportMenuAnchorEl, setSupportMenuAnchorEl] = useState(null);
  const [supportMenuIsOpen, setSupportMenuIsOpen] = useState(false);

  const [signOut] = useSignOut();

  function handleMenuOpen(e, type) {
    if (type === 'account') {
      setAccountMenuAnchorEl(e.currentTarget);
      setAccountMenuIsOpen(true);
    } else {
      setSupportMenuAnchorEl(e.currentTarget);
      setSupportMenuIsOpen(true);
    }
  }

  function handleMenuClose(type) {
    if (type === 'account') {
      setAccountMenuIsOpen(false);
      setAccountMenuAnchorEl(null);
    } else {
      setSupportMenuAnchorEl(null);
      setSupportMenuIsOpen(false);
    }
  }

  return (
    <div className={`NavBar ${userState === 'client-intake' ? 'no-side-nav' : ''}`} style={{ minWidth: navWidth }}>
      {userState !== 'client-intake' && <SideNavBar />}
      <IconButton
        className="initio-logo"
        onClick={() => window.location.assign(process.env.REACT_APP_INITIO_HOSTNAME)}
      >
        <InitioLogo />
      </IconButton>
      <div className="right-nav">
        <IconButton
          className={`support-btn ${supportMenuIsOpen ? 'active' : ''}`}
          onClick={(e) => handleMenuOpen(e)}
        >
          <HelpOutlineOutlinedIcon className="support-icon" />
        </IconButton>
        <Menu
          anchorEl={supportMenuAnchorEl}
          open={supportMenuIsOpen}
          onClose={() => handleMenuClose('support')}
          className="support-dropdown"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          disableScrollLock
        >
          <MenuItem
            className={window.location.pathname === '/support' ? 'selected' : ''}
            onClick={() => {
              handleMenuClose('support');
              nav('/support');
            }}
          >
            <SupportAgentOutlinedIcon />
            Customer Support
          </MenuItem>
          <MenuItem
            className={window.location.pathname === '/demo' ? 'selected' : ''}
            onClick={() => {
              handleMenuClose('support');
              nav('/demo');
            }}
          >
            <CoPresentOutlinedIcon />
            Request a demo
          </MenuItem>
        </Menu>
        {(!userAttributes.firstName || !userAttributes.lastName) ? (
          <IconButton
            disableRipple
            onClick={signOut}
            className="no-name-sign-out"
          >
            <LogoutOutlinedIcon className="log-out-icon" />
            <span>Sign out</span>
          </IconButton>
        ) : (
          <>
            <div className="user-name">
              {`${userAttributes.firstName} ${userAttributes.lastName.charAt(0)}.`}
            </div>
            <IconButton
              className={`profile-circle-button ${accountMenuIsOpen ? 'active' : ''}`}
              onClick={(e) => { handleMenuOpen(e, 'account'); }}
            >
              {userAttributes.firstName.charAt(0)}
            </IconButton>
            <Menu
              anchorEl={accountMenuAnchorEl}
              open={accountMenuIsOpen}
              className="profile-menu"
              onClose={() => handleMenuClose('account')}
              disableScrollLock
            >
              <MenuItem
                className={window.location.pathname === '/account-info' ? 'selected' : ''}
                onClick={() => {
                  nav('/account-info');
                  handleMenuClose('account');
                }}
              >
                <PersonOutlinedIcon />
                Account
              </MenuItem>
              <MenuItem
                className={window.location.pathname === '/subscription' ? 'selected' : ''}
                onClick={() => {
                  nav('/subscription-info');
                  handleMenuClose('account');
                }}
              >
                <SettingsOutlinedIcon />
                Subscription
              </MenuItem>
              <MenuItem
                onClick={() => {
                  signOut();
                  handleMenuClose('account');
                }}
              >
                <LogoutOutlinedIcon />
                Sign out
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </div>
  );
}
