import React, { useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';

import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';

// This project
import { industryVerticalsAlphabetizedAndSorted } from '../../utils/IndustryVerticals';


import ToolTip from './ToolTip';
import PanelButtons from './PanelButtons';
import './Wiz1.scss';

export default function Wiz1({
  currentStep,
  valuationDate,
  setValuationDate,
  setWhenIsExitLikelyToHappen,
  setWhatTypeOfExit,
  setIndustryVertical,
  whenIsExitLikelyToHappen,
  whatTypeOfExit,
  industryVertical,
}) {
  const [isOpen, setIsOpen] = useState(false);


  if (currentStep === 1) {
    return (
      <div className="Wiz1 step-1">
        <h4>Valuation date</h4>
        <p>
          Before we get into the details of your company, we first need to know if
          the valuation date for this specific valuation is something other than today&apos;s date.
        </p>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            sx={{ width: '380px' }}
            inputFormat="MM/DD/YYYY"
            value={valuationDate}
            onChange={(val) => setValuationDate(val)}
            slotProps={{ textField: { variant: 'outlined' } }}
            slots={{ openPickerIcon: BlueCalendar }}
          />
          <FormHelperText
            style={{
              height: '18px',
              marginLeft: '12px',
              fontSize: '12px',
            }}
          >
            MM/DD/YYYY
          </FormHelperText>
        </LocalizationProvider>
        <ToolTip
          content={(
            <p>
              The valuation date defaults to today&apos;s date if you don&apos;t edit it here.
              That may be sufficient, however there are cases when the date is different than the date you initiate
              your valuation; in that case, it&apos;s important that you update the date here. The valuation date can significantly
              impact your company&apos;s valuation.
            </p>
          )}
        />
      </div>
    );
  }
  if (currentStep === 2) {
    return (
      <div className="Wiz1 step-1">
        <h4>Industry</h4>
        <Autocomplete
          disablePortal
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onOpen={() => {
            setIsOpen(true);
          }}
          options={industryVerticalsAlphabetizedAndSorted}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.title}
          inputValue={industryVertical ? industryVertical.title : ''}
          onInputChange={(e, value) => {
            if (e) {
              e.preventDefault();

              if (!value) {
                setIndustryVertical(null);
                return;
              }

              const newValue = industryVerticalsAlphabetizedAndSorted
                .find((val) => val.title === value);

              setIndustryVertical(newValue || { title: value });
            }
          }}
          renderInput={(params) => <TextField {...params} label="Company" />}
        />
        <ToolTip
          content={(
            <p>
              <a
                href="https://www.bls.gov/iag/tgs/iag_index_alpha.htm"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
              {' '}
              to open a new tab with a complete list of company industries.
            </p>
          )}
        />
      </div>
    );
  }
  if (currentStep === 3) {
    const exitOptions = [
      'Merger and acquisition (M&A)',
      'Initial public offering (IPO)',
      'Don\'t know',
      'Other',
    ];

    return (
      <div className="Wiz1 step-2">
        <h4>What type of exit event do you think is most likely?</h4>
        <p>
          We know you can&apos;t predict the future, so provide
          information based on what you think is most likely, and
          we&apos;ll take care of the rest.
        </p>
        <PanelButtons
          key="what-type-of-exit"
          parentSetter={setWhatTypeOfExit}
          options={exitOptions}
          parentSelectedOption={whatTypeOfExit}
        />
      </div>
    );
  }
  if (currentStep === 4) {
    const exitTimeOptions = [
      'Less than 1 year',
      '1 year',
      '2 years',
      '3 years',
      '4 years',
      '5 years or more',
    ];

    return (
      <div className="Wiz1 step-3">
        <h4>When is this exit event most likely to happen?</h4>
        <p>
          Provide your best guess as to when the exit event is most likely to occur.
          If it is equal or greater than 5 years, please select 5 years or more.
        </p>
        <PanelButtons
          key="when-is-the-exit-likely-to-happen"
          parentSetter={setWhenIsExitLikelyToHappen}
          options={exitTimeOptions}
          leftJustified={false}
          parentSelectedOption={whenIsExitLikelyToHappen}
        />
      </div>
    );
  }

  return (
    <div>
      <h3>Not a valid step for the 1st 409a Wizard</h3>
    </div>
  );
}

Wiz1.propTypes = {
  currentStep: PropTypes.number,
  valuationDate: PropTypes.object,
  setValuationDate: PropTypes.func,
  setWhenIsExitLikelyToHappen: PropTypes.func,
  setWhatTypeOfExit: PropTypes.func,
  setIndustryVertical: PropTypes.func,
  whenIsExitLikelyToHappen: PropTypes.string,
  whatTypeOfExit: PropTypes.string,
  industryVertical: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};
