import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as SandboxBangCircle } from '../../images/icons/sandbox-bang-circle.svg';
import variables from '../../styles/mixins.scss';
import './ValuationSlider.scss';

export default function ValuationSlider({
  valueYears = false,
  label,
  parentVal,
  parentSetter,
  stepInterval,
  min,
  max,
  defaultVal,
  toolTipContent,
  disableSlider,
  setSliderHasBeenChanged,
  setCurrentOptimizeSelection,
}) {
  const [sliderVal, setSliderVal] = useState(defaultVal);
  const [mouseUpChange, setMouseUpChange] = useState(false);
  // NOTE: mouseUpChange exists to trigger the useEffect() for updating the parent component
  // mouseUpChange's value is not meaningful
  const { firstAndLastPointOffsetSpacing } = variables;
  const finalSliderDotIndex = ((max - min) / stepInterval);

  useEffect(() => {
    if (sliderVal !== parentVal) setSliderVal(parentVal);
  }, []);

  useEffect(() => {
    if (sliderVal !== parentVal) setSliderVal(parentVal);
  }, [parentVal]);

  useEffect(() => {
    if (mouseUpChange) {
      parentSetter(sliderVal);
      setSliderHasBeenChanged(true);
      setMouseUpChange(false);
      setCurrentOptimizeSelection('');
    }
  }, [mouseUpChange]);

  function formatValueTxt(value) {
    if (valueYears) {
      return `${value} yr${value > 1 ? 's' : ''}`;
    }

    return `${value}%`;
  }

  return (
    <div className="ValuationSlider">
      <div className="valuation-slider-wrapper">
        <Tooltip
          placement="right"
          arrow
          title={toolTipContent}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#313033',
                '& .MuiTooltip-arrow': {
                  color: '#313033',
                },
              },
            },
          }}
        >
          <div className="left-side-label">
            <h6>
              {label}
              <SandboxBangCircle />
            </h6>
          </div>
        </Tooltip>
        <Slider
          disabled={disableSlider}
          className="valuation-slider-slide"
          sx={{
            [`[data-index="${finalSliderDotIndex}"]`]: {
              marginLeft: `-${firstAndLastPointOffsetSpacing}`,
            },
          }}
          aria-label="Temperature"
          defaultValue={defaultVal}
          value={sliderVal}
          getAriaValueText={formatValueTxt}
          valueLabelFormat={formatValueTxt}
          valueLabelDisplay="on"
          step={stepInterval}
          marks
          min={min}
          max={max}
          onChange={(e, value) => {
            setSliderVal(value);
          }}
          onChangeCommitted={() => setMouseUpChange(true)}
        />
      </div>
      <div className="valuation-labels-wrapper">
        <span>{formatValueTxt(min)}</span>
        <span>{formatValueTxt(max)}</span>
      </div>
    </div>
  );
}

ValuationSlider.propTypes = {
  valueYears: PropTypes.bool,
  label: PropTypes.string.isRequired,
  stepInterval: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultVal: PropTypes.number.isRequired,
  parentVal: PropTypes.number.isRequired,
  parentSetter: PropTypes.func.isRequired,
  toolTipContent: PropTypes.string.isRequired,
  disableSlider: PropTypes.bool.isRequired,
  setSliderHasBeenChanged: PropTypes.func.isRequired,
  setCurrentOptimizeSelection: PropTypes.func.isRequired,
};
