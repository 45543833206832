import { signOut } from 'aws-amplify/auth';

import useAsync from './useAsync';

export default function useSignOut() {
  const [{ loading: signingOut }, signOutRequest] = useAsync();

  function handleSignOut({ inactivity = false } = {}) {
    signOutRequest({
      promise: () => signOut(),
      onSuccess: () => {
        localStorage.clear();
        window.location.assign(`${process.env.REACT_APP_AUTH_HOSTNAME}${inactivity ? '?inactivity=true' : ''}`);
      },
    });
  }

  return [handleSignOut, signingOut];
}
