import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

import BreadCrumbNav from '../../components/BreadCrumbNav';
import BasicInfo from './BasicInfo';
import PaymentHistory from './PaymentHistory';

import { UserDataContext } from '../../contexts';

import './index.scss';

export default function AccountPage() {
  const [tabSelected, setTabSelected] = useState('basic-info');

  const { userData: { userAttributes: { firstName } } } = useContext(UserDataContext);

  const { state } = useLocation();

  useEffect(() => { if (state?.tabSelected) setTabSelected(state.tabSelected); }, []);

  const accountTabs = [{
    tabName: 'basic-info',
    tabLabel: 'Basic Info',
    tabIcon: <AccountBalanceIcon />,
  },
  {
    tabName: 'payment-history',
    tabLabel: 'Payment history',
    tabIcon: <PaymentsOutlinedIcon />,
  }];

  return (
    <main className="AccountPage">
      <BreadCrumbNav path="/" pathTitle="Home" />
      <h2>
        Welcome to your account,&nbsp;
        <span>{firstName}</span>
      </h2>
      <div className="content-wrapper">
        <div className="top-tabs-nav">
          {accountTabs.map((tab) => {
            return (
              <Button
                key={tab.tabName}
                className={`tab-button ${tabSelected === tab.tabName ? 'active-tab' : ''}`}
                onClick={() => setTabSelected(tab.tabName)}
              >
                {tab.tabIcon}
                {tab.tabLabel}
              </Button>
            );
          })}
        </div>
        {tabSelected === 'basic-info' && <BasicInfo />}
        {tabSelected === 'payment-history' && <PaymentHistory />}
      </div>
    </main>
  );
}
