import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import { AuthContext, UserDataContext, UserStateContext } from '.';

import amplifyConfig from '../utils/amplify';

import useAsync from '../hooks/useAsync';
import useFetch from '../hooks/useFetch';
import useSignOut from '../hooks/useSignOut';

import { authenticationCheck, parseUserAttributes } from '../utils/auth';

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { userData, setUserData } = useContext(UserDataContext);
  const { setUserState } = useContext(UserStateContext);

  const [, fetchHomepageDataRequest] = useFetch();

  const [signOut] = useSignOut();

  const [, authCheckRequest] = useAsync();
  const [, authSessionRequest] = useAsync();
  const [, fetchAttributesRequest] = useAsync();

  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN }));

  function initSession() {
    amplifyConfig();
    authCheckRequest({
      promise: () => getCurrentUser(),
      onSuccess: () => {
        authSessionRequest({
          promise: () => fetchAuthSession(),
          onSuccess: (authResponse) => {
            if (authenticationCheck(authResponse)) {
              fetchAttributesRequest({
                promise: () => fetchUserAttributes(),
                onSuccess: (attributes) => {
                  const userAttributes = parseUserAttributes(attributes);

                  fetchHomepageDataRequest({
                    url: '/homepage/client',
                    urlIds: [userAttributes.companyId],
                    onSuccess: (homepageData) => {
                      const { userState } = homepageData;
                      console.log("homepageData", homepageData);
                      setUserData({
                        ...userData,
                        ...homepageData,
                        userState,
                        userAttributes,
                        ...homepageData.userTransactions && {
                          mostRecentValuationDate:
                            homepageData.userTransactions[0].valuationDate ||
                            homepageData.userTransactions[0].transactionDate,
                        },
                      });

                      setUserState(userState);

                      setIsAuthenticated(true);
                    },
                  });
                },
              });
            } else signOut();
          },
        });
      },
      onError: () => signOut(),
    });
  }

  useEffect(() => { if (!isAuthenticated) initSession(); }, []);

  return <AuthContext.Provider value={isAuthenticated}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = { children: PropTypes.element.isRequired };
