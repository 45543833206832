import { createContext } from 'react';

const AuthContext = createContext();
const UserDataContext = createContext();
const UserStateContext = createContext();
const NavigationContext = createContext();
const ErrorMessageContext = createContext();
const NavWidthContext = createContext();
const AppDataContext = createContext();

export {
  AuthContext,
  UserDataContext,
  UserStateContext,
  NavigationContext,
  ErrorMessageContext,
  NavWidthContext,
  AppDataContext,
};
