import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

// Inside Project
import { ReactComponent as SuccessCheckmark } from '../../images/success_checkmark.svg';
import rocketShip from '../../images/RocketShipGraphic.svg';

import {
  UserDataContext,
  UserStateContext,
  ErrorMessageContext,
  NavigationContext,
} from '../../contexts';

import { copy } from '../../utils';

import Wiz1 from './Wiz1';
import Wiz2 from './Wiz2';
import Wiz3 from './Wiz3';
import Wiz4 from './Wiz4';
import ReviewAndCheckout from './ReviewAndCheckout';

import { SAFE, convertibleNote, noFunding } from '../../utils/globals';

import useFetch from '../../hooks/useFetch';
import useSignOut from '../../hooks/useSignOut';

import './index.scss';

export default function InfoForm409A() {
  const valuationFormRef = useRef(null);

  const { userData, setUserData } = useContext(UserDataContext);
  const { userState, setUserState } = useContext(UserStateContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const { to, from } = useContext(NavigationContext);

  const [activeWizard, setActiveWizard] = useState(0);
  const [inWiz, setInWiz] = useState(false);
  const [hasVisitedReviewAndCheckoutWiz4, setHasVisitedReviewAndCheckoutWiz4] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [checkForLastCompletedStep, setCheckForLastCompletedStep] = useState(false);
  const [EBITDAChecked, setEBITDAChecked] = useState(false);
  const [transactionDate, setTransactionDate] = useState(null);
  const [dbValuationDate, setDbValuationDate] = useState(null);

  // Wiz 1
  const [valuationDate, setValuationDate] = useState(moment());
  const [whenIsExitLikelyToHappen, setWhenIsExitLikelyToHappen] = useState('');
  const [whatTypeOfExit, setWhatTypeOfExit] = useState('');
  const [industryVertical, setIndustryVertical] = useState(null);
  // Wiz 2
  const [wiz2Data, setWiz2Data] = useState({
    ntmOptionsPerc: 50,
    totalCurrentCash: '',
    currentMonthlyCashBurn: '',
    totalNonConvertibleDebt: '',
    totalConvertibleDebt: '',
    totalCurrentFiscalYearRevenue: '',
    totalNextFiscalYearRevenue: '',
    totalCurrentFiscalYearEBITDA: '',
    totalNextFiscalYearEBITDA: '',
  });

  // Wiz 3
  // >>> Step #1
  const [chosenFunding, setChosenFunding] = useState('');
  // >>> Step #2
  const [currentFundingDate, setCurrentFundingDate] = useState(moment());
  const [previousFundingRoundDate, setPreviousFundingRoundDate] = useState(moment());
  const [totalAmountRaised, setTotalAmountRaised] = useState('');
  const [totalSafeOrConvertible, setTotalSafeOrConvertible] = useState('');
  // -- spacer --
  const [interestRate, setInterestRate] = useState('');
  const [valuationCap, setValuationCap] = useState('');
  const [hasRaisedPreviousRound, setHasRaisedPreviousRound] = useState(null);
  const [preOrPostMoneyNotes, setPreOrPostMoneyNotes] = useState(null);
  // >>> Step #3
  const [isThisCompanysFirstRound, setIsThisCompanysFirstRound] = useState(null);
  const [previousFundingRoundType, setPreviousFundingRoundType] = useState('');
  // >>> Step #4
  const [receivedFutureTerms, setReceivedFutureTerms] = useState(null);
  // >>> Step #5
  const [
    anySecondaryCommonStockTransactions,
    setAnySecondaryCommonStockTransactions,
  ] = useState(null);
  const [
    secondaryCommonStockTransactionTotalAmount,
    setSecondaryCommonStockTransactionTotalAmount,
  ] = useState('');
  const [
    percentageSecondaryCommonStockSold,
    setPercentageSecondaryCommonStockSold,
  ] = useState('');
  // Wiz 4
  const [capTableProvider, setCapTableProvider] = useState('');
  const [capTableFilesUploaded, setCapTableFilesUploaded] = useState([]);
  const [optionLedgerFilesUploaded, setOptionLedgerFilesUploaded] = useState([]);
  const [financialFilesUploaded, setFinancialFilesUploaded] = useState([]);
  const [
    articlesOfIncorporationUploaded,
    setArticlesOfIncorporationUploaded] = useState([]);
  const [
    secondaryTransactionDocs,
    setSecondaryTransactionDocs] = useState([]);
  const [termsSheets, setTermsSheets] = useState([]);
  const [safeOrConvertibleNoteAgreement, setSafeOrConvertibleNoteAgreement] = useState([]);

  // ReviewAndCheckout
  const [showErrors, setShowErrors] = useState(false);
  const makePaymentButtonRef = useRef(null);
  const [paymentFormIsLoading, setPaymentFormIsLoading] = useState(false);
  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
  const [paymentIsCompleted, setPaymentIsCompleted] = useState(false);
  const [discountPaymentData, setDiscountPaymentData] = useState({});
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const [transactionId, setTransactionId] = useState('');

  const [show409AReview, setShow409AReview] = useState(false);

  const [isNthTransaction, setIsNthTransaction] = useState(false);

  // Wiz controller
  const [stepsVisited, setStepsVisited] = useState([]);
  const [wizSteps, setWizSteps] = useState({
    currentStep: 1,
    totalSteps: 3,
  });

  const nav = useNavigate();
  const location = useLocation();
  const [urlParams] = useSearchParams();

  const [signOut] = useSignOut();

  const [, saveTransactionRequest] = useFetch();
  const [{ loading: fetchingTransactionData }, getAllTransactionsRequest] = useFetch();

  const paramCurrentStep = urlParams.get('currentStep');
  const paramTotalSteps = urlParams.get('totalSteps');
  const paramActiveWizard = urlParams.get('activeWizard');

  const isReviewOnly = urlParams.get('review') === 'true';
  const isNewTransaction = urlParams.get('newTransaction') === 'true';
  const previousTransactionId = urlParams.get('previousTransaction');

  // Checking if 409A is already completed
  const completed409AStates = ['409a-completed', 'sandbox-ready', 'sandbox-finalized', 'report-ready'];
  const completed409A = !isNewTransaction && (!!previousTransactionId || completed409AStates.includes(userState));

  // Handles browser navigation
  function handleNav(event) {
    if (!event) return;
    if (!event.search && !completed409A) {
      setInWiz(false);
      setCheckForLastCompletedStep(true);
      return;
    }
    if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
      setWizSteps({
        currentStep: parseInt(paramCurrentStep, 10),
        totalSteps: parseInt(paramTotalSteps, 10),
      });
      setActiveWizard(parseInt(paramActiveWizard, 10));
      setInWiz(true);
    }
  }

  useEffect(() => { handleNav(to); }, [to]);
  useEffect(() => { handleNav(from); }, [from]);

  const safeOrConvertibleForCurrentOrPreviousRounds = (
    (previousFundingRoundType === SAFE) ||
    (previousFundingRoundType === convertibleNote) ||
    (chosenFunding === SAFE) ||
    (chosenFunding === convertibleNote)
  );
  const steps = [
    {
      label: 'Industry & expected exit',
      totalSteps: () => 4,
      isComplete: () => {
        return (
          valuationDate &&
          whenIsExitLikelyToHappen?.length > 0 &&
          whatTypeOfExit?.length > 0 &&
          (
            (typeof industryVertical === 'object') &&
            (industryVertical !== null)
          )
        );
      },
    },
    {
      label: 'Financials & option pool',
      totalSteps: () => 3,
      isComplete: () => {
        const {
          totalCurrentCash,
          currentMonthlyCashBurn,
          totalNonConvertibleDebt,
          totalConvertibleDebt,
          totalCurrentFiscalYearRevenue,
          totalNextFiscalYearRevenue,
          totalCurrentFiscalYearEBITDA,
          totalNextFiscalYearEBITDA,
        } = wiz2Data;

        let returnEBITDAComplete;
        if (EBITDAChecked) {
          returnEBITDAComplete = (
            (totalCurrentCash?.trim().length > 0) &&
            (currentMonthlyCashBurn?.trim().length > 0) &&
            (totalNonConvertibleDebt?.trim().length > 0) &&
            (totalConvertibleDebt?.trim().length > 0) &&
            (totalCurrentFiscalYearRevenue ? totalCurrentFiscalYearRevenue.trim().length > 0 : false) &&
            (totalNextFiscalYearRevenue ? totalNextFiscalYearRevenue.trim().length > 0 : false) &&
            (totalCurrentFiscalYearEBITDA ? totalCurrentFiscalYearEBITDA.trim().length > 0 : false) &&
            (totalNextFiscalYearEBITDA ? totalNextFiscalYearEBITDA.trim().length > 0 : false)
          );
        } else {
          returnEBITDAComplete = (
            (totalCurrentCash?.trim().length > 0) &&
            (currentMonthlyCashBurn?.trim().length > 0) &&
            (totalNonConvertibleDebt?.trim().length > 0) &&
            (totalConvertibleDebt?.trim().length > 0)
          );
        }

        return returnEBITDAComplete;
      },
    },
    {
      label: 'Funding & secondary transactions',
      totalSteps: () => 5,
      isComplete: () => {
        // ************************************************
        // >>> Step 1
        if (totalAmountRaised?.trim().length === 0) {
          return false;
        }

        // ************************************************
        // >>> Step 2
        if (chosenFunding?.trim().length === 0) {
          return false;
        }

        // ************************************************
        // >>> Step 3
        if ((chosenFunding === SAFE) || (chosenFunding === convertibleNote)) {
          if (
            (!currentFundingDate?._isAMomentObject) ||
            (totalSafeOrConvertible?.trim().length === 0) ||
            (interestRate?.trim().length === 0) ||
            (valuationCap?.trim().length === 0) ||
            (preOrPostMoneyNotes === null)
          ) {
            return false;
          }
        }

        if (
          (chosenFunding?.trim().length > 0) &&
          (chosenFunding !== noFunding)
        ) {
          if (!currentFundingDate._isAMomentObject) {
            return false;
          }
        }

        // ************************************************
        // >>> Step 4
        if (receivedFutureTerms === null) {
          return false;
        }

        // ************************************************
        // >>> Step 5
        if (anySecondaryCommonStockTransactions === null) {
          return false;
        }

        if (anySecondaryCommonStockTransactions) {
          if (
            (secondaryCommonStockTransactionTotalAmount?.trim().length === 0) ||
            (percentageSecondaryCommonStockSold?.trim().length === 0)
          ) {
            return false;
          }
        }

        return true;
      },
    },
    {
      label: 'Capitalization table & upload',
      totalSteps: () => {
        let defaultSteps = 5;

        if (anySecondaryCommonStockTransactions) {
          defaultSteps += 1;
        }

        if (safeOrConvertibleForCurrentOrPreviousRounds) {
          defaultSteps += 1;
        }

        if (receivedFutureTerms) {
          defaultSteps += 1;
        }

        return defaultSteps;
      },
      isComplete: () => {
        if (capTableProvider?.length === 0) {
          return false;
        }

        if (capTableFilesUploaded?.length === 0) {
          return false;
        }

        if (financialFilesUploaded?.length === 0) {
          return false;
        }

        if (articlesOfIncorporationUploaded?.length === 0) {
          return false;
        }

        if (anySecondaryCommonStockTransactions && (
          secondaryTransactionDocs?.length === 0
        )) {
          return false;
        }

        if (safeOrConvertibleForCurrentOrPreviousRounds && (
          safeOrConvertibleNoteAgreement?.length === 0
        )) {
          return false;
        }

        if (receivedFutureTerms && (
          termsSheets?.length === 0
        )) {
          return false;
        }

        return true;
      },
    },
    {
      label: `Review${isNthTransaction ? ' & payment' : ''}`,
      totalSteps: () => isNthTransaction ? 2 : 1, // TODO set back to 2 to show payments page
      isComplete: () => paymentIsCompleted,
    },
  ];

  function determineIcon(isWizardComplete, index) {
    const isCurrentWizComplete = isWizardComplete();

    let status = 'not-started';
    if (isCurrentWizComplete) status = 'completed';
    else if (activeWizard === index) status = 'is-next';
    else if (stepsVisited.includes(index)) status = 'has-started';

    return {
      icon: isCurrentWizComplete && <DoneIcon />,
      status,
    };
  }

  useEffect(() => {
    if (isReviewOnly || completed409A) {
      setShow409AReview(true);
      setActiveWizard(4);
      setInWiz(true);
    }

    // Fetching Data from DB
    function getTransactionData() {
      getAllTransactionsRequest({
        url: '/transactions/get-all-transactions',
        urlIds: ['companyId'],
        onSuccess: (response) => {
          const DBData = response.Body;
          let transactionData;
          if (DBData) {
            if (previousTransactionId) {
              transactionData = DBData.find((data) => data.transactionId === previousTransactionId);
            } else {
              setIsNthTransaction(DBData?.length > 1);
              if (isNewTransaction || userData.userTransactions.length !== DBData?.length) {
                const currentTransactionsCopy = copy(userData.userTransactions);
                currentTransactionsCopy.unshift({
                  ...DBData[0],
                  transactionStatus: '409a-in-progress',
                });
                setUserData({ ...userData, userTransactions: currentTransactionsCopy });
                setUserState('409a-in-progress');
              }
              [transactionData] = DBData;
            }
          }
          function isTrueOrFalseOrNull(value) {
            if (value === 1) return true;
            if (value === 0) return false;
            return null;
          }
          if (transactionData) {
            setTransactionId(transactionData.transactionId);
            const alreadyStarted = Object.keys(transactionData).some((DataKey) => transactionData[DataKey] !== null);
            setHasStarted(alreadyStarted);
            setTransactionDate(transactionData.transactionDate ? moment(transactionData.transactionDate, 'YYYY-MM-DD') : null);
            // Wiz 1
            setValuationDate(transactionData.valuationDate ? moment(transactionData.valuationDate, 'YYYY-MM-DD') : moment());
            setDbValuationDate(transactionData.valuationDate ? moment(transactionData.valuationDate, 'YYYY-MM-DD') : null);
            setWhenIsExitLikelyToHappen(transactionData.whenIsExitLikelyToHappen);
            setWhatTypeOfExit(transactionData.whatTypeOfExit);
            setIndustryVertical(transactionData.industryVertical ? {
              ...industryVertical,
              title: transactionData.industryVertical,
              category: transactionData.verticalCategory,
              subcategory: transactionData.verticalSubcategory,
            } : null);
            // Wiz 2
            // if pulled from DB to resume transaction and values exist in EBITDA section then check EBITDA checkbox
            setEBITDAChecked(isTrueOrFalseOrNull(transactionData.EBITDAChecked));
            setWiz2Data({
              ...wiz2Data,
              ntmOptionsPerc: transactionData.ntmOptionsPerc ? parseInt(transactionData.ntmOptionsPerc, 10) : 50,
              totalCurrentCash: transactionData.totalCurrentCash,
              currentMonthlyCashBurn: transactionData.currentMonthlyCashBurn,
              totalNonConvertibleDebt: transactionData.totalNonConvertibleDebt,
              totalConvertibleDebt: transactionData.totalConvertibleDebt,
              totalCurrentFiscalYearRevenue: transactionData.totalCurrentFiscalYearRevenue,
              totalNextFiscalYearRevenue: transactionData.totalNextFiscalYearRevenue,
              totalCurrentFiscalYearEBITDA: transactionData.totalCurrentFiscalYearEBITDA,
              totalNextFiscalYearEBITDA: transactionData.totalNextFiscalYearEBITDA,
            });
            // Wiz 3
            // >>> Step #1
            setChosenFunding(transactionData.chosenFunding);
            // >>> Step #2
            setCurrentFundingDate(transactionData.currentFundingDate ? moment(transactionData.currentFundingDate) : moment());
            setTotalSafeOrConvertible(transactionData.totalSafeOrConvertible);
            setPreviousFundingRoundDate(transactionData.previousFundingRoundDate ? moment(transactionData.previousFundingRoundDate) : moment());
            setTotalAmountRaised(transactionData.totalAmountRaised);
            // -- spacer --
            setInterestRate(transactionData.interestRate);
            setValuationCap(transactionData.valuationCap);
            setHasRaisedPreviousRound(isTrueOrFalseOrNull(transactionData.hasRaisedPreviousRound));
            setPreOrPostMoneyNotes(transactionData.preOrPostMoneyNotes);
            // >>> Step #3
            setIsThisCompanysFirstRound(isTrueOrFalseOrNull(transactionData.isThisCompanysFirstRound));
            setPreviousFundingRoundType(transactionData.previousFundingRoundType);
            // >>> Step #4
            setReceivedFutureTerms(isTrueOrFalseOrNull(transactionData.receivedFutureTerms));
            // >>> Step #5
            setAnySecondaryCommonStockTransactions(
              isTrueOrFalseOrNull(transactionData.anySecondaryCommonStockTransactions),
            );
            setSecondaryCommonStockTransactionTotalAmount(
              transactionData.secondaryCommonStockTransactionTotalAmount,
            );
            setPercentageSecondaryCommonStockSold(transactionData.percentageSecondaryCommonStockSold);
            // Wiz 4
            setCapTableProvider(transactionData.capTableProvider);
            setCapTableFilesUploaded(transactionData.documents?.cap_table_files || []);
            setOptionLedgerFilesUploaded(transactionData.documents?.option_ledger_files || []);
            setFinancialFilesUploaded(transactionData.documents?.financial_files || []);
            setArticlesOfIncorporationUploaded(transactionData.documents?.articles_of_incorporation || []);
            setSecondaryTransactionDocs(transactionData.documents?.secondary_transaction || []);
            setTermsSheets(transactionData.documents?.term_sheets || []);
            setSafeOrConvertibleNoteAgreement(transactionData.documents?.safe_or_convertible_note_agreement || []);
          }
          if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
            setWizSteps({
              currentStep: parseInt(paramCurrentStep, 10),
              totalSteps: parseInt(paramTotalSteps, 10),
            });
            setActiveWizard(parseInt(paramActiveWizard, 10));
            setInWiz(true);
          } else {
            setCheckForLastCompletedStep(true);
          }
        },
      });
    }

    getTransactionData();
  }, []);

  useEffect(() => {
    if (checkForLastCompletedStep && !show409AReview) {
      setActiveWizard(steps.findIndex(({ isComplete }) => !isComplete()));
    }
    setCheckForLastCompletedStep(false);
  }, [checkForLastCompletedStep]);


  useEffect(() => {
    if (inWiz && !show409AReview) {
      const { currentStep, totalSteps } = wizSteps;
      const newUrl = `?currentStep=${currentStep}&totalSteps=${totalSteps}&activeWizard=${activeWizard}`;
      if (location.search !== newUrl) nav(newUrl);
    }
  }, [wizSteps, inWiz, activeWizard]);

  function openNewWizard(
    activeWizIndex,
    numTotalSteps,
    startOnThisStep = 1,
    isInTheWiz = true,
  ) {
    setInWiz(isInTheWiz);
    setActiveWizard(activeWizIndex);
    wizSteps.totalSteps = numTotalSteps;
    wizSteps.currentStep = startOnThisStep;
    setWizSteps(copy(wizSteps));
  }

  function isInReviewAndCheckoutFirstStepFinalWizard() {
    return (
      (activeWizard === (steps.length - 1)) &&
      (wizSteps.currentStep === 1) // currentStep uses normal human indexing, starting @ 1
    );
  }

  function isInReviewAndCheckoutSecondStepFinalWizard() {
    return (
      (activeWizard === (steps.length - 1)) &&
      (wizSteps.currentStep === 2) // currentStep uses normal human indexing, starting @ 1
    );
  }

  function all4DataWizardsComplete() {
    return steps.every((step, i) => {
      if (i === steps.length - 1) {
        return true;
      }
      return step.isComplete();
    });
  }

  function updateTransactionData({ isComplete = false } = {}) {
    function isOneOrZeroOrNull(value) {
      if (value === true) return 1;
      if (value === false) return 0;
      return null;
    }
    try {
      const transactionData = {
        companyId: userData.userAttributes.companyId,
        companyName: userData.userAttributes.companyName,
        insertUserId: userData.userAttributes.userId,
        progress: isComplete ? 'completed' : 'In Progress',
        valuationDate: valuationDate ? valuationDate.format('YYYY-MM-DD') : null,
        transactionDate: isComplete ? moment().format('YYYY-MM-DD') : null,
        industryVertical: industryVertical ? industryVertical.title : '',
        verticalSubcategory: industryVertical ? industryVertical.subcategory : '',
        verticalCategory: industryVertical ? industryVertical.category : '',
        currentMonthlyCashBurn: wiz2Data.currentMonthlyCashBurn,
        totalConvertibleDebt: wiz2Data.totalConvertibleDebt,
        totalCurrentCash: wiz2Data.totalCurrentCash,
        totalNonConvertibleDebt: wiz2Data.totalNonConvertibleDebt,
        EBITDAChecked: EBITDAChecked ? 1 : 0,
        totalCurrentFiscalYearRevenue: wiz2Data.totalCurrentFiscalYearRevenue,
        totalNextFiscalYearRevenue: wiz2Data.totalNextFiscalYearRevenue,
        totalCurrentFiscalYearEBITDA: wiz2Data.totalCurrentFiscalYearEBITDA,
        totalNextFiscalYearEBITDA: wiz2Data.totalNextFiscalYearEBITDA,
        documents: {
          articles_of_incorporation: articlesOfIncorporationUploaded,
          cap_table_files: capTableFilesUploaded,
          option_ledger_files: optionLedgerFilesUploaded,
          financial_files: financialFilesUploaded,
          safe_or_convertible_note_agreement: safeOrConvertibleNoteAgreement,
          secondary_transaction: secondaryTransactionDocs,
          term_sheets: termsSheets,
        },
        whenIsExitLikelyToHappen,
        whatTypeOfExit,
        previousFundingRoundDate: previousFundingRoundDate?.format('YYYY-MM-DD'),
        currentFundingDate: currentFundingDate?.format('YYYY-MM-DD'),
        ntmOptionsPerc: wiz2Data.ntmOptionsPerc,
        capTableProvider,
        secondaryCommonStockTransactionTotalAmount,
        chosenFunding,
        totalAmountRaised,
        totalSafeOrConvertible,
        interestRate,
        valuationCap,
        hasRaisedPreviousRound: isOneOrZeroOrNull(hasRaisedPreviousRound),
        preOrPostMoneyNotes,
        isThisCompanysFirstRound: isOneOrZeroOrNull(isThisCompanysFirstRound),
        previousFundingRoundType,
        receivedFutureTerms: isOneOrZeroOrNull(receivedFutureTerms),
        anySecondaryCommonStockTransactions:
          isOneOrZeroOrNull(anySecondaryCommonStockTransactions),
        percentageSecondaryCommonStockSold,
        transactionId,
      };
      saveTransactionRequest({
        url: '/transactions/update-transaction',
        urlIds: ['companyId'],
        body: transactionData,
        onSuccess: () => { if (valuationDate) setDbValuationDate(valuationDate.format('YYYY-MM-DD')); },
        onFinally: () => { if (!isComplete) setUserState('409a-in-progress'); },
      });
      if (valuationDate) setDbValuationDate(valuationDate.format('YYYY-MM-DD'));
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      if (!isComplete) setUserState('409a-in-progress');
    }
  }

  function submissionInfoSucceeded() {
    setUserState('409a-completed');
    if (!isNthTransaction) updateTransactionData({ isComplete: true });
    setShowSuccessPage(true);
    setInWiz(false);
    wizSteps.currentStep = 1;
    setWizSteps(copy(wizSteps));
    nav('/409a-info');
  }

  useEffect(() => { if (paymentIsCompleted) submissionInfoSucceeded(); }, [paymentIsCompleted]);


  function home() {
    return (
      <>
        {fetchingTransactionData && (
          <div className="loading-wrapper">
            <div className="dots-circle-spinner" />
          </div>
        )}
        {!steps[0].isComplete() ?
          <h1>Start a new 409A valuation</h1> :
          <h1>Continue your 409A valuation</h1>}
        <img src={rocketShip} className="rocketShip" alt="RocketShipGraphic" />
        {!steps[0].isComplete() ? (
          <p>
            It&apos;s fast! It typically takes less than half an hour to provide us
            with all the information and documentation we need.
            If you stop in the middle, don&apos;t worry, your progress will be saved as you go.
            Once our work begins you&apos;ll receive your 409A in days not weeks.
          </p>
        ) : (
          <p>
            If you stop in the middle, don&apos;t worry,
            your progress will be saved as you go.
            Once our work begins you&apos;ll receive your 409A in days not weeks.
          </p>
        )}
        <Stepper
          activeStep={activeWizard}
          orientation="vertical"
        >
          {
            steps.map(({ label, totalSteps, isComplete }, i) => {
              const { icon, status } = determineIcon(isComplete, i);

              return (
                <Step
                  key={label}
                  disabled={false}
                  className="individual-step"
                  onClick={() => {
                    if (!hasStarted) setHasStarted(true);
                    openNewWizard(i, totalSteps());
                  }}
                >
                  <StepLabel
                    className={status}
                    StepIconComponent={() => icon || i + 1}
                  >
                    {label}
                    {/completed/ig.test(status) && <CreateOutlinedIcon />}
                  </StepLabel>
                </Step>
              );
            })
          }
        </Stepper>
        <Button
          className="start-btn"
          sx={{ borderRadius: 8 }}
          onClick={() => {
            if (!hasStarted) setHasStarted(true);
            const { totalSteps } = steps[activeWizard];
            openNewWizard(activeWizard, totalSteps());
          }}
        >
          {
            hasStarted ? 'Next' : 'Start'
          }
        </Button>
      </>
    );
  }
  function wiz() {
    if (activeWizard === 0) {
      return (
        <Wiz1
          currentStep={wizSteps.currentStep}
          valuationDate={valuationDate}
          setValuationDate={setValuationDate}
          setWhenIsExitLikelyToHappen={setWhenIsExitLikelyToHappen}
          setWhatTypeOfExit={setWhatTypeOfExit}
          industryVertical={industryVertical}
          setIndustryVertical={setIndustryVertical}
          whenIsExitLikelyToHappen={whenIsExitLikelyToHappen}
          whatTypeOfExit={whatTypeOfExit}
        />
      );
    }
    if (activeWizard === 1) {
      return (
        <Wiz2
          currentStep={wizSteps.currentStep}
          wiz2Data={wiz2Data}
          setWiz2Data={setWiz2Data}
          setEBITDAChecked={setEBITDAChecked}
          EBITDAChecked={EBITDAChecked}
        />
      );
    }
    if (activeWizard === 2) {
      return (
        <Wiz3
          currentStep={wizSteps.currentStep}
          // Step #1
          setChosenFunding={setChosenFunding}
          chosenFunding={chosenFunding}
          // Step #2
          currentFundingDate={currentFundingDate}
          setCurrentFundingDate={setCurrentFundingDate}
          // ------------ spacer ------------
          previousFundingRoundDate={previousFundingRoundDate}
          setPreviousFundingRoundDate={setPreviousFundingRoundDate}
          // ------------ spacer ------------
          totalAmountRaised={totalAmountRaised}
          setTotalAmountRaised={setTotalAmountRaised}
          // ------------ spacer ------------
          totalSafeOrConvertible={totalSafeOrConvertible}
          setTotalSafeOrConvertible={setTotalSafeOrConvertible}
          // ------------ spacer ------------
          interestRate={interestRate}
          setInterestRate={setInterestRate}
          // ------------ spacer ------------
          valuationCap={valuationCap}
          setValuationCap={setValuationCap}
          // ------------ spacer ------------
          hasRaisedPreviousRound={hasRaisedPreviousRound}
          setHasRaisedPreviousRound={setHasRaisedPreviousRound}
          // ------------ spacer ------------
          preOrPostMoneyNotes={preOrPostMoneyNotes}
          setPreOrPostMoneyNotes={setPreOrPostMoneyNotes}
          // Step #3
          isThisCompanysFirstRound={isThisCompanysFirstRound}
          setIsThisCompanysFirstRound={setIsThisCompanysFirstRound}
          // ------------ spacer ------------
          previousFundingRoundType={previousFundingRoundType}
          setPreviousFundingRoundType={setPreviousFundingRoundType}
          // Step #4
          receivedFutureTerms={receivedFutureTerms}
          setReceivedFutureTerms={setReceivedFutureTerms}
          // Step #5
          anySecondaryCommonStockTransactions={anySecondaryCommonStockTransactions}
          setAnySecondaryCommonStockTransactions={setAnySecondaryCommonStockTransactions}
          // ------------ spacer ------------
          secondaryCommonStockTransactionTotalAmount={
            secondaryCommonStockTransactionTotalAmount
          }
          setSecondaryCommonStockTransactionTotalAmount={
            setSecondaryCommonStockTransactionTotalAmount
          }
          // ------------ spacer ------------
          percentageSecondaryCommonStockSold={percentageSecondaryCommonStockSold}
          setPercentageSecondaryCommonStockSold={setPercentageSecondaryCommonStockSold}
        />
      );
    }
    if (activeWizard === 3) {
      return (
        <Wiz4
          currentStep={wizSteps.currentStep}
          capTableProvider={capTableProvider}
          setCapTableProvider={setCapTableProvider}
          capTableFilesUploaded={capTableFilesUploaded}
          setCapTableFilesUploaded={setCapTableFilesUploaded}
          optionLedgerFilesUploaded={optionLedgerFilesUploaded}
          setOptionLedgerFilesUploaded={setOptionLedgerFilesUploaded}
          financialFilesUploaded={financialFilesUploaded}
          setFinancialFilesUploaded={setFinancialFilesUploaded}
          articlesOfIncorporationUploaded={articlesOfIncorporationUploaded}
          setArticlesOfIncorporationUploaded={setArticlesOfIncorporationUploaded}
          secondaryTransactionDocs={secondaryTransactionDocs}
          setSecondaryTransactionDocs={setSecondaryTransactionDocs}
          termsSheets={termsSheets}
          setTermsSheets={setTermsSheets}
          safeOrConvertibleNoteAgreement={safeOrConvertibleNoteAgreement}
          setSafeOrConvertibleNoteAgreement={setSafeOrConvertibleNoteAgreement}
          anySecondaryCommonStockTransactions={anySecondaryCommonStockTransactions}
          safeOrConvertibleNote={!!safeOrConvertibleForCurrentOrPreviousRounds}
          receivedFutureTerms={receivedFutureTerms}
          transactionId={transactionId}
          updateTransactionData={() => updateTransactionData()}
        />
      );
    }
    if (activeWizard === 4) {
      if (!hasVisitedReviewAndCheckoutWiz4 && !show409AReview) {
        setHasVisitedReviewAndCheckoutWiz4(true);
      }

      return (
        <ReviewAndCheckout
          wizSteps={wizSteps}
          setWizSteps={setWizSteps}
          isNthTransaction={isNthTransaction}
          transactionId={transactionId}
          submissionInfoSucceeded={submissionInfoSucceeded}
          openNewWizard={openNewWizard}
          steps={steps}
          currentStep={wizSteps.currentStep}
          areAll4DataWizardsComplete={all4DataWizardsComplete()}
          // Wiz1
          valuationDate={dbValuationDate ? valuationDate.format('MM/DD/YYYY') : transactionDate?.format('MM/DD/YYYY')}
          whenIsExitLikelyToHappen={whenIsExitLikelyToHappen}
          whatTypeOfExit={whatTypeOfExit}
          industryVertical={industryVertical}
          // Wiz2
          wiz2Data={wiz2Data}
          EBITDAChecked={EBITDAChecked}
          // Wiz3
          chosenFunding={chosenFunding}
          totalAmountRaised={totalAmountRaised}
          currentFundingDate={currentFundingDate.format('MM/DD/YYYY')}
          totalSafeOrConvertible={totalSafeOrConvertible}
          interestRate={interestRate}
          valuationCap={valuationCap}
          preOrPostMoneyNotes={preOrPostMoneyNotes}
          receivedFutureTerms={receivedFutureTerms}
          anySecondaryCommonStockTransactions={anySecondaryCommonStockTransactions}
          percentageSecondaryCommonStockSold={percentageSecondaryCommonStockSold}
          secondaryCommonStockTransactionTotalAmount={secondaryCommonStockTransactionTotalAmount}
          // Wiz4
          capTableProvider={capTableProvider}
          capTableFilesUploaded={capTableFilesUploaded}
          optionLedgerFilesUploaded={optionLedgerFilesUploaded}
          financialsFilesUploaded={financialFilesUploaded}
          articlesOfIncorporationUploaded={articlesOfIncorporationUploaded}
          secondaryTransactionDocs={secondaryTransactionDocs}
          termsSheets={termsSheets}
          safeOrConvertibleNoteAgreement={safeOrConvertibleNoteAgreement}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          // Read-only review
          show409AReview={show409AReview}
          showDataLoadingOverlay={fetchingTransactionData}
          // Payment
          makePaymentButtonRef={makePaymentButtonRef}
          setPaymentFormIsLoading={setPaymentFormIsLoading}
          setPaymentIsProcessing={setPaymentIsProcessing}
          setPaymentIsCompleted={setPaymentIsCompleted}
          discountPaymentData={discountPaymentData}
          setDiscountPaymentData={setDiscountPaymentData}
        />
      );
    }

    return (
      <div>
        <h1>Test, which should never render</h1>
      </div>
    );
  }

  if (showSuccessPage) {
    return (
      <div className="InfoForm409A success-container">
        <h1>Success!</h1>
        <SuccessCheckmark />
        <div className="success-msg-container">
          <p>
            {`You've successfully submitted (and paid for) a 409A valuation form
            on behalf of ${userData.userAttributes.companyName}. The submitted information will soon be reviewed by
            one of our expert analysts. We'll email you within 7 business days when your
            409A sandbox is ready. The sandbox is where you can, in
            real-time, customize your 409A before you lock in your final valuation.`}
          </p>
        </div>
        <Button
          className="payment-success-link"
          onClick={() => window.location.assign(process.env.REACT_APP_INITIO_HOSTNAME)}
        >
          Go to homepage
        </Button>
        <Button
          className="payment-success-link"
          onClick={signOut}
        >
          Sign out
        </Button>
      </div>
    );
  }

  return (
    <div className="InfoForm409A">
      <div className="bread-crumb-wrapper">
        <div className="bread-crumb">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={() => {
              if (inWiz && !show409AReview) {
                setInWiz(false);
                nav('/409a-info');
                setCheckForLastCompletedStep(true);
              } else {
                nav('/');
              }
            }}
          >
            <ArrowBackIcon className="back-icon" />
            <span className="bread-crumb-title">
              {inWiz && !show409AReview ? '409A' : 'Home'}
            </span>
          </button>
        </div>
        <span className="step-top-label">
          {inWiz && !show409AReview ? steps[activeWizard].label : !show409AReview && 'New 409A valuation'}
        </span>
      </div>

      <div className="valuation-form-container" ref={valuationFormRef}>
        {inWiz && !show409AReview && (
          <div className="linear-progress">
            <span className="current-step">{`${wizSteps.currentStep} of ${wizSteps.totalSteps}`}</span>
            <LinearProgress variant="determinate" value={(wizSteps.currentStep / wizSteps.totalSteps) * 100} />
          </div>
        )}
        <div className="valuation-form" style={!inWiz ? { padding: '40px 140px 40px 140px' } : {}}>
          {inWiz ? wiz() : home()}
        </div>
        {
          inWiz && (
            <div className="bottom-btn-container">
              {
                (
                  hasVisitedReviewAndCheckoutWiz4 &&
                  (activeWizard !== 4)
                ) && (
                  <Button
                    variant="text"
                    className="back-to-review"
                    onClick={() => {
                      updateTransactionData();
                      openNewWizard(4, steps[4].totalSteps(), 1);
                    }}
                  >
                    Back to review page
                  </Button>
                )
              }
              {!show409AReview && (
                <Button
                  variant="outlined"
                  className="back-btn"
                  onClick={() => {
                    updateTransactionData();
                    if (wizSteps.currentStep === 1) {
                      setInWiz(false);
                      setCheckForLastCompletedStep(true);
                      nav('/409a-info');
                    } else {
                      wizSteps.currentStep -= 1;
                      setWizSteps(copy(wizSteps));
                    }
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                className="next-btn"
                disabled={paymentFormIsLoading || paymentIsProcessing}
                onClick={() => {
                  if (show409AReview) {
                    nav('/');
                    return;
                  }
                  if (!isInReviewAndCheckoutFirstStepFinalWizard() && !isInReviewAndCheckoutSecondStepFinalWizard()) {
                    updateTransactionData();
                  }
                  if (
                    isInReviewAndCheckoutFirstStepFinalWizard() &&
                    !all4DataWizardsComplete()
                  ) {
                    setShowErrors(true);
                    return;
                  }

                  if (isNthTransaction && isInReviewAndCheckoutSecondStepFinalWizard()) {
                    makePaymentButtonRef.current.click();
                    return;
                  }

                  if (!isNthTransaction && isInReviewAndCheckoutFirstStepFinalWizard()) {
                    submissionInfoSucceeded();
                    return;
                  }

                  if (wizSteps.currentStep === wizSteps.totalSteps) {
                    let nextActiveWiz = activeWizard + 1;
                    if (!steps[nextActiveWiz]) nextActiveWiz = steps.length - 1;
                    const newStepsVisitedArray = copy(stepsVisited);
                    newStepsVisitedArray.push(activeWizard);
                    setStepsVisited(newStepsVisitedArray);
                    setActiveWizard(nextActiveWiz);
                    setInWiz(false);
                    wizSteps.currentStep = 1;
                    setWizSteps(copy(wizSteps));
                    nav('/409a-info');
                  } else {
                    wizSteps.currentStep += 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                {show409AReview ? 'Back' :
                  isInReviewAndCheckoutFirstStepFinalWizard() ?
                    'Submit information' :
                    paymentIsProcessing ? (
                      <>
                        <span className="dots-circle-spinner" />
                        Processing Payment...
                      </>
                    ) : isInReviewAndCheckoutSecondStepFinalWizard() ?
                      (discountPaymentData.chargeAmount === '0' ? 'Proceed without payment' : 'Make payment') :
                      'Next'}
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
}
