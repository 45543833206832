import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { debounce } from '@mui/material/utils';

import parseHighlights from '../utils/parsePlacesHighlights';

import './AddressAutocomplete.scss';
import { AppDataContext } from '../../../contexts';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id,onError) {
  if (!position) return;

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  script.onerror = onError;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

export default function AddressAutocomplete({
  targetInput,
  setTargetInput,
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const [fetchingOptions, setFetchingOptions] = useState(false);

  const [open, setOpen] = useState(false);

  const loaded = useRef(false);
  const {isapiKeyWorking, setIsapiKeyWorking} =  useContext(AppDataContext)
  window.gm_authFailure = () => {
    setIsapiKeyWorking(false)
    console.error('Invalid API key or authentication failure for Google Maps API.');
  };
  const handleScriptError = () => {
    console.error('Error loading Google Maps API. Check your API key.');
  };

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
        handleScriptError
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(() => debounce((request, callback) => {
    autocompleteService.current.getPlacePredictions(request, callback);
  }, 400), []);

  useEffect(() => {
    setFetchingOptions(false);
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }


    if (!autocompleteService.current) return undefined;

    if (inputValue === '') {
      setOptions(targetInput.companyStreetAddress ? [targetInput.companyStreetAddress] : []);
      setTargetInput({ ...targetInput, companyStreetAddress: '', city: '', state: '', zipCode: '', country: '' });
      return undefined;
    }

    setFetchingOptions(true);
    fetch({ input: inputValue, types: ['street_address', 'route'] }, (results) => {
      if (active) {
        let newOptions = [];
        if (targetInput.companyStreetAddress && targetInput.companyStreetAddress.place_id) {
          placesService.current.getDetails({ placeId: targetInput.companyStreetAddress.place_id, fields: ['address_components'] }, (details) => {
            const addressComponents = details.address_components;
            setTargetInput({
              ...targetInput,
              companyStreetAddress: `${addressComponents.find((component) => component.types.includes('street_number'))?.long_name || ''} ` +
                `${addressComponents.find((component) => component.types.includes('route'))?.long_name || ''}`,
              city: addressComponents.find((component) => component.types.includes('locality'))?.short_name || '',
              state: addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.short_name || '',
              zipCode: addressComponents.find((component) => component.types.includes('postal_code'))?.short_name || '',
              country: addressComponents.find((component) => component.types.includes('country'))?.long_name || '',
            });
          });
        }
        if (results) newOptions = [...newOptions, ...results];
        setFetchingOptions(false);
        setOptions(newOptions);
      }
    });

    return () => { active = false; };
  }, [targetInput.companyStreetAddress, inputValue, fetch]);

  return (
    <Autocomplete
      freeSolo
      disablePortal
      open={open && options.length > 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className="company-address formSection4"
      name="companyStreetAddress"
      style={{ margin: 0 }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.structured_formatting.main_text}
      options={options}
      loading={fetchingOptions}
      loadingText=""
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={targetInput.companyStreetAddress || ''}
      inputValue={inputValue}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setTargetInput({ ...targetInput, companyStreetAddress: newValue });
      }}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      componentsProps={{ popper: { style: { width: 'fit-content', minWidth: '320px' }, placement: 'bottom-start' } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company street address"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetchingOptions ? <span className="dots-circle-spinner" /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        if (typeof option === 'string') return <li>{option}</li>;
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parseHighlights(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li
            {...props}
            key={`${option.structured_formatting.main_text}-${option.structured_formatting.secondary_text}`}
          >
            {parts.map((part) => (
              <span
                key={part.text}
                className={`primary-text ${part.highlight ? 'highlighted' : ''}`}
              >
                {part.text.replaceAll(' ', '\u00a0')}
              </span>
            ))}
            <span className="secondary-text">
              {option.structured_formatting.secondary_text.length > 60 ?
                `${option.structured_formatting.secondary_text.slice(0, 60)}...` :
                option.structured_formatting.secondary_text}
            </span>
          </li>
        );
      }}
    />
  );
}

AddressAutocomplete.propTypes = {
  targetInput: PropTypes.object.isRequired,
  setTargetInput: PropTypes.func.isRequired,
};
