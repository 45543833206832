import React, { useContext } from 'react';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';

import { UserDataContext } from '../../../../contexts';

import './index.scss';

export default function SubscriptionBanner() {
  const { userData } = useContext(UserDataContext);

  const { subscriptionData } = userData;

  const nav = useNavigate();

  return (
    <div className="SubscriptionBanner">
      <div className={`banner ${subscriptionData.isActive ? 'secondary-background' : 'primary-background'}`}>
        <div className="banner-text-and-icon">
          <EventRepeatOutlinedIcon />
          {subscriptionData.isActive ? (
            <div className="banner-text">
              <p>{`Subscription will automatically renew on ${moment(subscriptionData.endDateTime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')}`}</p>
              <p>Current cost of subscription renewal: free</p>
            </div>
          ) : (
            <div className="banner-text">
              <p>Resubscribe now</p>
              <p>You&apos;ve cancelled your subscription. Resubscribe for free! Only pay when you start a new 409A valuation.</p>
            </div>
          )}
        </div>
        <Button onClick={() => nav('/subscription-info')}>Manage subscription</Button>
      </div>
    </div>
  );
}
