import React from 'react';
import PropTypes from 'prop-types';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './ToolTip.scss';

export default function ToolTip({
  content,
  customContainerStyles,
  displayHeader = true,
  title = 'Tip',
  icon = 'TipsAndUpdatesOutlinedIcon',
}) {
  let displayIcon;

  if (/TipsAndUpdatesOutlinedIcon/ig.test(icon)) {
    displayIcon = <TipsAndUpdatesOutlinedIcon />;
  } else if (/WarningAmberIcon/ig.test(icon)) {
    displayIcon = <WarningAmberIcon />;
  } else { // should not get hit
    displayIcon = <TipsAndUpdatesOutlinedIcon />;
  }

  return (
    <div className="ToolTip" style={customContainerStyles}>
      {
        displayHeader && (
          <div>
            {displayIcon}
            <h5>{title}</h5>
          </div>
        )
      }
      <div className="body-content">
        {
          (typeof content === 'string') ? <p>{content}</p> : content
        }
      </div>
    </div>
  );
}

ToolTip.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  customContainerStyles: PropTypes.object,
  displayHeader: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
};
