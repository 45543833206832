import { useContext, useEffect } from 'react';

import LogRocket from 'logrocket';

import { AuthContext, UserDataContext } from '../contexts';

export default function useLogRocket() {
  const { userData } = useContext(UserDataContext);
  const { isAuthenticated } = useContext(AuthContext);

  const logRocketEnabled = process.env.REACT_APP_USE_LOG_ROCKET === 'true';

  useEffect(() => {
    if (!logRocketEnabled || !isAuthenticated) return;

    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID, {
      rootHostname: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
      mergeIframes: true,
    });

    LogRocket.identify(userData.userAttributes.userId, {
      name: `${userData.userAttributes.firstName} ${userData.userAttributes.lastName}`,
      email: userData.userAttributes.email,
      companyName: userData.userAttributes.companyName,
      companyId: userData.userAttributes.companyId,
    });
  }, [isAuthenticated]);
}
