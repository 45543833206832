const legalFirmInfo = [
  {
    name: 'Wilson Sonsini Goodrich & Rosati',
    value: 'wilsonSonsini',
    domain: 'wsgr.com',
    type: 'Legal',
  },
  {
    name: 'Fenwick & West',
    value: 'fenwickWest',
    domain: 'fenwick.com',
    type: 'Legal',
  },
  {
    name: 'Sidley Austin',
    value: 'sidleyAustin',
    domain: 'sidley.com',
    type: 'Legal',
  },
  {
    name: 'Gunderson Dettmer',
    value: 'gundersonDettmer',
    domain: 'gunder.com',
    type: 'Legal',
  },
  {
    name: 'Seward & Kissel',
    value: 'sewardKissel',
    domain: 'sewkis.com',
    type: 'Legal',
  },
  {
    name: 'Karr Tuttle Campbell',
    value: 'karrTuttleCampbell',
    domain: 'karrtuttle.com',
    type: 'Legal',
  },
];

const accountingFirmInfo = [
  {
    name: 'FLG Partners',
    value: 'flgPartners',
    domain: 'flgpartners.com',
    type: 'Financial',
  },
  {
    name: 'Kruze Consulting',
    value: 'kruzeConsulting',
    domain: 'kruzeconsulting.com',
    type: 'Financial',
  },
  {
    name: 'Standish Management',
    value: 'standishManagement',
    domain: 'standishmanagement.com',
    type: 'Financial',
  },
  {
    name: 'First Republic Bank',
    value: 'firstRepublicBank',
    domain: 'firstrepublic.com',
    type: 'Banking',
  },
  {
    name: 'Silicon Valley Bank',
    value: 'siliconValleyBank',
    domain: 'svb.com',
    type: 'Banking',
  },
  {
    name: 'Aduro Advisors',
    value: 'aduroAdvisors',
    domain: 'aduroadvisors.com',
    type: 'Financial',
  },
];

const firmInfo = legalFirmInfo.concat(accountingFirmInfo);

const dropdownAnimationTimeMS = 500;
const dropdownAnimationTimeS = 0.5;

// Financing info

const SAFE = 'SAFE Note';

const convertibleNote = 'Convertible Note';

const noFunding = 'No funding';

const fundingType = [
  SAFE,
  convertibleNote,
  'Angel',
  'Pre-Seed',
  'Series Seed',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
  'Series F',
];


export {
  legalFirmInfo,
  accountingFirmInfo,
  firmInfo,
  dropdownAnimationTimeMS,
  dropdownAnimationTimeS,
  SAFE,
  convertibleNote,
  noFunding,
  fundingType,
};
