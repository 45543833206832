import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import RequestReceived from './RequestReceived';

import useFetch from '../../hooks/useFetch';

import { userInfoDefaultValues, userInfoFormContent } from '../ClientIntakeForm/utils/formFieldsContents';

import './Demo.scss';

export default function Demo() {
  const nav = useNavigate();

  const [submissionReceived, setSubmissionReceived] = useState(false);
  const [contactPreference, setContactPreference] = useState([]);
  const [userInfo, setUserInfo] = useState(userInfoFormContent);
  const [submitType, setSubmitType] = useState('');
  const [userInput, setUserInput] = useState(userInfoDefaultValues);
  const [inputHasError, setInputHasError] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [, demoRequest] = useFetch();
  const [, sendEmailRequest] = useFetch();

  // function to set state with any state setter sent to it, when dealing with setting an new object
  function setNewState(targetedState, stateSetter, inputName, newValue) {
    const newState = { ...targetedState };
    newState[inputName] = newValue;
    return stateSetter(newState);
  }

  // function to check the specified format of an individual input if there is one
  function onChange(inputName, inputValue, fieldIndex, formatter) {
    const newInputValue = formatter ? formatter(inputValue) : inputValue;
    return setNewState(userInput, setUserInput, inputName, newInputValue, fieldIndex);
  }

  function clearError(inputName, fieldIndex) {
    const newErrorValue = false;
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  function pathToName(currentInput, currentField, fieldIndex) {
    if (Array.isArray(currentInput.userFormField)) {
      return currentInput.userFormField[fieldIndex][currentField.name];
    }
    return currentInput[currentField.name];
  }

  function checkForError(inputName, inputValue, fieldIndex, checkFormat) {
    const newErrorValue = !checkFormat(inputValue);
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  useEffect(() => {
    const moddedUserInfoFormContent = userInfoFormContent.map((inputField) => {
      inputHasError[inputField.name] = false;
      return {
        ...inputField,
        required: true,
        ... !inputField.formatChecker && { formatChecker: (val) => !!val },
      };
    });
    setUserInfo(moddedUserInfoFormContent);
  }, []);

  useEffect(() => {
    const errors = Object.values(inputHasError);
    const errorCheck = errors.filter((value) => value === true);
    if (!errorCheck.length) setDisableSubmitButton(false);
  }, [inputHasError]);

  function checkForErrorOnSubmit(info, inputFromUser) {
    const newState = { ...inputHasError };
    info.forEach((input) => {
      const newErrorValue = !input.formatChecker(inputFromUser[input.name]);
      newState[input.name] = newErrorValue;
    });
    setInputHasError(newState);
    if (Object.values(newState).filter((value) => value === true).length) {
      setDisableSubmitButton(true);
      return false;
    }
    setDisableSubmitButton(false);
    return true;
  }

  function handleSubmit() {
    demoRequest({
      url: '/support/demo-request',
      body: { ...userInput, contactPreference },
      bodyIds: ['accountId', 'companyId'],
      onSuccess: (demoResponse) => {
        sendEmailRequest({
          url: '/emails/send-html-email',
          method: 'post',
          body: {
            recipient: userInput.email,
            templateName: 'demoRequestSuccess',
            templateData: [userInput.firstName],
            metadata: demoResponse.Body,
          },
          onSuccess: () => {
            setSubmissionReceived(true);
            setSubmitType('demo');
          },
        });
      },
    });
  }

  if (submissionReceived) return <RequestReceived submitType={submitType} />;

  return (
    <div className="demo-page-container">
      <div className="bread-crumb">
        <button
          type="button"
          className="bread-crumb-btn"
          onClick={() => nav('/')}
        >
          <ArrowBackIcon className="back-icon" />
          <span className="bread-crumb-title">
            Home
          </span>
        </button>
      </div>
      <div>
        <div className="header">
          <p>Request a demo</p>
        </div>
        <div className="main">
          <form
            className="message-container"
            onSubmit={(e) => {
              e.preventDefault();
              if (checkForErrorOnSubmit(userInfo, userInput)) handleSubmit();
              else setDisableSubmitButton(true);
            }}
          >
            <FormControl className="message-form">
              <p>
                Please fill this form out so we can contact you to discuss your valuation needs and schedule a demo.
              </p>
              <Grid container spacing={2} className="input-grid">
                {userInfo.map((lab, index) => (
                  <Grid key={lab.name} item md="auto" className="inner-input-grid">
                    <TextField
                      className="user-inputs"
                      label={lab.label + (lab.isOptional ? ' (optional)' : '')}
                      name={lab.name}
                      type={lab.type}
                      value={pathToName(userInput, lab, index)}
                      onChange={(e) => {
                        checkForError(
                          e.target.name,
                          e.target.value,
                          index,
                          lab.formatChecker,
                        );
                        onChange(e.target.name, e.target.value, index, lab.formatter);
                      }}
                      onFocus={(e) => clearError(e.target.name, index)}
                      onBlur={(e) => {
                        checkForError(
                          e.target.name,
                          e.target.value,
                          index,
                          lab.formatChecker,
                        );
                        onChange(e.target.name, e.target.value.trim(), index);
                      }}
                      error={
                        pathToName(inputHasError, lab, index)
                      }
                    />
                    <FormHelperText style={{
                      visibility: pathToName(inputHasError, lab, index) ? 'visible' : 'hidden',
                      marginBottom: 0,
                    }}
                    >
                      <ErrorIcon />
                      {lab.helperText || 'input required'}
                    </FormHelperText>
                  </Grid>
                ))}
              </Grid>
              <p className="last-paragraph">
                How would you prefer we contact you? Please select from the options below, feel free to select more than one.
              </p>
              <div className="toggle-btns">
                <ToggleButtonGroup
                  value={contactPreference}
                  onChange={(_, newMethod) => setContactPreference(newMethod)}
                >
                  <ToggleButton value="email" className={contactPreference.includes('email') ? 'toggle-btn-active' : 'toggle-btn'}>
                    {contactPreference.includes('email') ? (
                      <>
                        Email Me
                        <CheckCircleIcon />
                      </>
                    ) : 'Email Me'}
                  </ToggleButton>
                  <ToggleButton value="call" className={contactPreference.includes('call') ? 'toggle-btn-active' : 'toggle-btn'}>
                    {contactPreference.includes('call') ? (
                      <>
                        Call Me
                        <CheckCircleIcon />
                      </>
                    ) : 'Call Me'}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </FormControl>
            <Button
              type="submit"
              className="send-request"
              disabled={disableSubmitButton}
            >
              <SendOutlinedIcon />
              Send
            </Button>
          </form>
          <Box className="info">
            <div className="info-container">
              <ForumOutlinedIcon className="contact-icon" />
              <h2>Other ways to connect</h2>
              <p>
                Contact us at:
                <br />
                (415) 472-9175
                <br />
                <br />
                We&apos;re available:
                <br />
                Monday to Friday
                <br />
                9am to 5pm PST
                <br />
                <br />
                If we can&apos;t get back to you
                right away, we&apos;ll get back
                <br />
                to you within one
                <br />
                business day.
              </p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}
