import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AuditRiskSlider2.scss';

export default function AuditRiskSlider2({
  farLeftPrice = '',
  midLeftPrice = '',
  midRightPrice = '',
  farRightPrice = '',
  modeVal = '',
  numOfDefensibleOutcomes = '',
  defensibleOutcomesPrice = '',
  auditRiskLabel = 'low',
}) {
  const [priceVerticalOffset, setPriceVerticalOffset] = useState(0);

  useEffect(() => {
    function initializeCalcPriceVerticalOffset() {
      function difference(a, b) {
        return Math.abs(a - b);
      }

      function calcAndSetOffsetForPriceVertical(
        theCurrentPrice,
        lowerBound,
        upperBound,
        pxStart,
        pxEnd,
      ) {
        const centsInRange = Math.round(
          difference((lowerBound * 100), (upperBound * 100)),
        );

        const pxInRange = difference(pxStart, pxEnd);
        const pxPerCent = pxInRange / centsInRange;

        const centsInFromLowestBound = Math.round(
          (theCurrentPrice * 100) - (lowerBound * 100),
        );

        const pxFromLowestBound = centsInFromLowestBound * pxPerCent;

        const offset = pxStart + pxFromLowestBound;

        setPriceVerticalOffset(offset);
      }

      const formatPriceToNum = (num) => parseFloat(num.replaceAll('$', ''));
      const farLeftPriceNum = formatPriceToNum(farLeftPrice);
      const midLeftPriceNum = formatPriceToNum(midLeftPrice);
      const centerPriceNum = formatPriceToNum(modeVal);
      const midRightPriceNum = formatPriceToNum(midRightPrice);
      const farRightPriceNum = formatPriceToNum(farRightPrice);
      const currentPriceNum = formatPriceToNum(defensibleOutcomesPrice);

      if (currentPriceNum <= farLeftPriceNum) { // start at
        // -340 to -299 dur 41
        setPriceVerticalOffset(-298);
      } else if (
        (currentPriceNum > farLeftPriceNum) &&
        (currentPriceNum <= midLeftPriceNum)
      ) {
        const startPxOffsetMidLeft = -298;
        const endPxOffsetMidLeft = -199;
        calcAndSetOffsetForPriceVertical(
          currentPriceNum,
          farLeftPriceNum,
          midLeftPriceNum,
          startPxOffsetMidLeft,
          endPxOffsetMidLeft,
        );
      } else if (
        (currentPriceNum > midLeftPriceNum) &&
        (currentPriceNum <= centerPriceNum)
      ) {
        const startPxOffsetMidLeft = -198;
        const endPxOffsetMidLeft = -68;
        calcAndSetOffsetForPriceVertical(
          currentPriceNum,
          midLeftPriceNum,
          centerPriceNum,
          startPxOffsetMidLeft,
          endPxOffsetMidLeft,
        );
      } else if (
        (currentPriceNum > centerPriceNum) &&
        (currentPriceNum <= midRightPriceNum)
      ) {
        const startPxOffsetMidLeft = -67;
        const endPxOffsetMidLeft = 50;
        calcAndSetOffsetForPriceVertical(
          currentPriceNum,
          centerPriceNum,
          midRightPriceNum,
          startPxOffsetMidLeft,
          endPxOffsetMidLeft,
        );
      } else if (
        (currentPriceNum > midRightPriceNum) &&
        (currentPriceNum <= farRightPriceNum)
      ) {
        const startPxOffsetMidLeft = 51;
        const endPxOffsetMidLeft = 150;
        calcAndSetOffsetForPriceVertical(
          currentPriceNum,
          midRightPriceNum,
          farRightPriceNum,
          startPxOffsetMidLeft,
          endPxOffsetMidLeft,
        );
      } else { // currentPriceNum > farRightPriceNum
        // 151 to 200 dur 49
        setPriceVerticalOffset(150);
      }
    }

    initializeCalcPriceVerticalOffset();
  }, [
    farLeftPrice,
    midLeftPrice,
    midRightPrice,
    farRightPrice,
    defensibleOutcomesPrice,
    numOfDefensibleOutcomes,
  ]);

  const modeOffsetPx = 0;
  const modeOffsets = {
    modeLabel: `-${187 + modeOffsetPx}px`,
    modePrice: `-${150 + modeOffsetPx}px`,
    modeVertical: `${273 + modeOffsetPx}px`,
  };

  function defensibleOutcomesOffsets() {
    const outcomesOffsets = {
      priceVertical: `${200 + -priceVerticalOffset}px`,
    };

    function calcOffset(num) {
      return (priceVerticalOffset <= -num) ?
        `${Math.abs(num + priceVerticalOffset)}px` :
        `-${num + priceVerticalOffset}px`;
    }

    outcomesOffsets.defensibleOutcomesWrapper = calcOffset(300);
    outcomesOffsets.defensiblePriceWrapper = calcOffset(310);

    return outcomesOffsets;
  }

  return (
    <div className="AuditRiskSlider2">
      <span className="share-price-label">
        Share price
      </span>
      <span
        className="mode-label"
        style={{ right: modeOffsets.modeLabel }}
      >
        Median
      </span>
      <span
        className="equidistantly-spaced-prices"
        style={{ right: modeOffsets.modePrice }}
      >
        {modeVal}
      </span>
      {
        [
          { right: '-330px', price: farRightPrice },
          { right: '-195px', price: midRightPrice },
          { right: '90px', price: midLeftPrice },
          { right: '222px', price: farLeftPrice },
        ].map(({ right, price }) => {
          return (
            <span
              className="equidistantly-spaced-prices"
              style={{ right }}
              key={price + right}
            >
              {price}
            </span>
          );
        })
      }
      <span
        className={`defensible-outcomes-price-wrapper ${auditRiskLabel}-risk`}
        style={{ right: defensibleOutcomesOffsets().defensiblePriceWrapper }}
      >
        <span className="defensible-outcomes-price">
          {defensibleOutcomesPrice === 'N/A' ? 'N/A' : `$${defensibleOutcomesPrice}`}
        </span>
      </span>

      <span
        className="defensible-outcomes"
        style={{ right: defensibleOutcomesOffsets().defensibleOutcomesWrapper }}
      >
        {
          [
            defensibleOutcomesPrice === 'N/A' ? 'N/A' : numOfDefensibleOutcomes,
            'Defensible',
            'outcomes',
          ].map((title) => {
            return (
              <span key={title} className="defensible-outcomes-text">
                {title}
              </span>
            );
          })
        }
      </span>
      <div className="slider">
        <span className="dotted-vertical" style={{ left: '50px' }} />
        <span className="dotted-vertical" style={{ left: '150px' }} />

        <span className="mode-vertical" style={{ right: modeOffsets.modeVertical }} />

        <span
          className="price-vertical"
          style={{ left: defensibleOutcomesOffsets().priceVertical }}
        />

        <span className="dotted-vertical" style={{ left: '400px' }} />
        <span className="dotted-vertical" style={{ left: '500px' }} />
      </div>
    </div>
  );
}


AuditRiskSlider2.propTypes = {
  farLeftPrice: PropTypes.string,
  midLeftPrice: PropTypes.string,
  midRightPrice: PropTypes.string,
  farRightPrice: PropTypes.string,
  modeVal: PropTypes.string,
  numOfDefensibleOutcomes: PropTypes.string,
  defensibleOutcomesPrice: PropTypes.string,
  auditRiskLabel: PropTypes.string,
};
