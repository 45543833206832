import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import './BreadCrumbNav.scss';

export default function BreadCrumbNav({ pageTitle, path, pathTitle }) {
  const nav = useNavigate();

  return (
    <div className="BreadCrumbNav">
      <Button onClick={() => nav(path)}>
        <ArrowBackIcon />
        {pathTitle}
      </Button>
      <h2>{pageTitle}</h2>
    </div>
  );
}

BreadCrumbNav.propTypes = {
  pageTitle: PropTypes.string,
  pathTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
};

BreadCrumbNav.defaultProps = {
  pageTitle: '',
  pathTitle: 'Home',
};
