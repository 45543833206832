import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

export default function ArrowTooltip() {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}>
      <div className="extra-info-link">
        What&apos;s included?
        <InfoIcon />
      </div>
    </Tooltip>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: '#00497D',
      color: '#FFFFFF',
      width: 320,
      fontSize: theme.typography.pxToRem(12),
      padding: '16px',
      borderRadius: 8,
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        backgroundColor: '#00497D',
      },
    },
  }));

  // Tooltip styles
  const title = {
    fontFamily: 'Roboto',
    textDecoration: 'underline',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
  };
  const strongBullet = {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
  };
  const lightBullet = {
    fontFamily: 'Roboto',
    fontWeight: 400,
    listStyleType: 'disc',
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
  };

  return (
    <StyledTooltip
      title={(
        <div className="tooltipContent">
          <p style={title}>Payment includes:</p>
          <ul>
            <li style={strongBullet}>
              Services for your entire company
              <ul style={lightBullet}>
                <li>Including free additional users</li>
              </ul>
            </li>
            <li style={strongBullet}>1 free 409A valuation per year</li>
            <li style={strongBullet}>
              Our proprietary 409A valuation sandbox that comes with:
              <ul style={lightBullet}>
                <li>
                  Real-time sliders that help you understand what
                  factors impact your company&apos;s share price and audit risk.
                </li>
                <li>
                  Complete flexibility. We process your valuation fast,
                  you finalize when you&apos;re ready.
                </li>
              </ul>
            </li>
            <li style={strongBullet}>
              And much more coming soon:
              <ul style={lightBullet}>
                <li>Scenario modeling</li>
                <li>Market data</li>
                <li>Marketplace insights</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
      placement="right"
      arrow
    />
  );
}
