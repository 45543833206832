import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';

import HowCanIHelpDrawing from '../../images/how-can-i-help.svg';

import Early409AModal from '../Modals/Early409AModal';
import New409AReSubModal from '../../containers/Subscription/New409AReSubModal';
import Tr409AInProgressModal from '../Modals/Tr409AInProgressModal';

import useFetch from '../../hooks/useFetch';

import { UserDataContext } from '../../contexts';

import './SideNavBar.scss';

export default function SideNavBar() {
  const [navBarIsOpen, setNavBarIsOpen] = useState(false);
  const [currentBodyHeight, setCurrentBodyHeight] = useState(0);

  const [showEarly409AModal, setShowEarly409AModal] = useState(false);
  const [showNew409AReSubModal, setShowNew409AReSubModal] = useState(false);
  const [showTr409AInProgressModal, setShowTr409AInProgressModal] = useState(false);

  const { userData } = useContext(UserDataContext);

  const [{ loading: startingNew409A }, startNew409ARequest] = useFetch();

  const location = useLocation();

  const nav = useNavigate();

  function startNew409A() {
    if (userData.userState === '409a-not-started') {
      nav('/409a-info');
      setNavBarIsOpen(false);
      return;
    }
    if (userData.userState !== 'report-ready') {
      setShowTr409AInProgressModal(true);
      return;
    }
    if (moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').isAfter(moment().subtract(1, 'years'))) {
      setShowEarly409AModal(true);
      return;
    }
    if (!userData.subscriptionData.isActive) {
      setShowNew409AReSubModal(true);
      return;
    }
    startNew409ARequest({
      url: '/transactions/new-transaction',
      method: 'post',
      urlIds: ['companyId'],
      onSuccess: () => {
        nav('/409a-info?newTransaction=true');
        setNavBarIsOpen(false);
      },
    });
  }

  useEffect(() => { if (navBarIsOpen) setCurrentBodyHeight(document.documentElement.scrollHeight - 56); }, [navBarIsOpen, location]);

  return (
    <div className="SideNavBar">
      {navBarIsOpen && (
        <div
          className="overlay"
          aria-label="overlay"
          role="button"
          tabIndex="0"
          onKeyDown={() => setNavBarIsOpen(false)}
          onClick={() => setNavBarIsOpen(false)}
          style={{ minHeight: `${currentBodyHeight}px` }}
        />
      )}
      <Button
        className={`nav-toggle ${navBarIsOpen ? 'is-open' : ''}`}
        onClick={() => setNavBarIsOpen(!navBarIsOpen)}
      >
        {navBarIsOpen ? <MenuOpenIcon /> : <MenuIcon />}
      </Button>
      <div
        className={`nav-bar ${navBarIsOpen ? 'open' : 'closed'}`}
        style={{ minHeight: `${currentBodyHeight}px` }}
      >
        <Button
          className={`nav-btn ${window.location.pathname === '/' ? 'selected' : ''}`}
          onClick={() => nav('/')}
        >
          <HomeOutlinedIcon />
          Home
        </Button>
        <hr />
        <span>My initio</span>
        <Button
          className={`nav-btn ${window.location.pathname === '/account-info' ? 'selected' : ''}`}
          onClick={() => nav('/account-info')}
        >
          <PersonOutlinedIcon />
          Account
        </Button>
        <Button
          className={`nav-btn ${window.location.pathname === '/subscription-info' ? 'selected' : ''}`}
          onClick={() => nav('/subscription-info')}
        >
          <SettingsOutlinedIcon />
          Manage subscription
        </Button>
        <hr />
        {startingNew409A ? (
          <Button className="nav-btn" disabled>
            <span className="dots-circle-spinner" />
            Starting new 409A...
          </Button>
        ) : (
          <Button
            className={`nav-btn ${window.location.pathname === '/409a-info' ? 'selected' : ''}`}
            onClick={startNew409A}
          >
            <AddchartIcon />
            Start new 409A
          </Button>
        )}
        <hr />
        <div className="how-can-i-help">
          <img src={HowCanIHelpDrawing} alt="how can I help?" />
          CLIENT SUPPORT
          <br />
          info@initio.software
          {/* 555.555.5555 */}
        </div>
        <Early409AModal showEarly409AModal={showEarly409AModal} setShowEarly409AModal={setShowEarly409AModal} />
        <New409AReSubModal showNew409AReSubModal={showNew409AReSubModal} setShowNew409AReSubModal={setShowNew409AReSubModal} />
        <Tr409AInProgressModal showTr409AInProgressModal={showTr409AInProgressModal} setShowTr409AInProgressModal={setShowTr409AInProgressModal} />
      </div>
    </div>
  );
}
