import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import ApprovalIcon from '@mui/icons-material/Approval';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ReactComponent as SuccessCheckmark } from '../../images/success_checkmark.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import RightInfoBlock from '../Sandbox/RightInfoBlock';
import SharePriceSlider from './SharePriceSlider';
import SharePriceCards from './SharePriceCards';

import useSignOut from '../../hooks/useSignOut';

import useFetch from '../../hooks/useFetch';
import useS3 from '../../hooks/useS3';

import { UserStateContext } from '../../contexts';

import './index.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const defaultSliderCardValues = [
  { color: 'responsive', title: 'Share price', data: ' ' },
  { color: 'responsive', title: 'Defensible outcomes', data: ' ' },
  { color: 'non-response', title: 'Total Iterations', data: ' ' },
  { color: 'non-response', title: 'Term', data: ' ' },
  { color: 'non-response', title: 'Volatility', data: ' ' },
  { color: 'non-response', title: 'NTM Options', data: ' ' },
  { color: 'non-response', title: 'DLOM', data: ' ' },
];

export default function PriceSandbox() {
  const { userState, setUserState } = useContext(UserStateContext);

  const [checkingSandboxStatus, setCheckingSandboxStatus] = useState(false);
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [concludedPercentOfPreferred, setConcludedPercentOfPreferred] = useState('');
  const [averageOption, setAverageOption] = useState('');
  const [averageOptionExercise, setAverageOptionExercise] = useState('');
  const [medianMarketPercent, setMedianMarketPercent] = useState(27);
  const [lastRoundIssued, setLastRoundIssued] = useState('1.00');
  const [prior409AValue, setPrior409AValue] = useState('');
  const [disablePriceSlider, setDisablePriceSlider] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(true);

  // Audit Risk
  const [centerVal, setCenterVal] = useState();
  const [sliderMarks, setSliderMarks] = useState(
    [
      { value: 0, label: null },
      { value: 50, label: null },
      { value: 100, label: null },
    ],
  );
  const [selectedPrice, setSelectedPrice] = useState(50);
  const [perSharePrices, setPerSharePrices] = useState();
  const [pricesData, setPricesData] = useState();
  const [displayedPrice, setDisplayedPrice] = useState(defaultSliderCardValues);
  const [incrementalToolTip, setIncrementalToolTip] = useState(false);
  const [showIncrementalToolTip, setShowIncrementalToolTip] = useState(false);
  const [priceStats, setPriceStats] = useState({});
  const [singlePrice, setSinglePrice] = useState(false);

  // Non-slider props
  const [auditRiskLabel, setAuditRiskLabel] = useState('low');
  const [averageIndustryVolatility, setAverageIndustryVolatility] = useState('');
  const [numDefensible, setNumDefensible] = useState('');

  // Optimize Dropdown
  const [anchorEl, setAnchorEl] = useState(null);

  const [currentOptimizeSelection, setCurrentOptimizeSelection] = useState('');

  const [userHasFinalized, setUserHasFinalized] = useState(false);
  const [sandboxIsFinalized, setSandboxIsFinalized] = useState(false);

  // No Outcome Modal
  const [noOutcome, setNoOutcome] = useState(false);

  // User Finalized Snackbar
  const [showSandboxFinalizedSnackbar, setShowSandboxFinalizedSnackbar] = useState(false);

  const [, s3JsonRequest] = useS3();
  const [, sandboxDataRequest] = useFetch();
  const [, s3UrlSandboxDataRequest] = useFetch();
  const [{ loading: sandboxIsFinalizing }, finalSelectionRequest] = useFetch();

  const nav = useNavigate();

  const [signOut] = useSignOut();

  function doSetOfAllSandboxValues(sandboxData, dynamoPriceSandbox) {
    const { auditRisk, cards, prices, stats, metaData } = sandboxData;

    setNumDefensible(stats.totalOutcomes);
    setIncrementalToolTip(metaData.incremental);
    setPriceStats(stats);

    // #1: Price Slider
    const { center } = auditRisk;
    const indexOfCenter = metaData.sortedPrices.indexOf(center);

    if (sandboxData) {
      const marks = metaData.sortedPrices.map((value, index) => {
        if (index === 0 || index === metaData.sortedPrices.length - 1) {
          return {
            value: index,
            label: `$${value}`,
          };
        }
        if (metaData.sortedPrices.length <= 20) {
          return {
            value: index,
          };
        }
        return null;
      }).filter((mark) => mark !== null);
      setPerSharePrices(metaData.sortedPrices);
      setSliderMarks(marks);
      setPricesData(prices);
      setCenterVal(center);
      if (metaData.totalIndex === 0) {
        setDisablePriceSlider(true);
        setSelectedPrice(0);
        setSinglePrice(true);
      }
      if (dynamoPriceSandbox.finalSelection) {
        setSelectedPrice(metaData.sortedPrices.indexOf(dynamoPriceSandbox.finalSelection));
        setSandboxIsFinalized(true);
        setDisablePriceSlider(true);
      } else setSelectedPrice(indexOfCenter);
      setCheckingSandboxStatus(false);
    }

    // // #3 - Cards
    setPrior409AValue(cards.prior409A);
    setLastRoundIssued(cards.latestRoundIssued);
    setMedianMarketPercent(cards.medianMarketPercOfPreferred);
    setAverageOptionExercise(cards.averageOptionExercise);
    setAverageIndustryVolatility(parseFloat(cards.averageIndustryVolatility).toFixed(0).toString());

    setShowDataLoadingOverlay(false);
  }


  useEffect(() => {
    if (perSharePrices) {
      if (perSharePrices[selectedPrice]) {
        setDisplayedPrice(
          [
            {
              color: 'responsive',
              title: 'Share price',
              data: `$${pricesData[perSharePrices[selectedPrice]].sharePrice}`,
            },
            {
              color: 'responsive',
              title: 'Defensible outcomes',
              data: `${pricesData[perSharePrices[selectedPrice]].outcomes}`,
            },
            {
              color: 'non-response',
              title: 'Total Iterations',
              data: `${numDefensible}`,
            },
            {
              color: 'non-response',
              title: 'Term',
              data: `${pricesData[perSharePrices[selectedPrice]].term} ${pricesData[perSharePrices[selectedPrice]].term > 1 ? 'yrs' : 'yr'}`,
            },
            {
              color: 'non-response',
              title: 'Volatility',
              data: `${pricesData[perSharePrices[selectedPrice]].volatility}%`,
            },
            {
              color: 'non-response',
              title: 'NTM Options',
              data: `${pricesData[perSharePrices[selectedPrice]].ntmOptionPercent}%`,
            },
            {
              color: 'non-response',
              title: 'DLOM',
              data: `${pricesData[perSharePrices[selectedPrice]].dlom}%`,
            },
          ],
        );
        setAuditRiskLabel(pricesData[perSharePrices[selectedPrice]].auditRisk);
        setAverageOption(
          pricesData[perSharePrices[selectedPrice]].averageOptionExercise ?
            pricesData[perSharePrices[selectedPrice]].averageOptionExercise :
            averageOptionExercise,
        );
        setConcludedPercentOfPreferred(pricesData[perSharePrices[selectedPrice]].concludedPercentOfPreferred);
      }
    }
  }, [selectedPrice, disablePriceSlider]);

  function getSandboxDefaults() {
    setShowDataLoadingOverlay(true);

    const urlParams = new URLSearchParams(window.location.search);
    const previousTransactionId = urlParams.get('previousTransaction');

    const finalizedSandboxStates = ['sandbox-finalized', 'report-ready'];
    setSandboxIsFinalized(finalizedSandboxStates.includes(userState));

    sandboxDataRequest({
      url: '/sandbox/price-sandbox',
      urlIds: ['companyId', previousTransactionId || 'transactionId'],
      onSuccess: (sandboxData) => {
        s3JsonRequest({
          requestType: 'get',
          key: sandboxData.s3Key,
          bucket: process.env.REACT_APP_S3_USER_DATA_BUCKET,
          onSuccess: (s3Url) => {
            s3UrlSandboxDataRequest({
              url: s3Url,
              s3Call: true,
              onSuccess: (s3SandboxData) => doSetOfAllSandboxValues(s3SandboxData, sandboxData),
            });
          },
        });
      },
    });
  }

  useEffect(() => {
    getSandboxDefaults();
    const hasVisited = localStorage.getItem('hasVisited');
    if (hasVisited) setOpenToolTip(false);
    else setOpenToolTip(true);
  }, []);

  useEffect(() => {
    if (currentOptimizeSelection === 'Company Optimize') {
      setSelectedPrice(perSharePrices ? perSharePrices.indexOf(priceStats.companyOptimizedPrice) : 50);
    } else if (currentOptimizeSelection === 'Employee Optimize') {
      setSelectedPrice(perSharePrices ? perSharePrices.indexOf(priceStats.employeeOptimizedPrice) : 50);
    }
  }, [currentOptimizeSelection]);

  function userFinalizedSelection({ signOutUser = false, navigateHome = false } = {}) {
    if (sandboxIsFinalizing || sandboxIsFinalized) {
      if (signOutUser) signOut();
      if (navigateHome) nav('/');
      return;
    }
    finalSelectionRequest({
      url: '/price-sandbox/final-selection',
      urlIds: ['companyId', 'transactionId', perSharePrices[selectedPrice]],
      onSuccess: () => {
        setUserState('sandbox-finalized');
        setSandboxIsFinalized(true);
        setDisablePriceSlider(true);
        if (signOutUser) signOut();
        if (navigateHome) nav('/');
      },
    });
  }

  if (userHasFinalized) {
    return (
      <div className="Sandbox success-container">
        <h1>Success!</h1>
        <SuccessCheckmark />
        <div className="success-msg-container">
          <p>
            You&apos;ve successfully finalized your 409A valuation.
          </p>
        </div>
        <Button
          className="success-link"
          onClick={() => userFinalizedSelection({ navigateHome: true })}
          disabled={sandboxIsFinalizing}
        >
          Go to homepage
        </Button>
        <Button
          className="success-link"
          onClick={() => userFinalizedSelection({ signOutUser: true })}
          disabled={sandboxIsFinalizing}
        >
          Sign out
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSandboxFinalizedSnackbar}
          onClose={(_, reason) => {
            if (reason === 'timeout') userFinalizedSelection();
            setShowSandboxFinalizedSnackbar(false);
          }}
          TransitionComponent={SlideTransition}
          message={(
            <>
              <span>Sandbox finalized</span>
              <Button onClick={() => {
                setShowSandboxFinalizedSnackbar(false);
                setUserHasFinalized(false);
              }}
              >
                Undo
              </Button>
              <Button
                onClick={() => {
                  userFinalizedSelection();
                  setShowSandboxFinalizedSnackbar(false);
                }}
              >
                Dismiss
              </Button>
            </>
          )}
          autoHideDuration={10000}
          ClickAwayListenerProps={{ onClickAway: () => null }}
        />
      </div>
    );
  }

  return (
    <div className="Sandbox PriceSandbox">
      <div className="back-link-block">
        <button
          type="button"
          className="back-link-container"
          onClick={() => nav('/')}
        >
          <ArrowBackIcon />
          <h4>Home</h4>
        </button>
      </div>
      <div className="valuation-container">
        <div className="headline-container">
          <h2>
            {`${sandboxIsFinalized ? 'Finalized ' : ''}`}
            409A Valuation Sandbox
          </h2>
          {!checkingSandboxStatus && (
            <>
              <Button
                variant="contained"
                className="secondary-top-btn printer-btn top-btn-instance"
                onClick={() => window.print()}
              >
                <PrintOutlinedIcon />
                Print Exhibit
              </Button>
              {!sandboxIsFinalized && (
                <>
                  <Button
                    variant="contained"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    className="secondary-top-btn optimize-btn top-btn-instance"
                  >
                    <AutoGraphIcon />
                    Optimize
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                    className="optimize-dropdown"
                  >
                    {['Company Optimize', 'Employee Optimize'].map((option, index) => (
                      <MenuItem
                        key={option}
                        className={option === currentOptimizeSelection ? 'active' : ''}
                        onClick={() => {
                          if (index === 0) setCurrentOptimizeSelection('Company Optimize');
                          else setCurrentOptimizeSelection('Employee Optimize');
                          setAnchorEl(null);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    variant="contained"
                    className="secondary-top-btn refresh-btn top-btn-instance"
                    disabled={showDataLoadingOverlay}
                    onClick={() => {
                      setSelectedPrice(selectedPrice ? `${selectedPrice}` : '');
                      getSandboxDefaults();
                    }}
                  >
                    <RefreshOutlinedIcon />
                    Reset
                  </Button>
                  {!showDataLoadingOverlay ? (
                    <Tooltip
                      open={openToolTip}
                      title={(
                        <>
                          Once you finalize you can&apos;t
                          <br />
                          make changes to the Sandbox.
                          <button
                            type="button"
                            aria-label="Close tooltip"
                            className="exit-tooltip"
                            onClick={() => {
                              setOpenToolTip(false);
                              localStorage.setItem('hasVisited', true);
                            }}
                          >
                            <CloseIcon />
                          </button>
                        </>
                      )}
                      placement="left-start"
                      PopperProps={{ className: 'finalize-arrow-tooltip' }}
                      arrow
                    >
                      <Button
                        variant="contained"
                        className="top-btn-instance finalize"
                        onClick={() => {
                          setUserHasFinalized(true);
                          setShowSandboxFinalizedSnackbar(true);
                        }}
                      >
                        <ApprovalIcon />
                        Finalize
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      className="top-btn-instance finalize"
                      disabled
                    >
                      Finalize
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="sandbox-scrolling-wrapper user-view">
          <div className="top-container">
            <div className="top-explanation">
              <p>
                Move the &apos;Share price slider&apos; to see your 409A valuation outcomes change in real-time.
                Click &apos;Optimize&apos; to optimize outcomes for your company or employees. If you decide
                you want to reset all values back to their defaults, simply click &lsquo;Reset&rsquo;.
              </p>
            </div>
            <div
              className="concluded-value"
              style={{
                backgroundColor: /low/ig.test(auditRiskLabel) ? '#42A179' : '#F4C543',
                color: /low/ig.test(auditRiskLabel) ? '#FFFFFF' : '#49454F',
              }}
            >
              <h6>Concluded value</h6>
              {!showDataLoadingOverlay && (
                <div className="price-container">
                  <div className="share-price">
                    <span>$</span>
                    {perSharePrices ? `${perSharePrices[selectedPrice]}` : ''}
                  </div>
                  <p>per share</p>
                </div>
              )}
            </div>
          </div>
          <div className="info-container">
            <div className="info-container-left-col">
              <div>
                <div
                  className="audit-risk-info-and-slider"
                  style={{
                    padding: `${incrementalToolTip ? '24px 32px 0px' : '24px 32px 32px'}`,
                  }}
                >
                  <h3>
                    Audit risk:
                    <span style={{ color: /low/ig.test(auditRiskLabel) ? '#42A179' : '#F4C543' }}>
                      {` ${auditRiskLabel}`}
                    </span>
                  </h3>
                  <div className="slider-wrapper">
                    {showDataLoadingOverlay && (
                      <div className="loading-wrapper">
                        <LoadingSpinner className="custom-loading-spinner" />
                      </div>
                    )}
                    {!showDataLoadingOverlay && (
                      <div className={`slider-mirage ${sandboxIsFinalized ? 'finalized' : ''} ${singlePrice ? 'single-price' : ''}`}>
                        <div className="slider-mirage-inner" />
                      </div>
                    )}
                    <h6 className="price-slider-header">Share price slider</h6>
                    {!showDataLoadingOverlay && (
                      <SharePriceSlider
                        sliderMarks={sliderMarks}
                        setSelectedPrice={setSelectedPrice}
                        selectedPrice={selectedPrice}
                        auditRiskLabel={auditRiskLabel}
                        currentOptimizeSelection={currentOptimizeSelection}
                        disablePriceSlider={disablePriceSlider}
                        showDataLoadingOverlay={showDataLoadingOverlay}
                        perSharePrices={perSharePrices}
                        sandboxIsFinalized={sandboxIsFinalized}
                        stats={priceStats}
                        singlePrice={singlePrice}
                      />
                    )}
                    <div className="iterations-bar">
                      {
                        displayedPrice.map(({ color, title, data }) => (
                          <SharePriceCards
                            color={color}
                            title={title}
                            data={data}
                            auditRiskLabel={auditRiskLabel}
                            priceInfo={displayedPrice}
                            key={title.replaceAll(' ', '-+-+-')}
                          />
                        ))
                      }
                    </div>
                  </div>
                  {incrementalToolTip && (
                    <div className="increment-info">
                      <p>
                        We scale the slider for ease of use.
                      </p>
                      <Tooltip
                        /* eslint-disable-next-line max-len */
                        title="The slider is incremented by 1% of your mode  share price. This allows you granular control while also making the slider itself easier to use."
                        placement="top-end"
                        arrow
                        open={showIncrementalToolTip}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              padding: '13px 24px',
                              width: '286px',
                              height: '112px',
                              background: '#313833',
                              color: '#FFFFFF',
                              fontSize: '12px',
                              letterSpacing: '0.4px',
                              lineHeight: '20px',
                              borderRadius: '8px',
                              fontWeight: '400',
                              fontFamily: 'Roboto',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#313833',
                            },
                          },
                        }}
                      >
                        <Button
                          className="incrementalBtn"
                          onClick={() => (setShowIncrementalToolTip(!showIncrementalToolTip))}
                          onMouseEnter={() => { setShowIncrementalToolTip(!showIncrementalToolTip); }}
                          onMouseLeave={() => { setShowIncrementalToolTip(false); }}
                        >
                          <InfoOutlinedIcon className="info-icon" />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="outcomes-text-block">
                  <p className="outcomes-text">
                    All outcomes generated here are your most defensible outcomes based on
                    the assumptions you provided initio. Yellow outcomes do not increase your chance
                    <br />
                    of audit. The difference between a green and yellow outcome is a yellow may
                    <br />
                    generate slightly more questions from auditors than those that fall in the green.
                  </p>
                </div>
                <div className="key-terms-block">
                  <h3 className="key-terms-title">Key terms</h3>
                  {
                    [
                      {
                        title: `$${centerVal} mode.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is your company\'s most defensible outcome. It\'s the most frequently concluded price per share of all iterations calculated.',
                      },
                      {
                        title: `${numDefensible} total outcomes.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is the total number of times initio\'s proprietary calculation engine iterated on your company\'s share price. It processed multiple methodologies, that our valuation experts reviewed and finalized.',
                      },
                    ].map(({ title, paragraph }) => {
                      return (
                        <div key={paragraph.replaceAll(' ', '+-=!')} className="key-terms-entry">
                          <p className="key-terms-explanation">
                            <span className="key-term">
                              {title}
                            </span>
                            {' '}
                            {paragraph}
                          </p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div className="info-container-right-col">
              <div className="right-col-wrapper">
                <h3>Insights</h3>
                <h4>Company data</h4>
                {
                  [
                    {
                      title: 'Concluded percent of preferred',
                      label: `${concludedPercentOfPreferred !== 'N/A' ? `${concludedPercentOfPreferred}%` : concludedPercentOfPreferred}`,
                    },
                    {
                      title: 'Average option exercise price',
                      label: `${(averageOption === 'N/A' || !averageOption) ? 'N/A' : `$${Number(averageOption).toFixed(2)}`}`,
                    },
                    {
                      title: 'Prior 409A value',
                      label: (prior409AValue.length ? `$${prior409AValue}` : 'N/A'),
                    },
                    {
                      title: 'Latest round issue price',
                      label: Number(lastRoundIssued) > 0 ? `$${lastRoundIssued}` : 'N/A',
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      showDataLoadingOverlay={showDataLoadingOverlay}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
                <hr />
                <h4>Market data</h4>
                {
                  [
                    {
                      title: 'Median Market % of preferred',
                      label: `${medianMarketPercent}%`,
                    },
                    {
                      title: 'Average industry volatility',
                      label: `${averageIndustryVolatility}%`,
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      showDataLoadingOverlay={showDataLoadingOverlay}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={noOutcome}
        className="no-outcome-dialog"
        disableScrollLock
      >
        <div className="box-header">
          <ErrorOutlineIcon />
          <h4>No outcome available</h4>
        </div>
        <p>
          This selected combination of inputs is either indefensible or not within the set of calculated values.
          <br />
          <br />
          Please select a different combination to find an available price.
        </p>
        <Button
          variant="contained"
          className="got-it-btn"
          onClick={() => {
            setNoOutcome(false);
          }}
        >
          Got it
        </Button>
      </Dialog>
    </div>
  );
}
