import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './containers/Home';
import InfoForm409A from './containers/409aInfo/index';
import Support from './containers/Support';
import Demo from './containers/Support/Demo';
import NotFound from './components/NotFound';
import Sandbox from './containers/Sandbox/index';
import PriceSandbox from './containers/PriceSandbox';
import SubscriptionPage from './containers/Subscription';
import AccountPage from './containers/Account';
import ValReport from './containers/Home/ValReport';

export default function RouterProvider() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/409a-info"
        element={<InfoForm409A />}
      />
      <Route
        path="/409a-sandbox"
        element={<Sandbox />}
      />
      <Route
        path="/409a-price-sandbox"
        element={<PriceSandbox />}
      />
      <Route
        path="/409a-report"
        element={<ValReport />}
      />
      <Route
        path="/account-info"
        element={<AccountPage />}
      />
      <Route
        path="/subscription-info"
        element={<SubscriptionPage />}
      />
      <Route
        path="/support"
        element={<Support />}
      />
      <Route
        path="/demo"
        element={<Demo />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
