import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import CalendlyModal from './CalendlyModal';

import './Tr409AInProgressModal.scss';

export default function Tr409AInProgressModal({ showTr409AInProgressModal, setShowTr409AInProgressModal }) {
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  return (
    <>
      <Dialog className="Tr409AInProgressModal" open={showTr409AInProgressModal}>
        <h4>
          <WarningAmberRoundedIcon />
          We&rsquo;re currently working on your 409A valuation
        </h4>
        <p>
          Once this valuation is complete and we&rsquo;ve delivered the report, you can start a new 409A valuation.
        </p>
        <p>
          <span>Please note:&nbsp;</span>
          409A valuations are valid for an entire year unless a material event has happened within that time.
          So, if you do start a new valuation once this one is complete, be ready for our system to ask you if you&rsquo;ve had a recent material event.
          We do this because we look out for our clients and want you to stay in compliance with IRS regulations. We also don&rsquo;t want you paying unnecessary
          fees for 409A valuations you don&rsquo;t need.
        </p>
        <p>
          <span>If you don&rsquo;t want a new valuation but instead want to augment or update your most recent 409A valuation,&nbsp;</span>
          you&rsquo;ll need to make an appointment to speak with an initio representative to discuss your situation.
        </p>
        <p>
          Email: info@initio.software
          <br />
          {/* Phone: 555.555.5555 */}
        </p>
        <div className="bottom-btns">
          <Button onClick={() => setShowTr409AInProgressModal(false)}>
            Cancel
          </Button>
          <Button onClick={() => {
            setShowTr409AInProgressModal(false);
            setShowCalendlyModal(true);
          }}
          >
            Make appointment
          </Button>
        </div>
      </Dialog>
      <CalendlyModal showCalendlyModal={showCalendlyModal} setShowCalendlyModal={setShowCalendlyModal} />
    </>
  );
}

Tr409AInProgressModal.propTypes = {
  showTr409AInProgressModal: PropTypes.bool.isRequired,
  setShowTr409AInProgressModal: PropTypes.func.isRequired,
};
