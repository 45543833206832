import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToolTip from './ToolTip';
import {
  dropdownAnimationTimeMS,
  dropdownAnimationTimeS,
} from '../../utils/globals';
import './AnimatedDropdown.scss';

export default function AnimatedDropdown({
  title,
  content,
  customContainerStyles = {},
  useToolTipBackground = true,
  addFieldButton,
  hideDropdownButton,
}) {
  const [height, setHeight] = useState(0);

  return (
    <div className="AnimatedDropdown" style={customContainerStyles}>
      <div className={addFieldButton && 'added-button-wrap'}>
        <Button
          type="button"
          className="dropdown-title-container"
          onClick={(e) => {
            e.preventDefault();
            setHeight(height ? 0 : 'auto');
          }}
          style={{ visibility: hideDropdownButton ? 'hidden ' : 'visible' }}
        >
          {title}
          <ExpandMoreIcon
            className={`${height ? 'upward' : 'downward'}-chevron`}
            sx={{ transition: `transform ${dropdownAnimationTimeS}s linear` }}
          />
        </Button>
        {addFieldButton}
      </div>
      <AnimateHeight
        duration={dropdownAnimationTimeMS}
        height={height}
        style={{ marginTop: '12px' }}
        className="content-dropdown"
      >
        {
          useToolTipBackground ? (
            <ToolTip content={content} displayHeader={false} />
          ) : (
            (typeof content === 'string') ? <p>{content}</p> : content
          )
        }
      </AnimateHeight>
    </div>
  );
}

AnimatedDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  customContainerStyles: PropTypes.object,
  useToolTipBackground: PropTypes.bool,
  hideDropdownButton: PropTypes.bool,
  addFieldButton: PropTypes.element,
};
