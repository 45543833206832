import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';

import { UserDataContext } from '../contexts';

import useFetch from '../hooks/useFetch';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);

export default function Payment({
  makePaymentButtonRef,
  setPaymentFormIsLoading,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  discountPaymentData,
  setDiscountPaymentData,
  transactionId,
}) {
  const { userData } = useContext(UserDataContext);

  const [clientSecret, setClientSecret] = useState('');

  const [, paymentIntentRequest] = useFetch();

  function loadClientSecret() {
    paymentIntentRequest({
      url: '/stripe/create-payment-intent',
      body: {
        currency: 'usd',
        amount: process.env.REACT_APP_STRIPE_PRICE_AMOUNT,
        paymentMethodType: ['card'],
        metadata: {
          accountId: userData.userAttributes.userId,
          companyId: userData.userAttributes.companyId,
          companyName: userData.userAttributes.companyName,
          code_applied: false,
          discount_percentage: 0,
          original_amount: null,
          ...transactionId && { transactionId },
        },
      },
      onSuccess: (response) => {
        setClientSecret(JSON.parse(response).clientSecret);
        setPaymentFormIsLoading(false);
      },
    });
  }

  useEffect(() => loadClientSecret(), []);

  if (clientSecret.length > 0) {
    return (
      <Elements
        key={clientSecret}
        stripe={stripePromise}
        options={{ clientSecret }}
      >
        <CheckoutForm
          makePaymentButtonRef={makePaymentButtonRef}
          setPaymentIsProcessing={setPaymentIsProcessing}
          setPaymentIsCompleted={setPaymentIsCompleted}
          clientSecret={clientSecret}
          discountPaymentData={discountPaymentData}
          setDiscountPaymentData={setDiscountPaymentData}
        />
      </Elements>
    );
  }

  return <div className="loading-payment-form"><div className="dots-circle-spinner" /></div>;
}

Payment.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  setPaymentFormIsLoading: PropTypes.func.isRequired,
  discountPaymentData: PropTypes.object.isRequired,
  setDiscountPaymentData: PropTypes.func.isRequired,
  transactionId: PropTypes.string,
};
