import React, { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Box,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';

import RequestReceived from './RequestReceived';

import { UserDataContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';

import './index.scss';


export default function Support() {
  const { userData } = useContext(UserDataContext);

  const [submissionReceived, setSubmissionReceived] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submitType, setSubmitType] = useState('');

  const [, supportRequest] = useFetch();
  const [, sendEmailRequest] = useFetch();

  const [ticketNumber, setTicketNumber] = useState('');

  const nav = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    supportRequest({
      url: '/support/request',
      method: 'post',
      body: { subject, message },
      bodyIds: ['companyId', 'accountId'],
      onSuccess: (supportData) => {
        sendEmailRequest({
          url: '/emails/send-html-email',
          method: 'post',
          body: {
            recipient: userData.userAttributes.email,
            templateName: 'supportRequestSuccess',
            templateData: [
              `${userData.userAttributes.firstName} ${userData.userAttributes.lastName}`,
              supportData.Body.ticketNumber.toUpperCase(),
              subject,
              message,
            ],
            metadata: supportData.Body,
          },
          onSuccess: () => {
            setSubmissionReceived(true);
            setSubmitType('support');
            setTicketNumber(supportData.Body.ticketNumber.toUpperCase());
          },
        });
      },
    });
  }

  if (submissionReceived) return <RequestReceived submitType={submitType} ticketNumber={ticketNumber} />;

  return (
    <div className="page-container">
      <div className="bread-crumb">
        <button
          type="button"
          className="bread-crumb-btn"
          onClick={() => nav('/')}
        >
          <ArrowBackIcon className="back-icon" />
          <span className="bread-crumb-title">
            Home
          </span>
        </button>
      </div>
      <div>
        <div className="header">
          <p>Customer Support</p>
          <Button
            onClick={() => nav('/demo')}
          >
            Request a demo
          </Button>
        </div>
        <div className="main">
          <form className="message-container" onSubmit={handleSubmit}>
            <FormControl className="message-form">
              <p className="first-paragraph">
                If it&apos;s outside of our business hours,
                or it&apos;s not an urgent issue, please send
                us a message and we will get back to you within one business day.
              </p>
              <FormLabel htmlFor="message">
                Your Message
              </FormLabel>
              <TextField
                name="message"
                type="text"
                className="subject"
                placeholder="Subject (optional, but helpful)"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <p>
                If you&apos;re having a problem please describe
                the issue you&apos;re having. Please include details,
                and be as specific as possible.
              </p>
              <TextField
                className="note"
                multiline
                placeholder="Type Note"
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
            <Button className="send-note" type="submit">Send</Button>
          </form>
          <Box className="info">
            <div className="info-container">
              <ContactPhoneOutlinedIcon className="contact-icon" />
              <h2>Need Help?</h2>
              <p>
                Contact us at:
                <br />
                (415) 472-9175
                <br />
                <br />
                We&apos;re available:
                <br />
                Monday to Friday
                <br />
                9am to 5pm PST
                <br />
                <br />
                If we can&apos;t get back to you
                right away, we&apos;ll get back
                <br />
                to you within one
                <br />
                business day.
              </p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}
