import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@mui/material/FormHelperText';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { ReactComponent as BlueCalendar } from '../../images/icons/blue_calendar.svg';
import './ChooseFundingRoundDate.scss';

export default function ChooseFundingRoundDate({
  title,
  label,
  parentSetter,
  parentChosenValue,
  customContainerStyles = {},
}) {
  const [fundingDate, setFundingDate] = useState(moment());
  const dateFormat = 'MM/DD/YYYY';

  useEffect(() => {
    if (parentChosenValue) setFundingDate(parentChosenValue);
  }, []);

  useEffect(() => {
    parentSetter(fundingDate);
  }, [fundingDate]);

  return (
    <div style={customContainerStyles}>
      <p>{title}</p>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          sx={{ width: '380px' }}
          label={label}
          inputFormat={dateFormat}
          value={fundingDate}
          onChange={(val) => { setFundingDate(val); }}
          slotProps={{ textField: { variant: 'outlined' } }}
          slots={{ openPickerIcon: BlueCalendar }}
        />
        <FormHelperText
          style={{
            height: '18px',
            marginLeft: '12px',
            fontSize: '12px',
          }}
        >
          {dateFormat}
        </FormHelperText>
      </LocalizationProvider>
    </div>
  );
}

ChooseFundingRoundDate.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  parentSetter: PropTypes.func.isRequired,
  customContainerStyles: PropTypes.object,
  parentChosenValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};
