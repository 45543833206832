import React from 'react';
import PropTypes from 'prop-types';
import './RightInfoBlock.scss';

export default function RightInfoBlock({ title, label, showDataLoadingOverlay }) {
  return (
    <div className="RightInfoBlock">
      <div className="right-col-info-container">
        <span className="title">{title}</span>
        {showDataLoadingOverlay ? (
          <span className="data-label loading">filler</span>
        ) : (
          <span className="data-label">{label}</span>
        )}
      </div>
    </div>
  );
}

RightInfoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showDataLoadingOverlay: PropTypes.bool,
};
