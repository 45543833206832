import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import New409AReSubModal from '../../containers/Subscription/New409AReSubModal';
import CalendlyModal from './CalendlyModal';

import useFetch from '../../hooks/useFetch';

import { UserDataContext } from '../../contexts';

import './Early409AModal.scss';

export default function Early409AModal({ showEarly409AModal, setShowEarly409AModal }) {
  const [showNew409AReSubModal, setShowNew409AReSubModal] = useState(false);
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  const { userData } = useContext(UserDataContext);

  const [{ loading: startingNew409A }, startNew409ARequest] = useFetch();

  const twoMonthsOld409A = moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').isAfter(moment().subtract(2, 'months'));

  const nav = useNavigate();

  function startNew409A() {
    if (!userData.subscriptionData.isActive) {
      setShowEarly409AModal(false);
      setShowNew409AReSubModal(true);
      return;
    }
    startNew409ARequest({
      url: '/transactions/new-transaction',
      method: 'post',
      urlIds: ['companyId'],
      onSuccess: () => nav('/409a-info?newTransaction=true'),
    });
  }

  return (
    <>
      <Dialog className="Early409AModal" open={showEarly409AModal}>
        <h4>
          <WarningAmberRoundedIcon />
          {twoMonthsOld409A ? 'Your last valuation is less than 2 months old. Do you want to continue?' : 'Are you sure you want to start a new 409A valuation this soon?'}
        </h4>
        {twoMonthsOld409A ? (
          <>
            <p>
              According to our records your most recent valuation date is
              {` ${moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}.`}
            </p>
            <p>
              You cannot have two 409A valuations with valuation dates that are within 2 months of each other unless a material event
              has occurred or new Key Performance Indicators are available, like the company has had a recent dramatic change in revenue.
            </p>
          </>
        ) : (
          <p>
            You have a valid 409A valuation that won&rsquo;t expire for several more months, unless your company has experienced a material event
            since the latest valuation date we have on record, which is:
            {` ${moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}.`}
          </p>
        )}
        <p className="ul">
          <span>Examples of material events that would impact the 409A:</span>
        </p>
        <ul>
          <li>new financing whether equity or debt</li>
          <li>material litigation</li>
          <li>raising rounds</li>
          <li>fundamental change in business plan</li>
          <li>major technical breakthroughs</li>
          <li>significant changes in key personnel or corporate restructuring</li>
          <li>mergers and acquisitions</li>
        </ul>
        <p className={twoMonthsOld409A ? 'softer' : ''}>
          The above events are just examples, there are many other events, not listed here, that could materially affect the
          value of your company and trigger the need for a new valuation to ensure compliance with IRS regulations.
        </p>
        {twoMonthsOld409A && (
          <p>
            <span>If you don&rsquo;t want a new valuation but instead want to augment or update your most recent 409A valuation,&nbsp;</span>
            you&rsquo;ll need to make an appointment to speak with an initio representative to discuss your situation.
          </p>
        )}
        <p>
          Email: info@initio.software
          <br />
          {/* Phone: 555.555.5555 */}
        </p>
        <div className="bottom-btns">
          <Button disabled={startingNew409A} onClick={() => setShowEarly409AModal(false)}>
            Cancel
          </Button>
          {twoMonthsOld409A && (
            <Button onClick={() => {
              setShowEarly409AModal(false);
              setShowCalendlyModal(true);
            }}
            >
              Make appointment
            </Button>
          )}
          {startingNew409A ? (
            <Button disabled>
              <span className="dots-circle-spinner" />
              Starting new 409A...
            </Button>
          ) : (
            <Button onClick={startNew409A}>
              {twoMonthsOld409A ? 'Continue' : 'I want to start a new 409A'}
            </Button>
          )}
        </div>
      </Dialog>
      <New409AReSubModal showNew409AReSubModal={showNew409AReSubModal} setShowNew409AReSubModal={setShowNew409AReSubModal} />
      <CalendlyModal showCalendlyModal={showCalendlyModal} setShowCalendlyModal={setShowCalendlyModal} />
    </>
  );
}

Early409AModal.propTypes = {
  showEarly409AModal: PropTypes.bool.isRequired,
  setShowEarly409AModal: PropTypes.func.isRequired,
};
