import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

import Payment from '../../components/Payment';

import './MakePayment.scss';

import { dropdownAnimationTimeMS } from '../../utils/globals';
import { commaEvery3rdChar } from '../../utils';


export default function MakePayment({
  makePaymentButtonRef,
  setPaymentFormIsLoading,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  discountPaymentData,
  setDiscountPaymentData,
  isNthTransaction,
  transactionId,
}) {
  const [removedAmount, setRemovedAmount] = useState('');
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(discountPaymentData.removedAmount ? 'auto' : 0);
    setRemovedAmount(!discountPaymentData.removedAmount ? '' : commaEvery3rdChar(discountPaymentData.removedAmount));
  }, [discountPaymentData]);

  return (
    <div className="MakePayment">
      <div className="payment-header">
        <h4>Payment</h4>
        {isNthTransaction ? (
          <p>
            After your payment is approved, our seasoned 409A valuation experts will begin working on your 409A valuation.
            Our average turn-around time for valuations is between 5 to 7 business days.
          </p>
        ) : (
          <p>
            After your payment is approved, you can immediately start filling out a 409A valuation form.
            Once you submit the form our seasoned experts will begin processing your valuation.
            Our process takes between 5 to 7 business days.
          </p>
        )}
        <hr />
      </div>
      <div className="checkout-cols-container">
        <div className="checkout-col">
          <Payment
            makePaymentButtonRef={makePaymentButtonRef}
            setPaymentFormIsLoading={setPaymentFormIsLoading}
            setPaymentIsProcessing={setPaymentIsProcessing}
            setPaymentIsCompleted={setPaymentIsCompleted}
            discountPaymentData={discountPaymentData}
            setDiscountPaymentData={setDiscountPaymentData}
            transactionId={transactionId}
          />
          {!isNthTransaction && (
            <p className="legal-text">
              <span>&copy;</span>
              {' '}
              {new Date().getFullYear()}
              {' '}
              initio software. All rights reserved.
              <br />
              *409A valuations cover Pre-Seed, Seed or Series A rounds.
            </p>
          )}
        </div>
        {!isNthTransaction ? (
          <div className="checkout-col">
            <div className="order-summary">
              <h6>Order summary</h6>
              <hr />
              <p className="summary-bold">1 initio Company Account Subscription</p>
              <p>$2,500</p>
              <hr />
              <p className="summary-bold">1 free 409A valuation</p>
              <p>Free with 1st year subscription</p>
              <hr />
              <AnimateHeight
                duration={dropdownAnimationTimeMS}
                height={height}
              >
                <p className="summary-bold" style={{ color: '#B3261E' }}>Discount</p>
                <p style={{ color: '#B3261E' }}>
                  -$
                  {removedAmount}
                </p>
                <hr />
              </AnimateHeight>
              <span className="total" style={{ display: 'block' }}>Total</span>
              <span className="total-price" style={{ display: 'block' }}>
                <AttachMoneyIcon
                  sx={{
                    display: 'inline-block',
                    marginTop: '-5px',
                    marginRight: '-5px',
                  }}
                />
                {`${!discountPaymentData.chargeAmount ? '2,500' : commaEvery3rdChar(discountPaymentData.chargeAmount)}`}
              </span>
              {discountPaymentData.chargeAmount === '0' && (
                <div className="full-discount-tooltip">
                  <TipsAndUpdatesOutlinedIcon />
                  <p>
                    This Referral source gives you a discount of 100%, leaving a balance of $0.
                    Click &lsquo;Proceed without payment&rsquo; to continue.
                    <br />
                    Your card will not be charged.
                  </p>
                </div>
              )}
              <hr className="total-separator" />
              <div className="subscription-includes">
                <p className="sub-bold">Subscription is billed annually</p>
                <p className="underlined">What&apos;s included:</p>
                <ul>
                  <li>1 free 409A valuation w/ 1st year subscription</li>
                  <li>Additional users join for free</li>
                  <li>
                    Our proprietary 409A valuation sandbox:
                    <ul className="sub-list">
                      <li>Customized for your company</li>
                      <li>
                        Designed with sliders that, in real-time,
                        show you which factors have the most
                        impact on your company&apos;s share price and audit risk
                      </li>
                      <li>Allows you to finalize your 409A valuation when you&apos;re ready</li>
                    </ul>
                  </li>
                  <li>
                    A 409A valuation report detailing:
                    <ul className="sub-list">
                      <li>
                        Your company&apos;s estimated fair market value based
                        on your finalized inputs
                      </li>
                      <li>The assumptions and conclusions of our analysis</li>
                    </ul>
                  </li>
                  <li>Private market data (coming soon)</li>
                  <li>Equity scenario simulations (coming soon)</li>
                </ul>
                <hr />
                <p>
                  Any additional 409A valuations requested within the subscription
                  year will be charged full price at $2,500 per valuation.
                </p>
                <hr />
                <p className="footer-sub-text">
                  Subscriptions automatically renew every year, unless you cancel.
                  We will send reminders when your subscription is coming due.
                  We make it easy to cancel at any time.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="checkout-col">
            <div className="order-summary single-payment">
              <h6>Order summary</h6>
              <hr />
              <p className="summary-bold">1 409A valuation </p>
              <div className="payment-includes">
                <p>Price Includes our proprietary 409A sandbox:</p>
                <ul>
                  <li>customized for your company</li>
                  <li>
                    designed to help you better understand what factors have the most impact
                    on your company&apos;s share price and audit risk
                  </li>
                  <li>you can finalize your 409A when ready</li>
                </ul>
                <p>And a 409A valuation report detailing:</p>
                <ul>
                  <li>your company&apos;s estimated fair market value based on your finalized inputs</li>
                  <li>the assumptions and conclusions of our analysis</li>
                </ul>
              </div>
              <hr />
              <span className="total-price" style={{ display: 'block' }}>
                <AnimateHeight
                  duration={dropdownAnimationTimeMS}
                  height={height}
                >
                  <p className="summary-bold">Discount</p>
                  <p style={{ color: '#B3261E' }}>
                    -$
                    {removedAmount}
                  </p>
                  <hr />
                </AnimateHeight>
                <span className="total" style={{ display: 'block' }}>Total</span>
                <AttachMoneyIcon
                  sx={{
                    display: 'inline-block',
                    marginTop: '-5px',
                    marginRight: '-5px',
                  }}
                />
                {`${!discountPaymentData.chargeAmount ? '2,500' : commaEvery3rdChar(discountPaymentData.chargeAmount)}`}
              </span>
              {discountPaymentData.chargeAmount === '0' && (
                <div className="full-discount-tooltip">
                  <TipsAndUpdatesOutlinedIcon />
                  <p>
                    This Referral source gives you a discount of 100%, leaving a balance of $0.
                    Click &lsquo;Proceed without payment&rsquo; to continue.
                    <br />
                    Your card will not be charged.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

MakePayment.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  setPaymentFormIsLoading: PropTypes.func.isRequired,
  discountPaymentData: PropTypes.object.isRequired,
  setDiscountPaymentData: PropTypes.func.isRequired,
  isNthTransaction: PropTypes.bool,
  transactionId: PropTypes.string,
};
