import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import {
  UserDataContext,
  UserStateContext,
  ErrorMessageContext,
  NavWidthContext,
  AppDataContext,
} from '.';

export default function ContextsProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [userState, setUserState] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [navWidth, setNavWidth] = useState(null);
  const [isapiKeyWorking, setIsapiKeyWorking] = useState(true);

  const userDataProviderValue = useMemo(
    () => ({ userData, setUserData }),
    [userData]
  );
  const userStateProviderValue = useMemo(
    () => ({ userState, setUserState }),
    [userState]
  );
  const showErrorMessageProviderValue = useMemo(
    () => ({ showErrorMessage, setShowErrorMessage }),
    [showErrorMessage]
  );
  const navWidthProviderValue = useMemo(
    () => ({ navWidth, setNavWidth }),
    [navWidth]
  );
  const appDataProviderValue = useMemo(
    () => ({ isapiKeyWorking, setIsapiKeyWorking }),
    [isapiKeyWorking]
  );

  return (
    <UserDataContext.Provider value={userDataProviderValue}>
      <UserStateContext.Provider value={userStateProviderValue}>
        <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
          <NavWidthContext.Provider value={navWidthProviderValue}>
          <AppDataContext.Provider value={appDataProviderValue}>
            {children}
          </AppDataContext.Provider>
          </NavWidthContext.Provider>
        </ErrorMessageContext.Provider>
      </UserStateContext.Provider>
    </UserDataContext.Provider>
  );
}

ContextsProvider.propTypes = { children: PropTypes.element.isRequired };
