// TODO Remove those linter lines once reinserting 'adding extra user' + permissions dropdowns
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

// import Button from '@mui/material/Button';

import companyGraphic from '../../images/CompanyGraphic.svg';
import legalAvatarGraphic from '../../images/LegalAvatarGraphic.svg';

import FormField from './components/FormField';
// import AnimatedDropdown from '../409aInfo/AnimatedDropdown';

import {
  userInfoFormContent,
  companyInfoFormContent,
  legalFirmInfoFormContent,
  accountingFirmInfoFormContent,
  // extraUserFormContent,
  // extraUserDefaultValues,
} from './utils/formFieldsContents';

import './Wiz1.scss';

function dropDownContent() {
  return (
    <div className="DropdownContent">
      <p>
        <span className="strong">View </span>
        - can view the status of the company&apos;s transactions,
        like the status of the company&apos;s 409A submission(s).
        Can&apos;t view information about users on the account.
        Can&apos;t create, edit or finalize transactions or view details like financials,
        type of funding round(s), etc.
      </p>
      <p>
        <span className="strong">Edit </span>
        - can view company account information,
        but not information about users on the account.
        Can view the status of all transactions and all transaction details.
        Can start and edit transactions, but not finalize transactions.
        Only you, the primary admin on the account can finalize transactions.
      </p>
      <p>
        <span className="strong">Administrator </span>
        - can do everything someone with &apos;Edit&apos; permissions can do.
        Like you, they can view, add, edit, delete users associated with your
        company&apos;s initio account, as well as edit account information.
        Again, while they can start and edit transactions, they
        can&apos;t finalize transactions, only you can do that.
      </p>
    </div>
  );
}

export default function Wiz1({
  currentStep,
  userInfoInput,
  companyInfoInput,
  legalFirmInfoInput,
  accountingFirmInfoInput,
  inputHasError,
  setUserInfoInput,
  setCompanyInfoInput,
  setLegalFirmInfoInput,
  setAccountingFirmInfoInput,
  setInputHasError,
}) {
  // this function is invoked when adding a new user to the company info page
  function addExtraUserField() {
    // copy the arrays that we will then modify
    const newArray = copy(companyInfoInput.extraUsers);
    const newErrorArray = copy(inputHasError.extraUsers);

    // push the default values for a new extra user after assigning it an id
    newArray.push(copy(extraUserDefaultValues));

    // set all the new default values to false and
    // we add those to the extra user input error state array
    const defaultErrorState = copy(extraUserDefaultValues);
    Object.keys(defaultErrorState).forEach((value) => { defaultErrorState[value] = false; });
    newErrorArray.push(copy(defaultErrorState));

    // assign those new Array as new values in their respective objects
    setCompanyInfoInput({ ...companyInfoInput, extraUsers: newArray });
    setInputHasError({ ...inputHasError, extraUsers: newErrorArray });
  }

  function removeExtraUserField(i) {
    const newArray = copy(companyInfoInput.extraUsers);
    const newErrorArray = copy(inputHasError.extraUsers);

    newArray.splice(i, 1);
    newErrorArray.splice(i, 1);

    setCompanyInfoInput({ ...companyInfoInput, extraUsers: newArray });
    setInputHasError({ ...inputHasError, extraUsers: newErrorArray });
  }

  if (currentStep === 1) {
    return (
      <div className="Wiz1 step-1">
        <FormField
          fieldName="userFormField"
          formKeyId={1}
          header={<h4>Your information</h4>}
          targetInput={userInfoInput}
          setTargetInput={setUserInfoInput}
          textFields={userInfoFormContent}
          inputHasError={inputHasError}
          setInputHasError={setInputHasError}
        />
      </div>
    );
  }
  if (currentStep === 2) {
    return (
      <div className="Wiz1 step-2">
        <FormField
          fieldName="companyFormField"
          formKeyId={2}
          header={<h4>Your company&apos;s information</h4>}
          graphic={<img className="formSection1" src={companyGraphic} alt="" />}
          targetInput={companyInfoInput}
          setTargetInput={setCompanyInfoInput}
          textFields={companyInfoFormContent}
          inputHasError={inputHasError}
          setInputHasError={setInputHasError}
        />
        {/**
        * NOTE: Adding 'extra users' put on hold for alpha MVP
        * TODO: Add back when needed with necessary imports and functions above
        */}
        {/* <hr />
        {companyInfoInput.extraUsers.length > 0 &&
          companyInfoInput.extraUsers.map((extraUser, i) => {
            return (
              <FormField
                key={companyInfoInput.extraUsers.indexOf(extraUser)}
                formKeyId={companyInfoInput.extraUsers.indexOf(extraUser) + 2.5}
                fieldName="extraUsers"
                currentFieldIndex={i}
                header={(
                  <>
                    <h4 className="title-with-helper-text">Add user</h4>
                    <p>You&apos;ll need to assign account permissions for this user.</p>
                  </>
                )}
                targetInput={companyInfoInput}
                setTargetInput={setCompanyInfoInput}
                textFields={extraUserFormContent}
                inputHasError={inputHasError}
                setInputHasError={setInputHasError}
                removeExtraUserField={removeExtraUserField}
                removeLinkLabel="Remove User"
              />
            );
          })}

        {/* <AnimatedDropdown
          title="Type of account permissions"
          content={dropDownContent()}
          addFieldButton={(
            <Button
              variant="outlined"
              className="add-field-button"
              onClick={addExtraUserField}
            >
              + Add user(s) to Company account
            </Button>
          )}
          hideDropdownButton={companyInfoInput.extraUsers.length === 0}
        /> */}
      </div>
    );
  }
  if (currentStep === 3) {
    return (
      <div className="Wiz1 step-3">
        <h4>Your accountant and legal firm</h4>
        <div className="legal-info-box">
          <img src={legalAvatarGraphic} alt="input info about your legal or accounting firm" />
          <p className="legal-info-text">
            Our platform makes it easy for you to share your transactions
            with your legal firm and accounting firm.
            {/* TODO Permissions on fold for Alpha MVP, add this text back in for MVP */}
            {/* You can even give your point of contact permission
            to complete transactions on your behalf.
            The only thing they can&apos;t ever do is finalize transactions.
            Only you can do that. */}
          </p>
        </div>
        <hr style={{ marginTop: '34px' }} />
        <FormField
          fieldName="legalFormField"
          formKeyId={3}
          header={(
            <>
              <h4 className="title-with-helper-text">Your legal firm (optional)</h4>
              <p>
                You&apos;ll need to assign account permissions
                for your point of contact at this firm.
              </p>
            </>
          )}
          targetInput={legalFirmInfoInput}
          setTargetInput={setLegalFirmInfoInput}
          textFields={legalFirmInfoFormContent}
          inputHasError={inputHasError}
          setInputHasError={setInputHasError}
        />
        <hr />
        <FormField
          fieldName="legalFormField"
          formKeyId={4}
          header={(
            <>
              <h4 className="title-with-helper-text">Your accounting firm (optional)</h4>
              <p>
                You&apos;ll need to assign account permissions
                for your point of contact at this firm.
              </p>
            </>
          )}
          targetInput={accountingFirmInfoInput}
          setTargetInput={setAccountingFirmInfoInput}
          textFields={accountingFirmInfoFormContent}
          inputHasError={inputHasError}
          setInputHasError={setInputHasError}
        />
        {/**
        * NOTE: Account permissions on hold for alpha MVP
        * TODO: Add back when needed with necessary imports and functions above
        */}
        {/* <hr />
        <AnimatedDropdown
          title="Type of account permissions"
          content={dropDownContent()}
        /> */}
      </div>
    );
  }

  return <h3>Not a valid step for the 1st Wizard</h3>;
}

Wiz1.propTypes = {
  currentStep: PropTypes.number.isRequired,
  userInfoInput: PropTypes.object.isRequired,
  companyInfoInput: PropTypes.object.isRequired,
  legalFirmInfoInput: PropTypes.object.isRequired,
  accountingFirmInfoInput: PropTypes.object.isRequired,
  inputHasError: PropTypes.object.isRequired,
  setUserInfoInput: PropTypes.func.isRequired,
  setCompanyInfoInput: PropTypes.func.isRequired,
  setLegalFirmInfoInput: PropTypes.func.isRequired,
  setAccountingFirmInfoInput: PropTypes.func.isRequired,
  setInputHasError: PropTypes.func.isRequired,
};
