import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import FormField from '../../ClientIntakeForm/components/FormField';

import companyGraphic from '../../../images/CompanyGraphic.svg';
import firmSmallGraphic from '../assets/firm_small.svg';

import {
  allFormsContent,
  userInfoFormContent,
  companyInfoFormContent,
  legalFirmInfoFormContent,
  accountingFirmInfoFormContent,
  hasErrorDefaultValues,
} from '../../ClientIntakeForm/utils/formFieldsContents';

import useFetch from '../../../hooks/useFetch';

import './EditModal.scss';

export default function EditModal({
  showEditModal,
  setShowEditModal,
  editSection,
  accountData,
  setAccountData,
  companyData,
  setCompanyData,
  userData,
  setUserData,
}) {
  const [inputsError, setInputsError] = useState(hasErrorDefaultValues);
  const [savingData, setSavingData] = useState(false);

  const [previousAccountData, setPreviousAccountData] = useState({});
  const [previousCompanyData, setPreviousCompanyData] = useState({});

  const [{ loading: accountUpdating, success: accountUpdateSuccess }, updateAccountRequest] = useFetch();
  const [{ loading: companyUpdating, success: companyUpdateSuccess }, updateCompanyRequest] = useFetch();


  function checkForErrors() {
    const checkForEmptyInputs = () => {
      const newInputsError = { ...inputsError };
      const allData = { ...accountData, ...companyData };
      if (!Object.keys(allData).every((x) => {
        if (allFormsContent.find((input) => input.name === x) && !allFormsContent.find((input) => input.name === x).isOptional) {
          newInputsError[x] = allData[x] === '';
          return allData[x] !== '';
        }
        return true;
      })) {
        setInputsError(newInputsError);
        return false;
      }
      return true;
    };

    const checkForErrorsInForm = () => Object.values(inputsError).every((x) => x === false || Array.isArray(x));

    return (
      checkForEmptyInputs() && checkForErrorsInForm()
    );
  }

  function updateUserData() {
    if (!checkForErrors()) return;

    if (isEqual(accountData, previousAccountData) && isEqual(companyData, previousCompanyData)) {
      setSavingData(false);
      setShowEditModal(false);
    }

    setSavingData(true);
    const newAccountData = { ...accountData, companyName: companyData.companyName };
    if (!isEqual(newAccountData, previousAccountData)) {
      updateAccountRequest({
        url: '/accounts/update-account',
        body: { ...accountData, companyName: companyData.companyName, updateAttributes: 1 },
        urlIds: ['companyId'],
        onSuccess: () => {
          setAccountData(newAccountData);
          setPreviousAccountData(newAccountData);
        },
      });
    }
    if (!isEqual(companyData, previousCompanyData)) {
      updateCompanyRequest({
        url: '/companies/update-company',
        urlIds: ['companyId'],
        body: { ...companyData, updateAttributes: 1 },
        onSuccess: () => {
          setCompanyData(companyData);
          setPreviousCompanyData(companyData);
        },
      });
    }
  }

  useEffect(() => { if (!isEmpty(accountData) && isEmpty(previousAccountData)) setPreviousAccountData(accountData); }, [accountData]);
  useEffect(() => { if (!isEmpty(companyData) && isEmpty(previousCompanyData)) setPreviousCompanyData(companyData); }, [companyData]);

  useEffect(() => {
    if (!accountUpdating && !companyUpdating && savingData) {
      setSavingData(false);
      setShowEditModal(false);
    }
  }, [accountUpdating, companyUpdating]);

  useEffect(() => {
    if (accountUpdateSuccess) {
      setUserData({
        ...userData,
        accountData,
        userAttributes: {
          ...userData.userAttributes,
          firstName: accountData.firstName,
          lastName: accountData.lastName,
        },
      });
    }
  }, [accountUpdateSuccess]);

  useEffect(() => {
    if (companyUpdateSuccess) {
      setUserData({
        ...userData,
        companyData,
        userAttributes: {
          ...userData.userAttributes,
          companyName: companyData.companyName,
        },
      });
    }
  }, [companyUpdateSuccess]);

  return (
    <Dialog className="EditModal" open={!!showEditModal}>
      {editSection === 'user-info' && (
        <div className="Wiz1 step-1">
          <FormField
            fieldName="userFormField"
            formKeyId={1}
            header={<h4>Your information</h4>}
            targetInput={accountData}
            setTargetInput={setAccountData}
            textFields={userInfoFormContent}
            inputHasError={inputsError}
            setInputHasError={setInputsError}
          />
        </div>
      )}
      {editSection === 'company-info' && (
        <div className="Wiz1 step-2">
          <FormField
            fieldName="companyFormField"
            formKeyId={2}
            header={<h4>Your company&apos;s information</h4>}
            graphic={<img className="formSection1" src={companyGraphic} alt="" />}
            targetInput={companyData}
            setTargetInput={setCompanyData}
            textFields={companyInfoFormContent}
            inputHasError={inputsError}
            setInputHasError={setInputsError}
          />
        </div>
      )}
      {editSection === 'firm-info' && (
        <div className="Wiz1 step-3">
          <h4>Your accountant and legal firm</h4>
          <div className="legal-info-box">
            <img src={firmSmallGraphic} alt="input info about your legal or accounting firm" />
            <p className="legal-info-text">
              Please provide your point of contact(s)&rsquo; information.
              It will make it easier for us, with your permission,
              to contact them for information, so we can provide the most
              accurate valuation possible. In the future this info will
              enable you to directly share or request help from them.
            </p>
          </div>
          <hr style={{ marginTop: '34px' }} />
          <FormField
            fieldName="legalFormField"
            formKeyId={3}
            header={(
              <>
                <h4 className="title-with-helper-text">Your legal firm (optional)</h4>
                <p>
                  You&apos;ll need to assign account permissions
                  for your point of contact at this firm.
                </p>
              </>
            )}
            targetInput={companyData}
            setTargetInput={setCompanyData}
            textFields={legalFirmInfoFormContent}
            inputHasError={inputsError}
            setInputHasError={setInputsError}
          />
          <hr />
          <FormField
            fieldName="legalFormField"
            formKeyId={4}
            header={(
              <>
                <h4 className="title-with-helper-text">Your accounting firm (optional)</h4>
                <p>
                  You&apos;ll need to assign account permissions
                  for your point of contact at this firm.
                </p>
              </>
            )}
            targetInput={companyData}
            setTargetInput={setCompanyData}
            textFields={accountingFirmInfoFormContent}
            inputHasError={inputsError}
            setInputHasError={setInputsError}
          />
        </div>
      )}
      <div className="bottom-btns">
        <Button disabled={savingData} onClick={() => setShowEditModal(false)}>
          <CancelOutlinedIcon />
          Cancel
        </Button>
        {savingData ? (
          <Button disabled>
            <span className="dots-circle-spinner" />
            Saving...
          </Button>
        ) : (
          <Button onClick={updateUserData}>
            <SaveOutlinedIcon />
            Save
          </Button>
        )}
      </div>
    </Dialog>
  );
}

EditModal.propTypes = {
  showEditModal: PropTypes.bool.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  editSection: PropTypes.string.isRequired,
  accountData: PropTypes.object.isRequired,
  setAccountData: PropTypes.func.isRequired,
  companyData: PropTypes.object.isRequired,
  setCompanyData: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
};
