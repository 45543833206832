import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ReviewDropdown from './ReviewDropdown';
import ReviewInfoTile from './ReviewInfoTile';
import MakePayment from '../ClientIntakeForm/MakePayment';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import './ReviewAndCheckout.scss';

export default function ReviewAndCheckout({
  wizSteps,
  setWizSteps,
  isNthTransaction,
  transactionId,
  submissionInfoSucceeded,
  steps,
  currentStep,
  openNewWizard,
  areAll4DataWizardsComplete,
  // Wiz1
  valuationDate,
  whenIsExitLikelyToHappen,
  whatTypeOfExit,
  industryVertical,
  // Wiz2
  wiz2Data,
  EBITDAChecked,
  // Wiz3
  chosenFunding,
  currentFundingDate,
  totalSafeOrConvertible,
  totalAmountRaised,
  interestRate,
  valuationCap,
  preOrPostMoneyNotes,
  receivedFutureTerms,
  anySecondaryCommonStockTransactions,
  percentageSecondaryCommonStockSold,
  secondaryCommonStockTransactionTotalAmount,
  // Wiz4
  capTableProvider,
  capTableFilesUploaded,
  optionLedgerFilesUploaded,
  financialsFilesUploaded,
  articlesOfIncorporationUploaded,
  secondaryTransactionDocs,
  safeOrConvertibleForCurrentOrPreviousRounds,
  safeOrConvertibleNoteAgreement,
  termsSheets,
  showErrors,
  setShowErrors,
  show409AReview,
  showDataLoadingOverlay,
  // Payment
  makePaymentButtonRef,
  setPaymentFormIsLoading,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  discountPaymentData,
  setDiscountPaymentData,
}) {
  function arrayToStringOfNoLengthIfLength0(arr) {
    return ((arr?.length === 0) ? '' : arr);
  }

  if (currentStep === 1) {
    return (
      <div className="ReviewAndCheckout">
        {showDataLoadingOverlay && (
          <div className="review-loading-wrapper">
            <LoadingSpinner className="custom-loading-spinner" />
          </div>
        )}
        {(showErrors && !areAll4DataWizardsComplete) && (
          <div className="warning-block">
            <WarningIcon className="warning-icon" />
            <div className="warning-message">
              <h2>Failed to submit information</h2>
              <p>
                All required information must be provided before you can submit your information.
              </p>
            </div>
          </div>
        )}
        {show409AReview ? (
          <div className="review-header-row">
            <h3>Submitted 409A valuation form</h3>
            <p>
              This information has been submitted, edits are no longer possible.
              We are in the process of reviewing this information and building your customized 409A valuation sandbox.
            </p>
          </div>
        ) : (
          <div className="page-header-row">
            <h3>Review and submit information</h3>
            <Button
              className="top-submit-info-btn"
              onClick={() => {
                if (!areAll4DataWizardsComplete) {
                  setShowErrors(true);
                } else {
                  if (isNthTransaction) {
                    const newSteps = { ...wizSteps };
                    newSteps.currentStep += 1;
                    setWizSteps(newSteps);
                    return;
                  }
                  setShowErrors(false);
                  submissionInfoSucceeded();
                  // openNewWizard(4, steps[steps?.length - 1].totalSteps(), 2);
                }
              }}
            >
              Submit information
            </Button>
          </div>
        )}
        <hr />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={0}
          headline={steps[0].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={0}
                wizardTotalSteps={steps[0].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={1}
                title="Valuation Date"
                data={valuationDate}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={0}
                wizardTotalSteps={steps[0].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={2}
                title="Company industry"
                data={industryVertical?.title}
                showErrors={showErrors}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={0}
                  wizardTotalSteps={steps[0].totalSteps()}
                  openNewWizard={openNewWizard}
                  currentStep={3}
                  title="Most likely type of exit event"
                  data={whatTypeOfExit}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
                <ReviewInfoTile
                  activeWizard={0}
                  wizardTotalSteps={steps[0].totalSteps()}
                  openNewWizard={openNewWizard}
                  currentStep={4}
                  title="Most likely timing of exit event"
                  data={whenIsExitLikelyToHappen}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>
            </div>
          )}
        />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={1}
          headline={steps[1].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={steps[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total current cash"
                  data={wiz2Data.totalCurrentCash}
                  showIcon={wiz2Data.totalCurrentCash ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />

                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={steps[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Current monthly cash burn"
                  data={wiz2Data.currentMonthlyCashBurn}
                  showIcon={wiz2Data.currentMonthlyCashBurn ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={steps[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total non-convertible debt"
                  data={wiz2Data.totalNonConvertibleDebt}
                  showIcon={wiz2Data.totalNonConvertibleDebt ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />

                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={steps[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total convertible debt"
                  data={wiz2Data.totalConvertibleDebt}
                  showIcon={wiz2Data.totalConvertibleDebt ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>
              {!!(EBITDAChecked ||
                wiz2Data.totalCurrentFiscalYearRevenue ||
                wiz2Data.totalNextFiscalYearRevenue ||
                wiz2Data.totalCurrentFiscalYearEBITDA ||
                wiz2Data.totalNextFiscalYearEBITDA) &&
                (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={steps[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total current fiscal year revenue"
                        data={wiz2Data.totalCurrentFiscalYearRevenue}
                        showIcon={wiz2Data.totalCurrentFiscalYearRevenue ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={steps[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total next fiscal year revenue"
                        data={wiz2Data.totalNextFiscalYearRevenue}
                        showIcon={wiz2Data.totalNextFiscalYearRevenue ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={steps[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total current fiscal year EBITDA"
                        data={wiz2Data.totalCurrentFiscalYearEBITDA}
                        showIcon={wiz2Data.totalCurrentFiscalYearEBITDA ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={steps[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total next fiscal year EBITDA"
                        data={wiz2Data.totalNextFiscalYearEBITDA}
                        showIcon={wiz2Data.totalNextFiscalYearEBITDA ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                    </div>
                  </>
                )}
              <ReviewInfoTile
                activeWizard={1}
                wizardTotalSteps={steps[1].totalSteps()}
                openNewWizard={openNewWizard}
                title="Percent of remaining option pool you intend to issue within the next 12 months"
                data={`${(wiz2Data.ntmOptionsPerc).toString()}%`}
                showErrors={showErrors}
              />
            </div>
          )}
        />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={2}
          headline={steps[2].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={steps[2].totalSteps()}
                openNewWizard={openNewWizard}
                title="Total amount raised"
                data={totalAmountRaised}
                currentStep={1}
                showIcon={totalAmountRaised ? 'AttachMoneyIcon' : ''}
                customContainerStyles={{ width: '100%' }}
                showErrors={showErrors}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={2}
                  wizardTotalSteps={steps[2].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Current funding type"
                  data={chosenFunding}
                  currentStep={2}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
                {chosenFunding !== 'No funding' && (
                  <ReviewInfoTile
                    activeWizard={2}
                    wizardTotalSteps={steps[2].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="Date of current round of funding"
                    data={currentFundingDate}
                    currentStep={3}
                    customContainerStyles={{ width: '50%' }}
                    showErrors={showErrors}
                  />
                )}
              </div>

              {
                (chosenFunding === 'SAFE Note' || chosenFunding === 'Convertible Note') && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Total amount raised"
                      data={totalSafeOrConvertible}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Interest rate"
                      data={interestRate}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="PercentIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Valuation Cap"
                      data={valuationCap}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Pre or Post money"
                      data={preOrPostMoneyNotes}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showErrors={showErrors}
                    />
                  </div>
                )
              }

              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={steps[2].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={4}
                title="Has the company received any term sheets for future rounds of funding?"
                data={(
                  receivedFutureTerms === null ?
                    '' :
                    (receivedFutureTerms === true) ? 'Yes' : 'No'
                )}
                showErrors={showErrors}
              />

              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={steps[2].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={5}
                title="Has the company had any secondary common stock transactions?"
                data={(
                  anySecondaryCommonStockTransactions === null ?
                    '' :
                    (anySecondaryCommonStockTransactions === true) ? 'Yes' : 'No'
                )}
                showErrors={showErrors}
              />

              {
                (anySecondaryCommonStockTransactions === true) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Total amount of secondary stock transaction"
                      data={secondaryCommonStockTransactionTotalAmount}
                      currentStep={5}
                      customContainerStyles={{ width: '50%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={steps[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Percent of common stock sold"
                      data={(
                        percentageSecondaryCommonStockSold?.length === 0 ?
                          '' :
                          `${percentageSecondaryCommonStockSold}%`
                      )}
                      currentStep={5}
                      customContainerStyles={{ width: '50%' }}
                      showErrors={showErrors}
                    />
                  </div>
                )
              }

            </div>
          )}
        />
        <ReviewDropdown
          steps={steps}
          openNewWizard={openNewWizard}
          activeWiz={3}
          headline={steps[3].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={steps[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="Who's your Cap table provider?"
                data={capTableProvider}
                currentStep={1}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={steps[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Cap Table provider"
                data={(capTableFilesUploaded?.length === 0) ? '' : capTableFilesUploaded}
                // currentStep={2}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={steps[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Option Ledger (as applicable)"
                data={(optionLedgerFilesUploaded?.length === 0) ? 'N/A' : optionLedgerFilesUploaded}
                currentStep={2}
                showErrors={false}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={steps[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Financials"
                data={(financialsFilesUploaded?.length === 0) ? '' : financialsFilesUploaded}
                currentStep={2}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={steps[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Articles of incorporation"
                data={arrayToStringOfNoLengthIfLength0(articlesOfIncorporationUploaded)}
                // currentStep={3}
                showErrors={showErrors}
              />
              {
                anySecondaryCommonStockTransactions && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={steps[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: Secondary transaction documents"
                    data={arrayToStringOfNoLengthIfLength0(secondaryTransactionDocs)}
                    currentStep={4} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
              {
                safeOrConvertibleForCurrentOrPreviousRounds && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={steps[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: SAFE or convertible note agreement documents"
                    data={arrayToStringOfNoLengthIfLength0(safeOrConvertibleNoteAgreement)}
                    currentStep={5} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
              {
                receivedFutureTerms && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={steps[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: Future terms sheets documents"
                    data={arrayToStringOfNoLengthIfLength0(termsSheets)}
                    currentStep={6} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
            </div>
          )}
        />
      </div>
    );
  }

  if (currentStep === 2) {
    return (
      <MakePayment
        makePaymentButtonRef={makePaymentButtonRef}
        setPaymentFormIsLoading={setPaymentFormIsLoading}
        setPaymentIsProcessing={setPaymentIsProcessing}
        setPaymentIsCompleted={setPaymentIsCompleted}
        discountPaymentData={discountPaymentData}
        setDiscountPaymentData={setDiscountPaymentData}
        transactionId={transactionId}
        isNthTransaction
      />
    );
  }

  return (
    <div className="ReviewAndCheckout">
      <h1>ReviewAndCheckout - error</h1>
    </div>
  );
}

ReviewAndCheckout.propTypes = {
  wizSteps: PropTypes.object,
  setWizSteps: PropTypes.func,
  transactionId: PropTypes.string,
  isNthTransaction: PropTypes.bool,
  valuationDate: PropTypes.string,
  submissionInfoSucceeded: PropTypes.func,
  areAll4DataWizardsComplete: PropTypes.bool,
  wiz2Data: PropTypes.object,
  EBITDAChecked: PropTypes.bool,
  openNewWizard: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
  whenIsExitLikelyToHappen: PropTypes.string,
  whatTypeOfExit: PropTypes.string,
  industryVertical: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  // Wiz3
  chosenFunding: PropTypes.string,
  currentFundingDate: PropTypes.string,
  totalAmountRaised: PropTypes.string,
  totalSafeOrConvertible: PropTypes.string,
  interestRate: PropTypes.string,
  valuationCap: PropTypes.string,
  preOrPostMoneyNotes: PropTypes.string,
  receivedFutureTerms: PropTypes.bool,
  anySecondaryCommonStockTransactions: PropTypes.bool,
  percentageSecondaryCommonStockSold: PropTypes.string,
  secondaryCommonStockTransactionTotalAmount: PropTypes.string,
  // Wiz4
  capTableProvider: PropTypes.string,
  capTableFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  optionLedgerFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  financialsFilesUploaded: PropTypes.arrayOf(PropTypes.object),
  articlesOfIncorporationUploaded: PropTypes.arrayOf(PropTypes.object),
  secondaryTransactionDocs: PropTypes.arrayOf(PropTypes.object),
  safeOrConvertibleForCurrentOrPreviousRounds: PropTypes.bool,
  safeOrConvertibleNoteAgreement: PropTypes.arrayOf(PropTypes.object),
  termsSheets: PropTypes.arrayOf(PropTypes.object),
  showErrors: PropTypes.bool,
  setShowErrors: PropTypes.func,
  // Read only review
  show409AReview: PropTypes.bool,
  showDataLoadingOverlay: PropTypes.bool,
  // Payment
  makePaymentButtonRef: PropTypes.object,
  setPaymentFormIsLoading: PropTypes.func,
  setPaymentIsProcessing: PropTypes.func,
  setPaymentIsCompleted: PropTypes.func,
  discountPaymentData: PropTypes.object.isRequired,
  setDiscountPaymentData: PropTypes.func.isRequired,
};
