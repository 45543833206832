import React, { useContext } from 'react';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { UserDataContext } from '../../contexts';

export default function PaymentHistory() {
  const { userData } = useContext(UserDataContext);
  const { paymentData } = userData;

  return (
    <main className="PaymentHistory">
      <h4>Payment history</h4>
      <hr />
      {paymentData && userData.paymentData.map((payment, paymentIndex) => (
        <React.Fragment key={payment.resource}>
          <div className="payment-data-row">
            <div className="data-tiles-grid">
              <div className="data-tile">
                <span className="data-name">Payment date</span>
                <span className="data">{moment(paymentData[paymentIndex].date_time, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')}</span>
              </div>
              <div className="data-tile">
                <span className="data-name">Reason for payment</span>
                <span className="data">-</span>
              </div>
              <div className="data-tile">
                <span className="data-name">Referral source code</span>
                <span className="data">{paymentData[paymentIndex].metadata.code || '-'}</span>
              </div>
              <div className="data-tile">
                <span className="data-name">Order number</span>
                <span className="data">-</span>
              </div>
              <div className="data-tile">
                <span className="data-name">Total paid</span>
                <span className="data">{`$${paymentData[paymentIndex].amount_captured}`}</span>
              </div>
              <div className="data-tile">
                <span className="data-name">Discount amount</span>
                <span className="data">
                  {paymentData[paymentIndex].metadata.discount_percentage ?
                    `${parseFloat(paymentData[paymentIndex].metadata.discount_percentage) * 100}%` : '-'}
                </span>
              </div>
            </div>
            {!!paymentData[paymentIndex].receipt_url && paymentData[paymentIndex].receipt_url !== 'n/a' && (
              <Tooltip
                disableInteractive
                title="View invoice"
                PopperProps={{ className: 'bottom-arrow-tooltip' }}
                placement="top"
                arrow
              >
                <IconButton onClick={() => window.open(paymentData[paymentIndex].receipt_url)}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {paymentIndex !== paymentData.length - 1 && <hr className="payment-info-hr" />}
        </React.Fragment>
      ))}
      <hr />
    </main>
  );
}
