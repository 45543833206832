import React, { useState, useEffect, useContext } from 'react';

import AnimateHeight from 'react-animate-height';

import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

import EditModal from './components/EditModal';

import { ReactComponent as CompanyGraphic } from './assets/company.svg';
import { ReactComponent as AccountingFirmGraphic } from './assets/firm.svg';

import { UserDataContext } from '../../contexts';

export default function BasicInfo() {
  const { userData, setUserData } = useContext(UserDataContext);

  const [accountData, setAccountData] = useState({});
  const [companyData, setCompanyData] = useState({});

  const [showEditModal, setShowEditModal] = useState(false);
  const [editSection, setEditSection] = useState('');

  useEffect(() => {
    if (userData.companyData && userData.accountData) {
      setAccountData(userData.accountData);
      setCompanyData(userData.companyData);
    }
  }, [userData]);

  const userFirstAndLastName = `${accountData.firstName || ''} ${accountData.lastName || ''}`;
  const companyAddress = `${companyData.companyStreetAddress || ''} ${companyData.city || ''}, ${companyData.state || ''} ${companyData.zipCode || ''} ${companyData.country || ''}`;
  const typeOfFirm = companyData.legalFirmName ? 'Legal' : companyData.accountingFirmName ? 'Accounting' : '';

  const basicInfoSections = [
    {
      sectionTitle: userFirstAndLastName,
      sectionName: 'user-info',
      sectionData: [
        { dataName: 'Name', data: userFirstAndLastName },
        { dataName: 'Email', data: accountData.email },
        { dataName: 'Phone number', data: accountData.phoneNumber },
      ],
    },
    {
      sectionTitle: 'Company information',
      sectionName: 'company-info',
      sectionData: [
        { dataName: 'Company name', data: companyData.companyName },
        { dataName: 'Doing business as (DBA)', data: companyData.dba },
        { dataName: 'Employer identification number (EIN)', data: companyData.ein },
        { dataName: 'Company street address', data: companyAddress },
        { dataName: 'Company website', data: companyData.companyWebsite },
      ],
    },
    {
      sectionTitle: 'Your accounting and legal firm(s)',
      sectionName: 'firm-info',
      sectionData: [
        { dataName: 'Type of firm', data: typeOfFirm },
        { dataName: 'Name of firm', data: companyData.legalFirmName || companyData.accountingFirmName },
        { dataName: 'Name of advisor', data: companyData.legalAdvisorName || companyData.accountingAdvisorName },
        { dataName: 'Advisor email', data: companyData.legalAdvisorEmail || companyData.accountingAdvisorEmail },
      ],
    },
  ];

  const [sectionsDropdownHeights, setSectionsDropdownHeights] = useState([...Array(basicInfoSections.length).fill('auto')]);

  const setNewDropdownHeight = (index) => sectionsDropdownHeights.map((height, i) => (i === index ? (height ? 0 : 'auto') : height));

  return (
    <main className="BasicInfo">
      <p>
        As authorized signatory on this account you are the one and only person that is able
        to finalize transactions with initio on behalf of&nbsp;
        {companyData.companyName}
        .&nbsp;
        Users with Admin roles can make payments and start transactions like 409A valuations,
        but they can&rsquo;t finalize the valuation, because they are not legally authorized to sign on behalf of your company.
      </p>
      <hr />
      {basicInfoSections.map((section, sectionIndex) => (
        <div key={section.sectionName}>
          <div
            role="button"
            className="header-row"
            onClick={() => setSectionsDropdownHeights(setNewDropdownHeight(sectionIndex))}
            onKeyDown={(e) => { if (e.key === 'Enter') setSectionsDropdownHeights(setNewDropdownHeight(sectionIndex)); }}
            tabIndex={0}
          >
            <IconButton
              className="chevron-btn"
              onClick={() => { setSectionsDropdownHeights(setNewDropdownHeight(sectionIndex)); }}
            >
              <ExpandCircleDownOutlinedIcon className={`circle-chevron${sectionsDropdownHeights[sectionIndex] ? ' upward' : ' downward'}`} />
            </IconButton>
            <h5>{section.sectionTitle}</h5>
            <IconButton
              className="edit-btn"
              onClick={(e) => {
                e.stopPropagation();
                setShowEditModal(true);
                setEditSection(section.sectionName);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          <div>
            <AnimateHeight duration={500} height={sectionsDropdownHeights[sectionIndex]}>
              {section.sectionTitle === userFirstAndLastName && (
                <div className="section-banner with-text">
                  <Avatar>{userData.userAttributes.firstName.charAt(0)}</Avatar>
                  <p>
                    This is the information we&rsquo;ll use if we need to contact you about your valuation.
                    <br />
                    Please be sure to keep it up to date.
                  </p>
                </div>
              )}
              {section.sectionTitle === 'Company information' && <div className="section-banner"><CompanyGraphic /></div>}
              {section.sectionTitle === 'Your accounting and legal firm(s)' && (
                <>
                  <div className="section-banner"><AccountingFirmGraphic /></div>
                  <div className="section-banner with-text">
                    <Avatar>{userData.userAttributes.firstName.charAt(0)}</Avatar>
                    <p>
                      Your accounting and legal firm information. We make it easy to download your transactions,
                      like your 409A valuations, so you can email them copies and keep them informed.
                    </p>
                  </div>
                </>
              )}
              <div className="data-tiles-grid">
                {section.sectionData.map((data, dataIndex) => (
                  <React.Fragment key={data.dataName}>
                    <div className="data-tile">
                      <span className="data-name">{data.dataName}</span>
                      <span className="data">{data.data || ''}</span>
                    </div>
                    {section.sectionTitle === userFirstAndLastName && dataIndex === 0 && <div />}
                  </React.Fragment>
                ))}
              </div>
              {sectionIndex !== basicInfoSections.length - 1 && <hr className="full-blue-hr" />}
            </AnimateHeight>
          </div>
        </div>
      ))}
      <EditModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        editSection={editSection}
        accountData={accountData}
        setAccountData={setAccountData}
        companyData={companyData}
        setCompanyData={setCompanyData}
        userData={userData}
        setUserData={setUserData}
      />
    </main>
  );
}
