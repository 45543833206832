import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import HomepageHeader from './Components/HomepageHeader';

import './TransactionInfo.scss';

export default function TransactionInfo({
  reportIsReady,
  hasOldSandbox,
  viewPreviousTransaction,
  setViewPreviousTransaction,
}) {
  const nav = useNavigate();

  const previousTransactionQuery = viewPreviousTransaction ? `?previousTransaction=${viewPreviousTransaction?.transactionId}&review=true` : '';

  return (
    <div className="TransactionInfo">
      {viewPreviousTransaction ? (
        <div className="homepage-header">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={() => setViewPreviousTransaction(false)}
          >
            <ArrowBackIcon className="back-icon" />
            <span className="bread-crumb-title">
              Home
            </span>
          </button>
          <h4>
            {`${moment(viewPreviousTransaction?.valuationDate).format('MMM D, YYYY')} | 409A valuation`}
          </h4>
        </div>
      ) : (
        <HomepageHeader />
      )}
      <div className="content-wrapper">
        <p>
          Below you will find all artifacts related to your 409A valuation.
          You can view everything and download or print your final report at any time.
        </p>
        <div className="sandbox-report-review-wrapper">
          <div className="TransactionInfoBlock item">
            <div
              role="button"
              tabIndex={0}
              aria-label="View report"
              className="overlay"
              onClick={() => nav(`/409a-report${previousTransactionQuery}`)}
              onKeyDown={(e) => { if (e.key === 'Enter') nav(`/409a-report${previousTransactionQuery}`); }}
            />
            <div className="title-background-image report-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A valuation report
                </span>
                {reportIsReady ? (
                  <div className="in-progress">
                    Completed
                  </div>
                ) : (
                  <div className="in-progress">
                    In Progress
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="TransactionInfoBlock item">
            <div
              role="button"
              tabIndex={0}
              aria-label="Open sandbox"
              className="overlay"
              onClick={() => nav(hasOldSandbox ? `/409a-sandbox${previousTransactionQuery}` : `/409a-price-sandbox${previousTransactionQuery}`)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') nav(hasOldSandbox ? `/409a-sandbox${previousTransactionQuery}` : `/409a-price-sandbox${previousTransactionQuery}`);
              }}
            />
            <div className="title-background-image sandbox-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A sandbox
                </span>
              </div>
            </div>
          </div>
          <div className="TransactionInfoBlock item">
            <div
              role="button"
              tabIndex={0}
              aria-label="View 409A valuation form"
              className="overlay"
              onClick={() => nav(`/409a-info${previousTransactionQuery}`)}
              onKeyDown={(e) => { if (e.key === 'Enter') nav(`/409a-info${previousTransactionQuery}`); }}
            />
            <div className="title-background-image submission-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A valuation form
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TransactionInfo.propTypes = {
  reportIsReady: PropTypes.bool,
  hasOldSandbox: PropTypes.bool,
  viewPreviousTransaction: PropTypes.object,
  setViewPreviousTransaction: PropTypes.func,
};
