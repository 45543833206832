import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import Button from '@mui/material/Button';

import AddchartIcon from '@mui/icons-material/Addchart';
import AddIcon from '@mui/icons-material/Add';

import SubscriptionBanner from './SubscriptionBanner';
import Early409AModal from '../../../components/Modals/Early409AModal';

import { UserDataContext } from '../../../contexts';

import useFetch from '../../../hooks/useFetch';

import './HomepageHeader.scss';

export default function HomepageHeader() {
  const { userData } = useContext(UserDataContext);

  const [showEarly409AModal, setShowEarly409AModal] = useState(false);

  const [, startNew409ARequest] = useFetch();

  const nav = useNavigate();

  function timeOfDayGreeting() {
    const currentHour = moment().hour();

    if (currentHour > 16) return "evening";
    if (currentHour > 12) return "afternoon";
    return "morning";
  }

  return (
    <>
      <div className="homepage-header">

        <h4>
          {`Good ${timeOfDayGreeting()}, `}
          <span className="user-name">
            {`${userData.userAttributes.firstName || ''} ${userData.userAttributes.lastName || ''}`}
          </span>
        </h4>

        {userData.userState === 'report-ready' && (
          <Button
            className="nth-transaction-btn"
            onClick={() => {
              if (moment(userData.mostRecentValuationDate, 'YYYY-MM-DD').isAfter(moment().subtract(1, 'years'))) {
                setShowEarly409AModal(true);
                return;
              }
              startNew409ARequest({
                url: '/transactions/new-transaction',
                method: 'post',
                urlIds: ['companyId'],
                onSuccess: () => nav('/409a-info?newTransaction=true'),
              });
            }}
          >
            <AddchartIcon />
            Start new 409A
          </Button>
        )}
        {(userData.userState === '409a-not-started' || userData.userState === '409a-in-progress') && (
          <Button
            className="new-first-transaction-btn"
            onClick={() => nav('/409a-info')}
          >
            {userData.userState === '409a-not-started' && <AddIcon />}
            {userData.userState === '409a-in-progress' ? 'Resume 409A valuation' : 'Start a new 409A'}
          </Button>
        )}

      </div>

      <SubscriptionBanner />
      <Early409AModal showEarly409AModal={showEarly409AModal} setShowEarly409AModal={setShowEarly409AModal} />
    </>
  );
}
