import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Document, Page, pdfjs } from 'react-pdf';

import { ReactComponent as ReportGraphic } from '../../images/ValReportInProgress.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { UserDataContext, UserStateContext } from '../../contexts';

import useS3 from '../../hooks/useS3';

import './ValReport.scss';

export default function ValReport() {
  const { userData } = useContext(UserDataContext);
  const { userState } = useContext(UserStateContext);
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const [{ loading: fetchingReport }, reportLinkRequest] = useS3();

  const nav = useNavigate();
  const location = useLocation();

  pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs';

  const previousTransactionId = new URLSearchParams(location.search).get('previousTransaction');

  const reportIsReady = previousTransactionId || userState === 'report-ready';

  function getReportLink({ download = false } = {}) {
    reportLinkRequest({
      requestType: 'get',
      key: `${userData.userAttributes.companyId}/${previousTransactionId || userData.userTransactions[0].transactionId}/${userData.companyData.companyName}-409AReport.pdf`,
      bucket: process.env.REACT_APP_S3_REPORTS_BUCKET,
      onSuccess: (url) => download ? window.open(url) : setPdfUrl(url),
    });
  }

  useEffect(() => { if (reportIsReady) getReportLink(); }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(1);
    setPdfLoading(false);
    setNumberOfPages(numPages);
  }

  if (fetchingReport) {
    return (
      <div className="page-loading-wrapper">
        <LoadingSpinner className="custom-loading-spinner" />
      </div>
    );
  }

  function homeButton() {
    return (
      <div className="bread-crumb" style={{ marginBottom: '10px' }}>
        <button
          type="button"
          className="bread-crumb-btn"
          onClick={() => nav('/')}
        >
          <ArrowBackIcon className="back-icon" />
          <span className="bread-crumb-title">
            Home
          </span>
        </button>
      </div>
    );
  }

  if (!reportIsReady) {
    return (
      <div className="ValReport">
        {homeButton()}
        <div className="splash-content-wrapper">
          <div className="splash-body-wrapper">
            <ReportGraphic className="splash-graphic" />
            <p>409A valuation reports typically take approximately one full business day to prepare.</p>
            <p>We&apos;ll email you when your report is ready.</p>
            <div className="button-wrapper">
              <Button onClick={() => nav('/')}>
                Back to homepage
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ValReport">
      {homeButton()}
      <div className="splash-content-wrapper">
        {pdfLoading && (
          <div className="loading-wrapper">
            <LoadingSpinner className="custom-loading-spinner" />
          </div>
        )}
        <div className="report-viewer">
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading=" " error=" ">
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
        </div>
        <div className="btn-container">
          <IconButton onClick={() => getReportLink({ download: true })}>
            <DownloadIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(1)}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber >= numberOfPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton
            disabled={pageNumber >= numberOfPages}
            onClick={() => setPageNumber(numberOfPages)}
          >
            <LastPageIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
