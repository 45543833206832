import React, { useContext } from 'react';

import ClientIntakeForm from '../ClientIntakeForm';
import SplashPage from './SplashPage';
import TransactionInfo from './TransactionInfo';
import FinalHomepage from './FinalHomepage';

import {
  UserDataContext,
  UserStateContext,
} from '../../contexts';

import './index.scss';

export default function Home() {
  const { userData } = useContext(UserDataContext);
  const { userState } = useContext(UserStateContext);

  if (userState === 'client-intake') return <ClientIntakeForm />;

  if (userData.userTransactions?.length > 1) {
    return (
      <FinalHomepage
        currentUserState={userState}
        userTransactions={userData.userTransactions}
        reportIsReady={userState === 'report-ready'}
      />
    );
  }

  if (
    userState === '409a-not-started' ||
    userState === '409a-in-progress' ||
    userState === '409a-completed' ||
    userState === 'sandbox-ready'
  ) {
    return (
      <SplashPage currentUserState={userState} />
    );
  }

  if (
    userState === 'sandbox-finalized' ||
    userState === 'report-ready'
  ) {
    return (
      <TransactionInfo
        hasOldSandbox={userData.userTransactions[0].hasOldSandbox}
        reportIsReady={userState === 'report-ready'}
      />
    );
  }
}
